import { AppointmentButtons } from '../AppointmentButtons/AppointmentButtons';
import { CalendarRecord } from 'src/common/types';
import { CancelAppointmentTag } from '../../../common/components/CancelAppointmentTag/CancelAppointmentTag';
import { ClinicBlock } from '../ClinicBlock/ClinicBlock';
import { Col, Divider, Layout, Row, Typography } from 'antd';
import { PriceTag } from 'src/common/components/PriceTag/PriceTag';
import { ProfileBlock } from '../ProfileBlock/ProfileBlock';
import { ServiceBlock } from '../ServiceBlock/ServiceBlock';
import { ServiceRequestType } from '../../../common/types/enums';
import { checkIsPaid, checkPaymentStatus, removeCents } from '../../../common/utils';
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface AppointmentBlockProps {
  appointment: CalendarRecord;
  refetchData?: () => void;
}

export const AppointmentBlock = ({ appointment, refetchData }: AppointmentBlockProps) => {
  const date = dayjs(appointment.created);

  const isTmkMeeting = useMemo(() => appointment.serviceDeliveryType === ServiceRequestType.Online, [appointment]);

  return (
    <Layout className={styles.AppointmentWrapper}>
      <Row className={styles.DateRow}>
        <Col>
          <Typography className={styles.Date}>{date.format('DD MMMM YYYY, HH:mm')}</Typography>
          <Typography className={styles.DayOfWeek}>{date.format('dddd')}</Typography>
        </Col>
        <Col>{/*<CustomSwitch checked={appointment?.appointmentStatus === AppointmentStatus.Past} />*/}</Col>
      </Row>
      <Divider />
      <Row className={styles.DoctorRow}>
        <Col className={styles.DoctorCol}>
          {appointment.doctor && (
            <ProfileBlock
              fio={appointment.doctor.fullName}
              role={appointment.doctor.specializationName}
              id={appointment.doctor.idMilaDoctor}
              avatar={appointment.doctor.idPhoto}
            />
          )}
          <ClinicBlock
            address={appointment.medOrganization.address}
            name={appointment.medOrganization.shortName}
            longitude={appointment.medOrganization.longitude}
            latitude={appointment.medOrganization.latitude}
            id={appointment.medOrganization.idMedOrganization}
          />
          <ServiceBlock name={appointment.doctor.serviceName} />
          {appointment?.isCancelled ? (
            <CancelAppointmentTag
              price={removeCents(appointment.paymentCost)}
              isMoneyBack={checkPaymentStatus(appointment.paymentStatus).refundedMoney}
              isTmk={isTmkMeeting}
              canceledPayment={checkPaymentStatus(appointment.paymentStatus).canceledPayment}
            />
          ) : (
            <PriceTag
              appointmentPaymentStatusName={appointment.paymentStatusName}
              isPaid={checkIsPaid(appointment.paymentStatus, +appointment.paymentCost)}
              paymentType={appointment.paymentTypeName}
              price={removeCents(appointment.paymentCost)}
            />
          )}
        </Col>
        <Col className={styles.ButtonsCol}>
          <AppointmentButtons appointment={appointment} refetchData={refetchData} />
        </Col>
      </Row>
    </Layout>
  );
};

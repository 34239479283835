import { Typography } from 'antd';
import styles from './styles.module.scss';

const { Title, Paragraph, Text } = Typography;

export const NotificationsDataAgreement = (
  <div className={styles.agreementWrapper}>
    <Typography.Title level={4} className={styles.agreementTitle}>
      СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ПОЛУЧЕНИЕ УВЕДОМЛЕНИЙ
    </Typography.Title>

    <Paragraph className={styles.paragraph}>
      <Text strong>1. </Text>Я (субъект персональных данных, дееспособное физическое лицо, достигшее 18-летнего
      возраста, далее &ndash; Пользователь), действуя своей волей и в своем интересе,
      <Text strong> даю свое согласие Обществу с ограниченной ответственностью &laquo;ЭлНетМед&raquo; </Text>
      (ООО &laquo;ЭлНетМед&raquo;, ИНН 7842529670, ОГРН 114 784 736 4323) (далее &ndash; Оператор), а также медицинским
      организациям и третьим лицам, состоящим с Оператором в договорных отношениях, на направление мне по контактным
      данным (номеру телефона и/или электронной почте), указанным мной при прохождении процедуры регистрации на сайте
      Mila (Мила) по адресу <a href="http://www.mila.online">www.mila.online</a> (далее &ndash; Сайт) или через сервисы
      Сайта, в том числе виджет записи на прием в медицинскую организацию, отображаемый на сайте медицинской организации
      (далее - Сервисы) путем ручного заполнения формы регистрации или авторизации посредством Госуслуг (через Единую
      Систему Идентификации и Аутентификации) или Сбер ID (сервис авторизации от ПАО &laquo;Сбербанк&raquo;), сообщений
      с целью информирования меня о поступлении новых медицинских сведений в медицинскую карту на Сайте (мою или лиц,
      законным представителем которых я являюсь), об изменении статусов записей на прием в медицинские организации (моих
      или лиц, законным представителем которых я являюсь), получения ответов на мои запросы по услугам медицинских
      организаций, сведения о которых опубликованы на Сайте и его Сервисах, предоставления персональных рекомендаций на
      основании сведений в медицинской карте на Сайте, доведения сведений о ранее оказанных медицинских услугах, для
      запроса оценки мной качества оказанных медицинских услуг, о появлении новых Сервисов на Сайте, о доступных мне
      акциях медицинских организаций и др. сообщений информационного и рекламного характера, в виде электронных писем,
      и/или сообщений внутри Сайта и Сервисов, и/или сообщений в мессенджерах, и/или push-уведомлений, и/или посредством
      телефонных звонков.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>2. </Text>Согласие дается мной путем включения соответствующей &laquo;галочки&raquo; в форме
      регистрации на Сайте или в разделе &laquo;Согласия на доступ к данным&raquo; Сайта, либо нажатия кнопки
      &laquo;Разрешить уведомления&raquo; в форме &laquo;Настройка профиля&raquo;. Настоящим подтверждаю, что иных
      доказательств для подтверждения моего свободного волеизъявления не потребуется.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>3. </Text>Я согласен с тем, что текст данного мной согласия хранится в электронном виде в базе данных
      Сайта и подтверждает факт согласия на обработку персональных данных в соответствии с вышеизложенными положениями и
      беру на себя ответственность за достоверность предоставления персональных данных. Настоящим я гарантирую, что
      указанные мною на Сайте или его Сервисах абонентские номера телефонов принадлежат мне, в случае прекращения
      использования мною абонентского номера телефона, указанного на Сайте или его Сервисах, я обязуюсь проинформировать
      об этом Оператора. Под персональными данными понимается любая информация, сообщённая о себе Пользователем Сайта,
      на основании которой возможна его идентификация как субъекта персональных данных, либо сообщённая Пользователем
      Сайта о лице, законным представителем которого Пользователь является. При использовании персональных данных
      Оператор руководствуется статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006г. №
      152-ФЗ &laquo;О персональных данных&raquo;, Федеральным законом от 13.03.2006г. № 38-ФЗ &laquo;О рекламе&raquo;,
      Политикой конфиденциальности и Правилами обработки персональных данных, размещенными на Сайте.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>4. </Text>Я подтверждаю, что владею информацией о том, что
      <Text strong> в любой момент в течение всего срока действия настоящего согласия я вправе отозвать согласие </Text>
      путем выключения соответствующей &laquo;галочки&raquo; в настройках профиля на Сайте. Также я информирован, что
      при возникновении вопросов относительно отказа от рассылки, я могу обратиться за помощью, отправив запрос в службу
      технической поддержки Оператора через сервис &laquo;Помощь&raquo; на Сайте.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>5. </Text>
      Настоящее согласие предоставляется на неограниченный срок при отсутствии сведений о его отзыве.
    </Paragraph>
  </div>
);

import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Col, Row, Skeleton, Typography } from 'antd';
import { EmptyDoctors } from 'src/common/icons';
import { Link } from 'react-router-dom';
import { SliderRow } from 'src/common/components/SliderRow/SliderRow';
import { useGetDoctorsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import styles from './styles.module.scss';

interface MyDoctorsPreviewProps {
  idMedOrganization?: string;
  maxShow: number;
}

export const MyDoctorsPreview = ({ idMedOrganization, maxShow }: MyDoctorsPreviewProps) => {
  const { data: profiles, isLoading: profileLoading } = useGetProfilesQuery();

  const profilesIds = useMemo(() => profiles?.map((profile) => profile.idProfile), [profiles]);

  const { data: myDoctors, isLoading: myDoctorsLoading } = useGetDoctorsQuery(
    {
      patientIds: profilesIds,
      practiceId: idMedOrganization ?? '',
    },
    {
      skip: !profiles,
    },
  );

  if (myDoctorsLoading || profileLoading)
    return (
      <Row className={styles.DoctorShortCol}>
        <Skeleton.Avatar active shape={'circle'} size={'large'} className={styles.DocAvatar} />
        <Skeleton active paragraph={{ rows: 2 }} title={false} />
      </Row>
    );

  return (
    <>
      {!(myDoctorsLoading || profileLoading) && !myDoctors?.length ? (
        <Col className={styles.Empty}>
          <EmptyDoctors />
          <Typography.Text>Здесь будет список ваших врачей </Typography.Text>
        </Col>
      ) : (
        <SliderRow
          className={styles.DoctorCarousel}
          arrowsClass={styles.Arrows}
          arrowsHide={!!myDoctors?.length && myDoctors.length < maxShow}
        >
          {myDoctors?.map((doctor) => (
            <Link
              onClick={(e) => !doctor.hasMilaProfile && e.preventDefault()}
              style={!doctor.hasMilaProfile ? { cursor: 'default' } : undefined}
              to={`/records/aboutDoctor/${doctor.idMilaDoctor}${
                idMedOrganization ? `?branchId=${idMedOrganization}` : ''
              }`}
              key={doctor.idMilaDoctor}
            >
              <Col className={styles.DoctorShortCol}>
                <Avatar
                  idProfile={doctor.idMilaDoctor}
                  className={styles.DocAvatar}
                  classNameText={styles.Initials}
                  idPhoto={doctor.photoId}
                  name={doctor.fio}
                  isDoctor
                />
                <Typography className={styles.DocFio}>{doctor.fio}</Typography>
                <Typography className={styles.DocBranch}></Typography>
                {doctor &&
                  doctor?.specialitiesTags &&
                  doctor.specialitiesTags?.length > 0 &&
                  doctor.specialitiesTags[0]?.specialityName && (
                    <Typography className={styles.DocSpec}>
                      {doctor.specialitiesTags[0].specialityName?.replaceAll('-', ' - ')}
                    </Typography>
                  )}
              </Col>
            </Link>
          ))}
        </SliderRow>
      )}
    </>
  );
};

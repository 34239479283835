import { Button } from 'antd';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';
import React, { ReactNode, useMemo } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

interface CustomButtonProps {
  isPrimal?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  children?: ReactNode;
  htmlType?: 'submit' | 'button';
  disabled?: boolean;
  dataTestId?: string;
}

export const CustomButton = ({
  isPrimal = false,
  htmlType,
  onClick,
  children,
  className = '',
  disabled = false,
  dataTestId,
}: CustomButtonProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => whiteLabel?.isServiceEnabled, [whiteLabel]);

  return (
    <Button
      data-test-id={dataTestId ? `button-${dataTestId}` : undefined}
      htmlType={htmlType}
      className={cx(
        {
          [styles.PrimalButton]: isPrimal,
          [styles.SecondaryButton]: !isPrimal,
          [styles.labQuestPrimary]: isWhiteLabelEnabled && isPrimal,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

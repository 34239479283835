import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import React, { useEffect, useMemo, useState } from 'react';

import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from '../../common/components/CustomSpinner/CustomSpinner';
import { DocBlock } from './DocBlock/DocBlock';
import { NoDocuments } from '../../common/icons';
import { PharmDocumentStatus, SignedDocumentStatus } from '../../common/types';
import { SingleDoc } from './SingleDoc/SingleDoc';
import { Typography, notification } from 'antd';
import {
  useGetPharmDocumentsQuery,
  useGetProfilesQuery,
  useJoinEsiaAccountMutation,
} from '../../common/redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';
import EsiaLoginService from '../../common/services/login/Esia/esia-login-service';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const items = [
  { label: `На рассмотрение`, key: PharmDocumentStatus.Active },
  { label: `Рассмотрены`, key: PharmDocumentStatus.Reviewed },
  { label: `Архив`, key: PharmDocumentStatus.Archive },
];

const count = 20;

export const Docs = () => {
  const [activeTab, setActiveTab] = useState<string>(PharmDocumentStatus.Active);
  const [choosenDoc, setChoosenDoc] = useState<number | null>(null);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [documents, setDocuments] = useState<any[]>([]);
  const navigate = useNavigate();

  const { data: profiles, isFetching: isFetchingProfiles, refetch: refetchProfiles } = useGetProfilesQuery();
  const myProfile = useMemo(() => profiles?.find((profile) => profile.itSelf), [profiles]);
  const { data, isFetching } = useGetPharmDocumentsQuery(
    { status: activeTab, offset, count },
    {
      refetchOnMountOrArgChange: true,
      skip: !myProfile,
    },
  );
  const [joinEsiaAccount] = useJoinEsiaAccountMutation();

  useEffect(() => {
    if (data) {
      setTotal(data.total);
      setDocuments([...documents, ...data.data]);
    }
  }, [data]);

  const handleTabChange = (key: string) => {
    setDocuments([]);
    setTotal(0);
    setOffset(0);
    setActiveTab(key);
  };

  const handleGoBack = () => {
    choosenDoc !== null ? setChoosenDoc(null) : navigate(-1);
  };

  const backToList = () => {
    setActiveTab(PharmDocumentStatus.Reviewed);
    setDocuments([]);
    setTotal(0);
    setOffset(0);
    setChoosenDoc(null);
  };

  const getDocStatus = (document: any) => {
    if (document.documents.filter((x: any) => x.waiver && x.patientSigned).length > 0) {
      return SignedDocumentStatus.Refused;
    }
    if (document.documents.filter((x: any) => !x.waiver && x.doctorSignedDate).length > 0) {
      return SignedDocumentStatus.Signed;
    }

    if (document.documents.filter((x: any) => !x.waiver && x.patientSigned).length > 0) {
      return SignedDocumentStatus.WaitDoctorSign;
    }

    return SignedDocumentStatus.Undefined;
  };

  const getDocs = () => {
    return (
      documents?.map((x: any) => ({
        name: x.name,
        dateCreate: dayjs(x.createdDate).format('DD MMMM YYYY, HH:mm'),
        dateApproval: x.expiredDate ? dayjs(x.expiredDate).format('DD.MM.YYYY') : '-',
        doctor: {
          name: x.doctorFullName,
          profession: x.doctorSpeciality,
        },
        clinic: x.doctorDepartment,
        type: x.tag,
        status: activeTab !== PharmDocumentStatus.Active ? getDocStatus(x) : null,
      })) ?? []
    );
  };

  const getDoc = (idx: any) => {
    const doc = documents?.[idx];
    return {
      name: doc?.name,
      dateCreate: dayjs(doc?.createdDate).format('DD MMMM YYYY, HH:mm'),
      dateApproval: doc.expiredDate ? dayjs(doc.expiredDate).format('DD.MM.YYYY') : '-',
      doctor: {
        name: doc?.doctorFullName,
        profession: doc?.doctorSpeciality,
      },
      clinic: doc?.doctorDepartment,
      type: doc?.tag,
      studyName: doc?.studyName,
      studyDescription: doc?.studyDescription,
      documents: doc?.documents,
      createdDate: doc?.createdDate,
      id: doc?.id,
      status: activeTab !== PharmDocumentStatus.Active ? getDocStatus(doc) : null,
    };
  };

  const handleNext = () => {
    setOffset(offset + count);
  };

  const connectEsiaProfile = () => {
    EsiaLoginService.Login().then((accessToken) => {
      joinEsiaAccount({ accessToken, idProfile: myProfile?.idProfile! })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Профиль успешно подключен к Госуслугам' });
          refetchProfiles();
        })
        .catch(() => notification.error({ message: 'Ошибка подключения профиля к Госуслугам' }));
    });
  };

  return (
    <>
      <BackHeader title="Документы" backFunction={handleGoBack} />
      {choosenDoc === null ? (
        <>
          <CustomCardTabs
            items={items}
            onChange={handleTabChange}
            className={styles.TabsLabels}
            activeKey={activeTab}
          />
          {isFetching || isFetchingProfiles ? (
            <CustomSpinner />
          ) : (
            <InfiniteScroll
              dataLength={documents.length}
              loader={<CustomSpinner />}
              next={handleNext}
              hasMore={documents.length < total}
              className={styles.RecordsScroll}
            >
              {documents?.length > 0 ? (
                getDocs()?.map((doc: any, index: any) => (
                  <DocBlock {...doc} setDocNumber={() => setChoosenDoc(index)} key={index} />
                ))
              ) : !myProfile?.isConfirmed ? (
                <div className={styles.NoDocumentsWrapper}>
                  <NoDocuments />
                  <Typography.Text className={styles.NoDocumentsTitle}>
                    Для поиска документов подтвердите вашу учетную запись
                  </Typography.Text>
                  <Typography.Text className={styles.NoDocumentsText}>
                    В данном разделе отображаются персональные приглашения на участие в клинических исследованиях, а
                    также иные документы, переданные медицинскими организациями. Для просмотра таких документов
                    требуется подтвердить вашу учетную запись через Госуслуги. Обращаем внимание, что MILA официально
                    подключена к ГИС ЕСИА. Для подтверждения вы будете перенаправлены на сайт Госуслуг, а после
                    авторизации обратно в MILA. MILA не получает и не хранит ваши логин и пароль от аккаунта на
                    Госуслугах.
                  </Typography.Text>
                  <CustomButton onClick={connectEsiaProfile} isPrimal className={styles.Button}>
                    <Typography>Подключить Госуслуги к профилю</Typography>
                  </CustomButton>
                </div>
              ) : (
                <div className={styles.NoDocumentsWrapper}>
                  <NoDocuments />
                  <Typography.Text className={styles.NoDocumentsTitle}>
                    Медицинские организации еще не передали документы для вас
                  </Typography.Text>
                  <Typography.Text className={styles.NoDocumentsText}>
                    В данном разделе отображаются персональные приглашения на участие в клинических исследованиях, а
                    также иные (не медицинские) документы, переданные медицинскими организациями для подписания пациенту
                  </Typography.Text>
                </div>
              )}
            </InfiniteScroll>
          )}
        </>
      ) : (
        <SingleDoc {...getDoc(choosenDoc)} docStatus={activeTab} backToList={backToList} />
      )}
    </>
  );
};

import { Col, Image, Row, Typography } from 'antd';
import { CustomTag } from '../../../common/components/CustomTag/CustomTag';
import { Link } from 'react-router-dom';
import { MilaIconMobile, PurpleDot } from 'src/common/icons';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCall } from '../../../common/hooks/useDebounce';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import { useUpdateNotificationStatusMutation } from '../../../common/redux/api/apiPatientSlice';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export interface NotificationMessageProps {
  title: string | ReactNode;
  text: string;
  link?: string;
  tagName: string;
  isViewed?: boolean;
  className?: string;
  idNotification: string;
}

export const NotificationMessage = ({
  title,
  tagName,
  idNotification,
  text = '',
  className = '',
  link = '',
  isViewed,
}: NotificationMessageProps) => {
  const refContainer = useRef(null);
  const [isJustViewed, setIsJustViewed] = useState(false);
  const [updateNotificationStatus, result] = useUpdateNotificationStatusMutation();
  const debouncedMutation = useDebouncedCall(updateNotificationStatus, 300);

  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const handleScroll = useCallback(async () => {
    if (refContainer?.current) {
      let currentCardPos = (refContainer.current as Element).getBoundingClientRect().bottom;
      if (window.innerHeight >= currentCardPos) {
        if (!isViewed) {
          await debouncedMutation(idNotification);
        }
      }
    }
    /* eslint-disable */
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (result.isSuccess) {
      setIsJustViewed(true);
    }
  }, [result]);

  return (
    <Row className={styles.MessageContainer} ref={refContainer}>
      {isWhiteLabelEnabled ? (
        <Image
          className={styles.MilaIcon}
          src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idIconFile}`}
          preview={false}
        />
      ) : (
        <MilaIconMobile />
      )}
      <Row className={`${className} ${styles.MessageRow}`}>
        <Col className={styles.TextCol}>
          <Row className={`${styles.TitleRow}`}>
            <Row className={styles.TimeWithMarkContainer}>
              <Typography className={styles.Title}>{title}</Typography>
              {!isViewed && !isJustViewed && <PurpleDot />}
            </Row>
            <CustomTag className={styles.MessageTag}>{tagName}</CustomTag>
          </Row>
          <Typography
            className={`${(isViewed || isJustViewed) && styles.ViewedText} ${styles.Text}`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <Link to={link} className={styles.TextLink}>
            Показать
          </Link>
        </Col>
      </Row>
    </Row>
  );
};

export enum AppointmentStatus {
  Unknown = -1,
  Upcoming,
  Ongoing,
  Past,
}

export enum ServiceRequestType {
  OnSite = 0,
  AtHome = 1,
  Online = 2,
  Hospital = 3,
  Dispensary = 4,
}

export enum AppointmentType {
  Unknown = -1,
  Ambulatory = 0,
  Online = 1,
  Stationary = 2,
  AtHome = 3,
  Dispensary = 4,
}

export enum NotificationType {
  MedicalCardUpdate = 0,
  MedicalCardShare = 1,
  AppointmentSent = 2,
  AppointmentRemind = 3,
  AppointmentCanceled = 4,
  AppointmentRescheduled = 5,
  WaitingList = 6,
  AppointmentReviewRequest = 7,
  AppointmentChanged = 8
}

export enum DocumentUploadedByWhomType {
  Patient = 0,
  Clinic = 1,
}

export enum DocumentType {
  Analysis = 0, // Анализ (Лабораторное Исследование)
  PhotoAttachedToAppointment = 1,
  MedicalReport = 2, // Заключение
  Referral = 3, // Направление
  Examination = 4, // Исследование (Инструментальное исследование)
  Vaccination = 5, // Вакцинация
  Recipe = 6, // Рецепт
  Surgery = 7, // Операции
  Genetic = 8, // Генетика
  Other = 99, // Другое
}

export enum DictionaryType {
  Mkb10 = 'mkb10',
  Services = 'services',
}

export enum PaymentStatusType {
  Unpaid = 0,
  Paid = 1,
  Canceled = 2,
  Refunded = 3,
  Prepaid = 5,
  PaymentSystemError = 6,
}

export enum Gender {
  Female = 0,
  Male = 1,
}

export enum AppointmentPaymentStatus {
  CreatedAndNotPaid = 0,
  WithdrawBySystem = 1,
  Canceled = 2,
  RefundedBySystem = 3,
  MoneyReserved = 5,
  PaymentSystemError = 6,
  WithdrawByClinic = 11,
  WithdrawByPatientInOrderOfCancellingAnAppointment = 12,
  WithdrawByClinicWithoutReport = 13,
  WithdrawByClinicInOrderOfSuccess = 14,
  RefundedByPatient = 31,
  RefundedByClinic = 32,
}

export enum AuthType {
  Esia = 'esia',
  Sber = 'sber',
  Email = '',
}

export enum AppointmentStatusChangedName {
  Cancelled = 'Cancelled',
  Finished = 'Finished',
  ReportUploaded = 'ReportUploaded',
}

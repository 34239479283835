import { BackHeader } from '../../common/components/BackHeader/BackHeader';
import { BonusRules } from './BonusRules';
import { Card, Row } from 'antd';
import { ContactInfo } from './ContactInfo';
import { CustomSpinner } from '../../common/components/CustomSpinner/CustomSpinner';
import { History } from './History';
import { PointsContainer } from './PointsContainer';
import { ProfileTabs } from '../../common/components/ProfileTabs/ProfileTabs';
import { SharePromo } from './SharePromo';
import { historyData } from './constants';
import { useEffect, useMemo, useState } from 'react';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from 'src/common/redux/api/apiWhiteLabelSlice';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

export const Points = () => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => whiteLabel?.isServiceEnabled, [whiteLabel]);
  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const [params, setSearchParams] = useSearchParams();
  const idProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile || '', [profiles]);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const preselectedPersonTab = useMemo(() => params.get('profileId'), [params]);

  useEffect(() => {
    if (!selectedPerson) setSelectedPerson(idProfile);
    if (preselectedPersonTab) setSelectedPerson(preselectedPersonTab);
  }, [idProfile, preselectedPersonTab, selectedPerson]);

  const handlePersonTabClick = (key: string) => {
    setSearchParams({ profileId: key });
  };

  return (
    <>
      <BackHeader title="Баллы" />
      <Row className={styles.profileRow}>
        {isLoadingProfiles ? (
          <CustomSpinner />
        ) : (
          <ProfileTabs activeProfile={selectedPerson} onChange={handlePersonTabClick} withoutAllTab />
        )}
      </Row>

      <div className={styles.contentWrapper}>
        <Card
          headStyle={{ border: 'none' }}
          className={styles.section}
          title={<div className={styles.mainTitle}>Ваши бонусные баллы</div>}
        >
          <PointsContainer />

          <SharePromo />

          <BonusRules />

          <ContactInfo />
        </Card>

        <History historyData={historyData} />
      </div>
    </>
  );
};

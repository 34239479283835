import { ChangeEvent, FC, memo, useEffect, useRef } from 'react';
import { Input, InputRef, Popover } from 'antd';
import { RemoveCross, Search } from 'src/common/icons';
import cx from 'classnames';
import FloatLabelInput from '../FloatLabelInput/FloatLabelInput';
import styles from './styles.module.scss';

interface SearchProps {
  handleSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  value?: string;
  floatLabel: string;
  inputName?: string;
  disabled?: boolean;
  defaultValue?: string;
  focus?: boolean;
  allowClear?: boolean;
}

export const CustomSearch: FC<SearchProps> = memo(
  ({
    handleSearch,
    placeholder,
    className,
    value,
    floatLabel,
    disabled,
    inputName,
    defaultValue,
    focus,
    allowClear = false,
  }) => {
    const searchRef = useRef<InputRef>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (focus) {
        searchRef.current?.focus();
      }
    }, [focus]);

    const showPopup = typeof value === 'string' && value.length > 0 && value.length < 3;

    const popupContent = (
      <div className={styles.popupContent}>Для работы поиска необходимо ввести более 2 символов</div>
    );

    return (
      <div ref={containerRef} className={cx(styles.FormItemWrapper, className)}>
        <FloatLabelInput label={floatLabel} value={value?.toString()}>
          <Popover
            content={popupContent}
            trigger="focus"
            placement="topLeft"
            open={showPopup}
            getPopupContainer={() => containerRef.current!}
          >
            <Input
              suffix={<Search />}
              placeholder={placeholder}
              onChange={handleSearch}
              defaultValue={defaultValue}
              disabled={disabled}
              name={inputName}
              value={value}
              ref={searchRef}
              allowClear={allowClear && { clearIcon: <RemoveCross /> }}
            />
          </Popover>
        </FloatLabelInput>
      </div>
    );
  },
);

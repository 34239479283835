import { Clinic } from '../../types';
import { Clock, Location, Phone } from 'src/common/icons';
import { Col, Divider, Row, Typography } from 'antd';
import { RoutingButton } from '../RoutingButton/RoutingButton';
import React from 'react';
import styles from './styles.module.scss';

interface ClinicContacsBlockProps {
  clinic: Clinic;
  className?: string;
  isFullViewClinicCard?: boolean;
}

export const ClinicContacsBlock = React.memo(
  ({ clinic, isFullViewClinicCard = false, className }: ClinicContacsBlockProps) => (
    <Row className={`${styles.BlockContent} ${className}`}>
      <Row className={`${styles.ContactRow} ${styles.RoutingRow}`}>
        <Col className={styles.RoutingCol}>
          <Location className={styles.Icon} />
          <Col>
            <Typography className={styles.ClinicAddress}>{clinic?.address}</Typography>
            {clinic?.nearestSubwayDescription.length > 0 &&
              clinic?.nearestSubwayDescription !== ' ()' &&
              clinic?.nearestSubwayDescription !== '()' && (
                <Typography className={styles.GreyText}>{clinic?.nearestSubwayDescription}</Typography>
              )}
          </Col>
        </Col>
        <RoutingButton latitude={clinic?.latitude} longitude={clinic?.longitude} />
      </Row>
      <Divider className={`${styles.MobileDivider} ${isFullViewClinicCard && styles.DesktopVisible}`} />
      <Row className={styles.ContactRow}>
        <Clock className={styles.Icon} />
        <Typography className={styles.WorkingHours}>{clinic?.workingHoursDescription}</Typography>
      </Row>
      <Divider className={`${styles.MobileDivider} ${isFullViewClinicCard && styles.DesktopVisible}`} />
      <Row className={styles.ContactRow}>
        <Phone className={styles.Icon} />
        <Col className={styles.MobileCol}>
          {clinic?.phones?.map((phone) => (
            <Typography.Link href={`tel:${phone}`} className={styles.PhoneNumber} key={phone}>
              {phone}
            </Typography.Link>
          ))}
        </Col>
      </Row>
    </Row>
  ),
);

import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { MilaIconMobile } from 'src/common/icons';
import { useGetWhiteLabelInfoQuery } from '../../../../common/redux/api/apiWhiteLabelSlice';
import { useMemo } from 'react';
import styles from './styles.module.scss';

type DeleteSharedMedcardModalProps = {
  isModalOpen: boolean;
  onCancelClick: () => void;
  handleDeleteMedcard: () => void;
  handleGoTOMedcard: () => void;
};

const DeleteSharedMedcardModal = ({
  isModalOpen,
  onCancelClick,
  handleDeleteMedcard,
  handleGoTOMedcard,
}: DeleteSharedMedcardModalProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  return (
    <CustomModal isOpen={isModalOpen} onCancelClick={onCancelClick} className={styles.DeleteMedcardModal}>
      <Row className={styles.DeleteMedcardModalMainRow}>
        <Typography className={styles.DeleteMedcardModalTitle}>Закрыть доступ для врача</Typography>
        <Row className={styles.DeleteMedcardModalInfoRow}>
          <Col>
            {isWhiteLabelEnabled ? (
              <Image
                className={styles.MilaIcon}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idIconFile}`}
                preview={false}
              />
            ) : (
              <MilaIconMobile />
            )}
          </Col>
          <Col className={styles.DescriptionCol}>
            <Row>
              <Typography className={styles.InfoTitle}>
                Вы можете отредактировать вид медкарты для врача без удаления доступа
              </Typography>
            </Row>
            <Row>
              <Typography className={styles.InfoDescription}>
                Если вы хотите отредактировать состав документов, которые отображаются врачу в медкарте, загрузите новые
                документы или измените текущие настройки доступа к документам в разделе «Медкарта». Вы сможете
                посмотреть вид измененной медкарты по ссылке «Посмотреть, что увидит врач». Также напомню, что просмотр
                для врача будет открыт в течение 24 часов в день назначенного приема, после этого ссылка будет удалена
                автоматически.
              </Typography>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={styles.ButtonsCol}>
            <CustomButton className={styles.DeleteMedcardModalBtn} onClick={handleGoTOMedcard}>
              <Typography>Отредактировать медкарту</Typography>
            </CustomButton>
            <CustomButton isPrimal className={styles.DeleteMedcardModalBtn} onClick={handleDeleteMedcard}>
              <Typography>Закрыть доступ для врача</Typography>
            </CustomButton>
          </Col>
        </Row>
      </Row>
    </CustomModal>
  );
};

export default DeleteSharedMedcardModal;

import {
  AddClinic,
  AddProfile,
  BriefcaseBig,
  DocumentUpload,
  Note,
  Percent,
  Play,
  ShareMedcard,
} from 'src/common/icons';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { InvitationModal } from 'src/features/Catalog/InvitationModal/InvitationModal';
import { Layout } from 'antd';
import { MedcardDocumentLoadForm } from 'src/features/MedCard/MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { MilaService } from '../../../common/types';
import { ServiceRequestType } from 'src/common/types/enums';
import { ShareModal } from 'src/features/MedCard/ShareModal/ShareModal';
import { SliderRow } from 'src/common/components/SliderRow/SliderRow';
import { setServiceRequestType } from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/app/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

interface ServicesProps {
  idMedOrganization?: string;
  arrowClass?: string;
}

export const Services = ({ idMedOrganization, arrowClass }: ServicesProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentOpen, setDocumentOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const handleClick = (key: string, action?: () => void) => {
    if (key.toString().indexOf('/') >= 0 || Number(key) >= 0) {
      if (Object.values(ServiceRequestType).includes(key)) {
        dispatch(setServiceRequestType(key as unknown as ServiceRequestType));
        navigate(`/records/specialtyChoice${idMedOrganization ? `?branchId=${idMedOrganization}` : ''}`);
      } else {
        navigate(key);
      }
    }
    if (action) {
      action();
    }
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleDocumentOpen = () => setDocumentOpen(true);
  const handleDocumentClose = () => setDocumentOpen(false);

  const handleShareOpen = () => setShareOpen(true);

  const [items, setItems] = useState([
    {
      icon: <Note />,
      text: 'Записаться на прием в клинику',
      color: '#FAD1CE',
      key: ServiceRequestType.OnSite,
      service: MilaService.OnSite,
    },
    {
      icon: <Play />,
      text: 'Записаться на онлайн-консультацию',
      color: '#E5DBF8',
      key: ServiceRequestType.Online,
      service: MilaService.Online,
    },
    {
      icon: <BriefcaseBig />,
      text: 'Вызвать врача на дом',
      color: '#FEF7F6',
      key: ServiceRequestType.AtHome,
      service: MilaService.AtHome,
    },
    // {
    //   icon: <ChatMessages />,
    //   text: 'Написать клинике в чат',
    //   color: '#F1E9FE',
    //   key: '/settings/userProfile/chats',
    //   service: MilaService.ChatWithClinic
    // },
    {
      icon: <Percent />,
      text: 'Найти скидки и акции в клиниках',
      color: '#FAECDA',
      key: '/catalog',
      service: MilaService.Discounts,
    },
    {
      icon: <DocumentUpload />,
      text: 'Загрузить документы в медкарту',
      color: '#FAD1CE',
      key: 'upload',
      action: handleDocumentOpen,
      service: MilaService.UploadDocuments,
    },
    {
      icon: <ShareMedcard />,
      text: 'Поделиться медкартой',
      color: '#E5DBF8',
      key: 'sharedMedcard',
      action: handleShareOpen,
      service: MilaService.SharedMedcard,
    },
    {
      icon: <AddClinic />,
      text: 'Добавить свою клинику в Mila',
      color: '#FEF7F6',
      key: 'addClinic',
      action: handleOpenModal,
      service: MilaService.AddClinic,
    },
    {
      icon: <AddProfile />,
      text: 'Добавить профили родных',
      color: '#F1E9FE',
      key: '/settings/userProfile',
      service: MilaService.AddProfile,
    },
  ]);

  useEffect(() => {
    if (isWhiteLabelEnabled && whiteLabel)
      setItems((items) => {
        return items.filter((item) => whiteLabel.services.includes(item.service));
      });
  }, [isWhiteLabelEnabled]);

  return (
    <SliderRow className={styles.CardRow} arrowsClass={arrowClass}>
      {items.map((item) => (
        <Layout
          className={styles.Card}
          style={{ background: item.color }}
          onClick={() => handleClick(item.key as unknown as string, item.action)}
          key={item.key}
        >
          {item.icon}
          {item.text}
        </Layout>
      ))}
      <CustomModal isOpen={isModalOpen} onCancelClick={handleCloseModal}>
        <InvitationModal />
      </CustomModal>

      <CustomModal isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
        <MedcardDocumentLoadForm onFinish={handleDocumentClose} />
      </CustomModal>

      <ShareModal isModalOpen={shareOpen} onCancelClick={() => setShareOpen(false)} />
    </SliderRow>
  );
};

import { Button, Typography } from 'antd';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { useState } from 'react';
import PromoIcon from './../../../assets/PromoIcon.svg';
import styles from './styles.module.scss';

const { Title, Paragraph } = Typography;

export const SharePromo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const promoCode = 'REF-W3502S';

  const handleModalToggle = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      <Button type="primary" className={styles.promoButton} onClick={handleModalToggle}>
        Поделитесь промокодом на скидку и получите еще баллы
      </Button>

      <CustomModal
        title={<Title className={styles.modalTitle}>Поделиться промокодом</Title>}
        isOpen={isModalOpen}
        onCancelClick={handleModalToggle}
        className={styles.customModal}
        centered
      >
        <div className={styles.modalContent}>
          <img src={PromoIcon} alt="" />

          <Paragraph className={styles.mainDescription}>
            Ваши друзья получат скидку по промокоду, а вы — бонусные баллы
          </Paragraph>

          <Paragraph className={styles.secondaryDescription}>
            Отправьте промокод вашему другу, чтобы он получил скидку на наши услуги. Другу необходимо будет сообщить код
            администратору при оформлении заказа в клинике. Код можно отправлять неограниченному количеству друзей, но
            каждый из них сможет применить его один раз.
          </Paragraph>

          <div className={styles.promoCodeWrapper}>
            <span className={styles.promoCode}>{promoCode}</span>
          </div>

          <Button type="primary" size="large" className={styles.submitButton} onClick={handleModalToggle}>
            Отправить код
          </Button>
        </div>
      </CustomModal>
    </>
  );
};

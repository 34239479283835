import { Button } from 'antd';
import React, { MouseEvent, ReactNode } from 'react';
import styles from './styles.module.scss';

interface TagProps {
  children: ReactNode;
  onClick?: (e?: MouseEvent) => void;
  className?: string;
  selected?: boolean;
  dataTestId?: string;
}

export const CustomDateTag = ({ children, onClick, className, selected = false, dataTestId }: TagProps) => {
  return (
    <Button
      onClick={onClick}
      data-test-id={dataTestId ? `button-${dataTestId}` : null}
      className={`${className ? className : ''}  ${selected ? styles.Active : ''} ${styles.CustomTag} `}
    >
      {children}
    </Button>
  );
};

import { CalendarRecordDump, Plus } from '../../common/icons';
import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { Layout, Row, Typography } from 'antd';
import React from 'react';
import styles from './styles.module.scss';

interface NoRecordsDumpProps {
  addNewRecordClick?: () => void;
}

export const NoRecordsDump = ({ addNewRecordClick }: NoRecordsDumpProps) => {
  return (
    <Layout className={styles.NoRecordsContainer}>
      <Row className={styles.DumpIconWrapper}>
        <CalendarRecordDump />
      </Row>
      <Row className={styles.RecordsDumpText}>
        <Typography>{'Здесь будут ваши записи на прием'}</Typography>
      </Row>
      <Row>
        <CustomButton isPrimal onClick={addNewRecordClick} className={styles.AddNewDumpDesktop}>
          <Row className={styles.AddButtonRow}>
            <Plus className={styles.PlusIcon} />
            <Typography className={styles.AddNewText}>Добавить запись</Typography>
          </Row>
        </CustomButton>
      </Row>
    </Layout>
  );
};

import { CloseModalMobileButton } from '../../icons';
import { FC, ReactNode } from 'react';
import { Modal, ModalProps } from 'antd';
import cx from 'classnames';
import styles from './styles.module.scss';

interface CustomModalProps extends Omit<ModalProps, 'open' | 'onOk' | 'onCancel' | 'className'> {
  className?: string;
  children?: ReactNode;
  isOpen: boolean;
  onOkClick?: () => void;
  onCancelClick?: () => void;
}

export const CustomModal: FC<CustomModalProps> = ({
  children,
  className,
  isOpen,
  onOkClick,
  onCancelClick,
  title,
  ...rest
}) => (
  <Modal
    title={title}
    open={isOpen}
    onOk={onOkClick}
    onCancel={onCancelClick}
    className={cx(styles.DefaultModalContainer, className)}
    closeIcon={<CloseModalMobileButton />}
    footer={null}
    {...rest}
  >
    {children}
  </Modal>
);

import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Form, Row, Typography, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CustomAutocompleteSearch } from '../../common/components/CustomAutocompleteSearch/CustomAutocompleteSearch';
import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { CustomInput } from '../../common/components/CustomInput/CustomInput';
import { PhoneInput } from '../../common/components/PhoneInput/PhoneInput';
import { SubmitModal } from './SubmitModal';
import { WhiteRow } from '../../common/components/WhiteRow/WhiteRow';
import { useDebounce } from '../../common/hooks/useDebounce';
import { useForm } from 'antd/es/form/Form';
import { useGetWhiteLabelInfoQuery } from '../../common/redux/api/apiWhiteLabelSlice';
import { useLazyGetClinicsSearchQuery, useSendMessageToSupportMutation } from '../../common/redux/api/apiPatientSlice';
import FloatLabelInput from '../../common/components/FloatLabelInput/FloatLabelInput';
import cx from 'classnames';
import styles from './styles.module.scss';

interface FormType {
  customFields: string[];
  customFieldsValues: {
    [key: string]: string;
  };
  setCustomFieldsValues: (values: { [key: string]: string }) => void;
  idProfile: string;
}

type FormFields = {
  subject: string;
  message: string;
  middleName: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export const ClinicForm: FC<FormType> = ({ customFields, customFieldsValues, setCustomFieldsValues, idProfile }) => {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [searchInput, setSearchInput] = useState<string>();
  const [idMedOrganization, setIdMedOrganization] = useState('');
  const debouncedSearch = useDebounce(searchInput || '', 300);

  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const [search, searchQuery] = useLazyGetClinicsSearchQuery();
  const [sendMessage] = useSendMessageToSupportMutation();

  useEffect(() => {
    if (debouncedSearch && searchInput) search({ search: searchInput });
  }, [debouncedSearch, search, searchInput]);

  const searchOptions = useMemo(
    () =>
      !searchQuery.isLoading
        ? searchQuery.data?.map(({ shortName }, index) => ({
            key: index.toString(),
            value: shortName,
            label: shortName,
          }))
        : [],
    [searchQuery.data, searchQuery.isLoading],
  );

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    setIdMedOrganization(
      searchQuery?.data?.find(({ shortName }) => shortName === e.target.value)?.idMedOrganization || '',
    );
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSubmitDisabled = (e: CheckboxChangeEvent) => setSubmitDisabled(!e.target.checked);

  useEffect(() => form.setFieldsValue(customFieldsValues), [customFieldsValues, form]);

  const handleValuesChange = () => setCustomFieldsValues(form.getFieldsValue(customFields));

  const handleSetIdMedOrganization = (value: string) => {
    setSearchInput(value);
    setIdMedOrganization(searchQuery?.data?.find(({ shortName }) => shortName === value)?.idMedOrganization || '');
  };

  const handleFormSubmit = (values: FormFields) => {
    sendMessage({ ...values, idMedOrganization, idProfile })
      .unwrap()
      .then(() => {
        form.resetFields();
        notification.success({ message: 'Обращение успешно отправлено' });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка отправления сообщения' });
      });
  };

  const phoneValue = Form.useWatch('phone', form);

  return (
    <>
      <WhiteRow className={styles.WhiteCol}>
        <Typography.Title level={3} className={styles.Title}>
          Написать в клинику
        </Typography.Title>
        <Form form={form} className={styles.Form} onValuesChange={handleValuesChange} onFinish={handleFormSubmit}>
          <Row>
            <Col>
              <CustomAutocompleteSearch
                floatLabel={isWhiteLabelEnabled ? 'Выберите клинику' : 'Выберите клинику из каталога Mila'}
                inputName="clinic"
                options={searchOptions ?? []}
                handleChangeValue={handleSetIdMedOrganization}
                onChange={handleSearchValue}
                className={cx(styles.FloatLabel, styles.ClinicChoose)}
                value={searchInput}
              />
              <Form.Item
                name="subject"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите тему',
                  },
                ]}
              >
                <CustomInput floatLabel="Тема *" floatLabelClassName={styles.FloatLabel} />
              </Form.Item>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите сообщение',
                  },
                ]}
                className={styles.TextArea}
              >
                <CustomInput
                  floatLabel="Сообщение *"
                  isTextArea
                  floatLabelClassName={cx(styles.FloatLabel, styles.TextArea)}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите фамилию',
                  },
                ]}
              >
                <CustomInput floatLabel="Фамилия *" className={cx(styles.FloatLabel, styles.FloatLabelData)} />
              </Form.Item>
              <Row>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста введите имя',
                    },
                  ]}
                >
                  <CustomInput floatLabel="Имя *" className={cx(styles.FloatLabel, styles.FloatLabelData)} />
                </Form.Item>
                <Form.Item name="middleName">
                  <CustomInput
                    floatLabel="Отчество пациента"
                    className={cx(styles.FloatLabel, styles.FloatLabelData)}
                  />
                </Form.Item>
              </Row>
              <FloatLabelInput
                className={cx(styles.FloatLabel, styles.FloatLabelDataPhone)}
                label="Телефон  *"
                value={phoneValue}
              >
                <PhoneInput />
              </FloatLabelInput>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите корректную электронную почту',
                    pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                  },
                ]}
              >
                <CustomInput
                  floatLabel="E-mail для получения ответа *"
                  className={cx(styles.FloatLabel, styles.FloatLabelData)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.BottomAlign}>
            <Col>
              <Checkbox className={styles.Agree} onChange={handleSubmitDisabled}>
                Даю согласие на обработку своих персональных данных (имя, отчество, фамилия, телефон, почта) в
                соответствии с требованиям ФЗ №152 от 27.07.2006
              </Checkbox>
            </Col>
            <Col>
              <CustomButton
                htmlType="submit"
                isPrimal
                className={styles.Button}
                disabled={isSubmitDisabled || !idMedOrganization}
              >
                <Typography.Text>Отправить</Typography.Text>
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </WhiteRow>
      <SubmitModal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} />
    </>
  );
};

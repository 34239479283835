import React, { useState } from 'react';

import { Image, Row, Typography } from 'antd';
import pic1 from './assets/1.png';
import pic2 from './assets/2.png';
import pic3 from './assets/3.png';
import pic4 from './assets/4.png';
import pic5 from './assets/5.png';
import pic6 from './assets/6.png';
import pic7 from './assets/7.png';
import pic8 from './assets/8.png';
import styles from './styles.module.scss';

export const Instruction = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Row className={styles.Area}>
      <Typography onClick={() => setIsOpen((prev) => !prev)} className={`${styles.Title} ${isOpen ? styles.Open : ''}`}>
        Как подписать согласие на участие в клинических исследованиях
      </Typography>
      <div className={`${styles.Blocks} ${isOpen ? styles.Open : ''}`}>
        <div className={styles.Block}>
          <div className={styles.Number}>1</div>
          <Typography>
            Врач предлагает пациенту участие в КИ и отвечает на вопросы об исследовании и лекарственном препарате
          </Typography>
          <Image src={pic1} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>2</div>
          <Typography>Пациент дает предварительное устное согласие на участие в КИ</Typography>
          <Image src={pic2} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>3</div>
          <Typography>
            Врач вносит пациента в реестр потенциальных участников КИ и направляет ему электронные документы для
            подписания через подсистему MILA
          </Typography>
          <Image src={pic3} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>4</div>
          <Typography>
            Пациент получает уведомление на электронную почту о поступлении документов на подписание со ссылкой для
            перехода в подсистему MILA
          </Typography>
          <Image src={pic4} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>5</div>
          <Typography>
            Пациент авторизуется в подсистеме MILA посредством своей учетной записи Госуслуг и получает доступ к
            электронным документам
          </Typography>
          <Image src={pic5} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>6</div>
          <Typography>
            Пациент знакомится с электронными документами, принимает решение о согласии на участие в КИ, либо об отказе
            от участия в КИ и подписывает соответствующие формы электронных документов
          </Typography>
          <Image src={pic6} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>7</div>
          <Typography>
            Врач получает подписанные пациентом документы (согласие или отказ) и подписывает их со своей стороны.{' '}
            <Typography.Text className={styles.Bold}>
              <br />
              Электронные документы вступают в силу с момента подписания их врачом.
            </Typography.Text>
          </Typography>
          <Image src={pic7} preview={false} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Number}>8</div>
          <Typography>
            Пациент получает уведомление на e-mail о подписании документов врачом и вступлении согласия (или отказа) в
            силу. В подсистеме MILA статус согласования изменится на "Решение принято". Подписанные электронные
            документы будут храниться в подсистеме MILA.
          </Typography>
          <Image src={pic8} preview={false} />
        </div>
      </div>
    </Row>
  );
};

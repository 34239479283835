import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CaretRight, GreenCheckIcon, Plus } from 'src/common/icons';
import { Col, Divider, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Link, useNavigate } from 'react-router-dom';
import { ProfileRow } from './ProfileRow/PatientOption';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { handleAddEsiaAccount } from '../../common/utils';
import { useAppSelector } from '../../app/hooks';
import {
  useGetPaymentsQuery,
  useGetProfileNotificationsQuery,
  useGetProfilesQuery,
} from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import Smile from 'src/assets/Smile.png';
import styles from './styles.module.scss';

export const Profile = () => {
  const navigate = useNavigate();
  const { data: profiles, isFetching } = useGetProfilesQuery();
  const { data: notifications } = useGetProfileNotificationsQuery({ count: 10, offset: 0, sort: 'asc' });
  const { data: payments } = useGetPaymentsQuery({
    count: 1,
    offset: 0,
    sort: '',
    type: 'payments',
  });
  const { data: refunds } = useGetPaymentsQuery({
    count: 1,
    offset: 0,
    sort: '',
    type: 'refunds',
  });
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const unViewedNotifications = useMemo(() => notifications?.unreadCount, [notifications]);

  const myProfile = useMemo(() => profiles?.find((profile) => profile.itSelf), [profiles]);
  const connectedProfiles = useMemo(() => profiles?.filter((profile) => !profile.itSelf), [profiles]);
  const isEsiaConnectedProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.isConfirmed, [profiles]);

  const handleAddProfile = () => navigate(`/settings/userProfile`);

  return (
    <>
      <BackHeader title="Профили" addButtonText="Добавить профиль" handleAddClick={handleAddProfile} />
      <WhiteRow className={styles.ProfileRow}>
        <Typography.Title level={3} className={styles.Title}>
          Основной профиль
        </Typography.Title>
        {myProfile && <ProfileRow profile={myProfile} />}
        <Typography.Title level={4} className={styles.Subtitle}>
          Управление аккаунтом
        </Typography.Title>
        <Divider className={styles.Divider} />

        <Row className={styles.Row}>
          <Typography.Text className={styles.GreyText}>Вход через Госуслуги</Typography.Text>
          {isEsiaConnectedProfile ? (
            <Row className={styles.GreenRow}>
              <GreenCheckIcon /> <Typography>Подключен</Typography>
            </Row>
          ) : (
            <CustomButton className={styles.Button}>
              <Typography onClick={() => handleAddEsiaAccount(keycloakObj)}>Подключить</Typography>
            </CustomButton>
          )}
        </Row>
        {/*<Divider className={styles.Divider} />   // asked to hide this
        <Row className={styles.Row}>
          <Typography.Text className={styles.GreyText}>Вход через Сбер ID</Typography.Text>
          <CustomButton className={styles.Button}>
            <Typography>Подключить</Typography>
          </CustomButton>
  </Row>*/}

        {/* Commented according to https://jira.n3med.ru/browse/MILA-215 */}
        {/* <Divider className={styles.Divider} />
        <Link to={`/settings/userProfile/payments`} className={styles.LinkRow}>
          <Typography.Text className={styles.GreyText}>Платежи</Typography.Text>
          <Col className={styles.Col}>
            <Typography.Text className={styles.RoundText}>{payments?.total}</Typography.Text>
            <CaretRight />
          </Col>
        </Link>
        <Divider className={styles.Divider} />
        <Link to={`/settings/userProfile/moneyBacks`} className={styles.LinkRow}>
          <Typography.Text className={styles.GreyText}>Возвраты</Typography.Text>
          <Col className={styles.Col}>
            <Typography.Text className={styles.RoundText}>{refunds?.total}</Typography.Text>
            <CaretRight />
          </Col>
        </Link> */}
        <Divider className={styles.Divider} />
        <Link to={`/settings/userProfile/notifications`} className={styles.LinkRow}>
          <Typography className={styles.GreyText}>Уведомления</Typography>
          <Col className={styles.Col}>
            <Typography.Text className={styles.RoundText}>{unViewedNotifications}</Typography.Text>
            <CaretRight />
          </Col>
        </Link>
        <Divider className={styles.Divider} />
        <Link to={`/consent`} className={styles.LinkRow}>
          <Typography.Text className={styles.GreyText}>Согласия на доступ к данным</Typography.Text>
          <Col className={styles.Col}>
            <CaretRight />
          </Col>
        </Link>
        <Divider className={styles.Divider} />
        <Link to="/help" className={styles.LinkRow}>
          <Typography.Text className={styles.GreyText}>Помощь</Typography.Text>
          <Col className={styles.Col}>
            <CaretRight />
          </Col>
        </Link>
      </WhiteRow>
      {isFetching ? (
        <CustomSpinner />
      ) : (
        connectedProfiles?.length === 0 && (
          <Row className={styles.VerticalRow}>
            <Image src={Smile} preview={false} rootClassName={styles.ImagePlaceholder} />
            <Typography.Text className={styles.Text}>
              Добавьте ваших близких, чтобы заботиться об их здоровье
            </Typography.Text>
          </Row>
        )
      )}
      <WhiteRow className={styles.ProfileRow}>
        <Typography.Title level={3} className={styles.Title}>
          Связанные профили
        </Typography.Title>
        {connectedProfiles?.map((profile) => (
          <ProfileRow profile={profile} key={profile.idProfile} />
        ))}

        <CustomButton className={styles.AddButton} onClick={handleAddProfile}>
          <Row className={styles.Col}>
            <Plus />
            <Typography.Text>Добавить профиль</Typography.Text>
          </Row>
        </CustomButton>
      </WhiteRow>
    </>
  );
};

export type HistoryData = {
  date: string;
  amount: string;
  details?: string;
  description: string;
};

export const historyData: HistoryData[] = [
  {
    date: '03.02.2025',
    amount: '+2000',
    details: 'Используйте до 16 февраля 2025',
    description: 'С годовщиной регистрации в программе!',
  },
  {
    date: '01.02.2025',
    amount: '-3000',
    description: 'Автоматическое списание "Новогодние бонусы от ЛабКвест", начисленных 31.12.2024',
  },
  {
    date: '31.12.2024',
    amount: '+3000',
    details: 'Используйте до 1 февраля 2025',
    description: 'Новогодние бонусы от ЛабКвест',
  },
  {
    date: '03.02.2024',
    amount: '+2000',
    description: 'Начисление за заказ № 00001047334',
  },
];

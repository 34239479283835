import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CustomCardTabs } from '../../../common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { Image, Row, Typography } from 'antd';
import { NotificationEntity, NotificationsType } from '../../../common/types';
import { NotificationMessage } from './NotificationMessage';
import { TMKChatWidget } from '../../../common/components/TMKChatWidget/TMKChatWidget';
import {
  getLinkNotification,
  getNotificationTagName,
  getNotificationTimeLabel,
  removeDuplicatesById,
} from '../../../common/utils';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetProfileNotificationsQuery,
  useGetTmkChatsQuery,
  useGetTmkUserInfoQuery,
} from '../../../common/redux/api/apiPatientSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import StubImage from 'src/assets/MessageStub.png';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface NotificationsTab {
  label: string;
  key: string;
}

interface NotificationsProps {
  showChats?: boolean;
}

export const Notifications = ({ showChats = false }: NotificationsProps) => {
  const navigate = useNavigate();
  const [notificaionsListState, setNotificationsList] = useState<NotificationEntity[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setNextPage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const pageCount = 5;
  const [room, setRoom] = useState(searchParams.get('room'));

  const {
    data: notifications,
    isLoading,
    refetch: refetchNotifications,
  } = useGetProfileNotificationsQuery({
    count: pageCount,
    offset: pageOffset,
    sort: 'created desc',
  });

  const { data: chats } = useGetTmkChatsQuery();
  const { data: tmkUserInfo } = useGetTmkUserInfoQuery();

  const hasActiveNotifications = useMemo(() => notifications?.unreadCount !== 0, [notifications]);

  const unViewedNotifications = useMemo(() => notifications?.unreadCount, [notifications]);

  const tabItems = useMemo(
    () =>
      [
        {
          label: (
            <Row>
              <Typography>Уведомления</Typography>
              <Row
                className={`${styles.NotificationsTabNumber} ${
                  !hasActiveNotifications && styles.NotificationsTabNumberInactive
                }  ${unViewedNotifications === 0 && styles.HiddenLabel}`}
              >
                <Typography.Text>{unViewedNotifications}</Typography.Text>
              </Row>
            </Row>
          ),
          key: 'notifications',
        },
        { label: `Чаты с клиниками`, key: 'chats' },
      ].filter((x) => x.key !== 'chats'),
    [hasActiveNotifications, unViewedNotifications],
  );

  useEffect(() => {
    currentPage === 1 && !showChats && setNotificationsList(notifications?.data!);
  }, [notifications?.data, currentPage, showChats]);

  useEffect(() => {
    if (showChats) {
      if (!room && chats) {
        setRoom(chats[0].roomId);
        setSearchParams({ room: chats[0].roomId });
      } else {
        setSearchParams(room && room?.length > 0 ? { room: room || '' } : {});
      }
    } else if (searchParams.get('room')) {
      setSearchParams({});
    }
  }, [showChats, room, chats, setSearchParams]);

  const loadMoreData = () => {
    const pageNumber = currentPage + 1;
    setNextPage(pageNumber);
    setPageOffset((currentPage - 1) * pageCount + 1);
    refetchNotifications()
      .unwrap()
      .then((payload: NotificationsType) => {
        setNotificationsList(notificaionsListState.concat(payload.data));
      });
  };

  const handleTabChange = (key: string) => {
    navigate(`/settings/userProfile/${key}${room ? `?room=${room}` : ''}`);
  };

  const uniqueNotificationsList = useMemo(() => removeDuplicatesById(notificaionsListState), [notificaionsListState]);

  return (
    <>
      <BackHeader title="Уведомления" />
      <CustomCardTabs
        items={tabItems as NotificationsTab[]}
        onChange={handleTabChange}
        activeKey={showChats ? 'chats' : 'notifications'}
      />
      <Row className={styles.NotificationsWrapper}>
        <div className={`${styles.Chats} ${showChats ? styles.ShowChats : ''}`}>
          {room && tmkUserInfo && chats && chats?.length > 0 ? (
            <TMKChatWidget roomId={room} sessionId={tmkUserInfo.sessionId} userId={tmkUserInfo.userId} />
          ) : (
            <Row className={styles.StubContainer}>
              <Image className={styles.Image} src={StubImage} preview={false} />
              <Typography>Здесь будут чаты с клиниками</Typography>
            </Row>
          )}
        </div>

        {!showChats && (
          <>
            {isLoading ? (
              <CustomSpinner />
            ) : notificaionsListState?.length > 0 ? (
              <InfiniteScroll
                dataLength={notificaionsListState?.length || 0}
                next={loadMoreData}
                hasMore={!!notifications && notifications.total > uniqueNotificationsList?.length}
                loader={<CustomSpinner />}
                pullDownToRefresh
                refreshFunction={loadMoreData}
                pullDownToRefreshThreshold={300}
                className={styles.ScrollableDiv}
                scrollThreshold={'200px'}
              >
                {uniqueNotificationsList?.map((notification: NotificationEntity) => (
                  <NotificationMessage
                    title={getNotificationTimeLabel(notification.created)}
                    text={notification?.content?.content}
                    tagName={getNotificationTagName(notification.type)}
                    idNotification={notification.idNotification}
                    key={notification.idNotification}
                    isViewed={notification.isViewed}
                    link={getLinkNotification(notification.type, notification.content.actionEntityId)}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <Row className={styles.StubContainer}>
                <Image className={styles.Image} src={StubImage} preview={false} />
                <Typography>Здесь будут ваши уведомления</Typography>
              </Row>
            )}
          </>
        )}
      </Row>
    </>
  );
};

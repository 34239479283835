import { Col, Image, Progress, Row, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { CustomModal } from '../CustomModal/CustomModal';
import { EstimateCard } from '../EstimateCard/EstimateCard';
import { MilaMessage } from '../MilaMessage/MilaMessage';
import { ProfileBlock } from '../../../features/Appointment/ProfileBlock/ProfileBlock';
import { ReplaceAvatar } from '../ReplaceAvatar/ReplaceAvatar';
import { Reviews } from '../../types';
import { Star } from '../../../common/icons';
import { useNavigate } from 'react-router-dom';
import Calendar from 'src/assets/Calendar.svg';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const formatName = (name: string) => `${name.split(' ')[1]} ${name.split(' ')[0][0]}...`;
const plural = require('plural-ru');

const numberFormat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
});

interface DoctorReviewsBlockProps {
  isClinicReviews?: boolean;
  reviewsList: Reviews;
}

const spawnStars = (index: number) => {
  let stars = [];
  for (let i = 0; i < index; i++) {
    stars.push(<Star key={`${i}-${index}`} />);
  }
  return stars;
};

export const DoctorReviewsBlock = ({ isClinicReviews, reviewsList }: DoctorReviewsBlockProps) => {
  const [isEstimateModalVisible, setEstimateModalVisible] = useState(false);
  const navigate = useNavigate();

  const scores = useMemo(
    () =>
      reviewsList &&
      Object.keys(reviewsList.reviewsScoresDistribution!)
        .reverse()
        .map((score) => (
          <Row className={styles.ProgressRow} key={score}>
            <Row className={styles.Stars}>{spawnStars(Number(score))}</Row>
            <Progress
              percent={(reviewsList.reviewsScoresDistribution![score] * 100) / reviewsList.reviewsCount!}
              showInfo={false}
              strokeColor={'#E79E44'}
              size={[178, 4]}
            />
          </Row>
        )),
    [reviewsList],
  );

  const sortedReviews = useMemo(
    () =>
      reviewsList && reviewsList.reviews!.length > 0
        ? [...reviewsList.reviews!].sort((a, b) => (a.reviewTimestamp > b.reviewTimestamp ? -1 : 1))
        : [],
    [reviewsList],
  );

  const handleReviewClick = () => {
    isClinicReviews ? navigate('/records?activeTab=past') : setEstimateModalVisible(true);
  };

  const handleEstimateCloseClick = () => {
    setEstimateModalVisible(false);
  };

  return (
    <>
      <Row>
        {reviewsList?.reviewsCount !== 0 && (
          <Typography.Title level={3} className={styles.ReviewsTitle}>
            {plural(reviewsList?.reviewsCount, '%d отзыв', '%d отзыва', '%d отзывов')}
          </Typography.Title>
        )}
      </Row>
      <Row className={styles.TotalReviewsAndButton}>
        <Row className={styles.TotalReviewsBlock}>
          <Col className={styles.Average}>
            <Typography.Text strong>{numberFormat.format(reviewsList?.reviewsAverageScore!)}</Typography.Text>
          </Col>
          {<Col className={styles.ProgressRows}>{scores}</Col>}
        </Row>
        <CustomButton
          className={styles.AddReview}
          disabled={
            (!reviewsList?.canRate && !!reviewsList?.lastPastAppointmentToBeRatedId) ||
            (!isClinicReviews && reviewsList?.lastPastAppointmentToBeRatedId === null)
          }
          onClick={handleReviewClick}
        >
          <Typography>{isClinicReviews ? 'Перейти в записи и оставить отзыв' : 'Оставить отзыв'}</Typography>
        </CustomButton>
      </Row>

      <MilaMessage
        title={reviewsList?.howToRateTipTitle}
        text={''}
        hiddenText={reviewsList?.howToRateTipHtmlBody}
        className={styles.Message}
      />
      {reviewsList && reviewsList.reviews!.length > 0 && (
        <Row>
          {sortedReviews &&
            sortedReviews.length > 0 &&
            sortedReviews?.map((review) => (
              <Row className={styles.Review} key={review.idMilaUserProfileAppointment}>
                <Row className={styles.Info}>
                  <Col className={styles.ImageCol}>
                    {review.idPatientPhoto ? (
                      <Image
                        src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${review.idPatientPhoto}`}
                        className={styles.Photo}
                        preview={false}
                      />
                    ) : (
                      <ReplaceAvatar
                        id={review.idMilaUserProfile}
                        classNameAvatar={styles.ImageReplace}
                        initials={formatName(review.patientShortName)[0]}
                      />
                    )}
                  </Col>
                  <Col className={styles.DataCol}>
                    <Col className={styles.CommentsBlockContentContainer}>
                      <Col className={styles.DoctorNameBlock}>
                        <Col>
                          <Typography.Title level={5} className={styles.Name}>
                            {review.patientShortName}
                          </Typography.Title>
                          <Typography.Text className={styles.Date}>
                            {dayjs(review.reviewTimestamp).format('DD MMMM hh:mm')}
                          </Typography.Text>
                        </Col>
                        <Col className={styles.RateBlock}>
                          <Typography.Text className={styles.Rate}>
                            {numberFormat.format(review.reviewScore)}
                          </Typography.Text>
                        </Col>
                      </Col>
                      <Col className={styles.CommentsBlockContainer}>
                        {isClinicReviews && (
                          <Col className={styles.DoctorBlock}>
                            <Typography.Title className={styles.CommentTitle} level={4}>
                              Врач
                            </Typography.Title>
                            <ProfileBlock
                              fio={review.doctorShortName}
                              role={review.doctorSpecialtyName}
                              fioTextClassName={styles.DoctorFio}
                              id={review.idMilaDoctor}
                              avatar={review.doctorPhotoStaticFileId}
                            />
                          </Col>
                        )}
                        <Col>
                          <Typography.Title className={styles.CommentTitle} level={4}>
                            Комментарий
                          </Typography.Title>
                          <Typography className={styles.Comment}>{review.reviewComment}</Typography>
                          <Row className={styles.CommentInfo}>
                            <Col className={styles.ImageCol}>
                              <Image src={Calendar} preview={false} />
                            </Col>
                            <Col className={styles.DataCol}>
                              <Typography.Text className={styles.ClinicText}>
                                Прием у врача {dayjs(review.appointmentTimestamp).format('DD MMMM YYYY')}
                              </Typography.Text>
                              <Typography.Text className={styles.ClinicText}>
                                {review.medOrganizationShortName} {review.medOrganizationAddress}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                    </Col>
                  </Col>
                </Row>
              </Row>
            ))}
        </Row>
      )}

      {reviewsList.lastPastAppointmentToBeRatedId && (
        <CustomModal isOpen={isEstimateModalVisible} onCancelClick={handleEstimateCloseClick}>
          <EstimateCard
            title={<Typography.Text>Пожалуйста, расскажите, как все прошло? </Typography.Text>}
            text={'Мне и врачу очень важен ваш отзыв'}
            rates={[{ rateTitle: 'Оцените работу врача', rateType: 'rate', ratePlaceholder: 'Напишите Ваш отзыв' }]}
            appointmentId={reviewsList.lastPastAppointmentToBeRatedId}
          />
        </CustomModal>
      )}
    </>
  );
};

import { CustomModal } from '../CustomModal/CustomModal';
import {
  MedicalDataAgreement as MilaMedicalAgreement,
  NotificationsDataAgreement as MilaNotificationsAgreement,
  ProcessPersonalDataAgreement as MilaProcessPersonalDataAgreement,
  ProcessPersonalDataAgreement75 as MilaProcessPersonalDataAgreement75,
} from './Agreements';
import { ReactNode, useMemo } from 'react';
import {
  idMedicalDataAccessConsent,
  idNotificationsAgreementConsent,
  idProcessPersonalDataAgreementConsent,
  idProcessPersonalDataAgreementConsent75,
} from '../../contants/constatns';
import styles from './styles.module.scss';

export interface AgreementModalProps {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  consentId: string;
}

export const AgreementModal = ({ consentId, handleCloseModal, isModalOpen }: AgreementModalProps) => {
  const modalContent = useMemo(() => {
    const contentMap: Record<string, ReactNode> = {
      [idMedicalDataAccessConsent]: MilaMedicalAgreement,
      [idNotificationsAgreementConsent]: MilaNotificationsAgreement,
      [idProcessPersonalDataAgreementConsent]: MilaProcessPersonalDataAgreement,
      [idProcessPersonalDataAgreementConsent75]: MilaProcessPersonalDataAgreement75,
    };

    return contentMap[consentId];
  }, [consentId]);

  return (
    <CustomModal isOpen={isModalOpen} onCancelClick={handleCloseModal} className={styles.agreementModalContainer}>
      {modalContent}
    </CustomModal>
  );
};

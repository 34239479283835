import { AvatarUploadForm } from './AvatarUploadForm';
import { BackHeader } from '../../../common/components/BackHeader/BackHeader';
import { Checkbox, Form, Radio, Row, Typography, notification } from 'antd';
import { CustomButton } from '../../../common/components/CustomButton/CustomButton';
import { CustomDatePicker } from '../../../common/components/CustomDatePicker/CustomDatePicker';
import { CustomInput } from '../../../common/components/CustomInput/CustomInput';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Gender } from 'src/common/types/enums';
import { GosUslugiBlock } from '../../../common/components/GosUslugiBlock/GosUslugiBlock';
import { PhoneInput } from '../../../common/components/PhoneInput/PhoneInput';
import { ProfilesSlider } from 'src/common/components/ProfilesSlider/ProfilesSlider';
import { RemoveProfileForm } from './RemoveProfileForm';
import { StatisticsTab } from '../../../common/components/StatisticsTab/StaticticsTab';
import { formatPhone } from 'src/common/utils';
import { streamProfileDocumentsCountApi } from '../../../common/redux/api/apiStreamSlice';
import { useAppSelector } from '../../../app/hooks';
import {
  useCreateEsiaAccountMutation,
  useCreateProfileMutation,
  useGetConsentsQuery,
  useGetProfileDocumentsCountQuery,
  useGetProfileInfoQuery,
  useGetProfilesQuery,
  useJoinEsiaAccountMutation,
  useUpdateProfileInfoMutation,
} from '../../../common/redux/api/apiPatientSlice';
import { useForm } from 'antd/es/form/Form';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Calendar from '../../../assets/Calendar.png';
import ClinicPlacemark from '../../../assets/ClinicPlacemark.png';
import EsiaLoginService from '../../../common/services/login/Esia/esia-login-service';
import FloatLabelInput from '../../../common/components/FloatLabelInput/FloatLabelInput';
import Lock from '../../../assets/Lock.png';
import MedCardlock from '../../../assets/Medcardlock.png';
import MedMask from '../../../assets/Medmask.png';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import keycloak from '../../../keycloak';
import styles from './styles.module.scss';
import { withId } from 'react-yandex-metrika';

interface ProfileFormProps {
  isNewProfile?: boolean;
}

export const ProfileForm = ({ isNewProfile = false }: ProfileFormProps) => {
  const navigate = useNavigate();
  const { profileId } = useParams();

  const [form] = useForm();
  const formRef = useRef<null | HTMLDivElement>(null);
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [_, setIsFormTouched] = useState(false);
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const { data: profiles, refetch: refetchProfiles } = useGetProfilesQuery();
  const {
    data: profile,
    isFetching: isLoadingProfile,
    refetch: refetchProfileData,
  } = useGetProfileInfoQuery(profileId!, { skip: !profileId });

  const { data: crId } = useGetProfileDocumentsCountQuery(profileId || '', { skip: !profileId });

  const [docsCount, setDocsCount] = useState<number>();
  const [newDocsCount, setNewDocsCount] = useState<number>();

  const [processDocumentsCountMessage] = streamProfileDocumentsCountApi((item) => {
    setNewDocsCount(item);
  });

  useEffect(() => {
    if (newDocsCount) {
      setDocsCount((prevState) => (prevState ? prevState + newDocsCount : newDocsCount));
    }
  }, [newDocsCount]);

  useEffect(() => {
    setDocsCount(undefined);
    processDocumentsCountMessage(crId);
  }, [crId]);

  const { data: consents } = useGetConsentsQuery(profileId!, { skip: !profileId });
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const thisProfile = useMemo(() => profiles?.find(({ idProfile }) => idProfile === profileId), [profiles, profileId]);

  const [createProfile] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileInfoMutation();
  const [createEsiaAccount] = useCreateEsiaAccountMutation();
  const [joinEsiaAccount] = useJoinEsiaAccountMutation();

  const handleOpenAvatarModal = () => setIsAvatarModalOpen(true);
  const handleCloseAvatarModal = useCallback(() => setIsAvatarModalOpen(false), []);

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true);
    withId(96307853)('reachGoal', 'udalit-profil');
  };

  const handleCloseRemoveModal = () => setIsRemoveModalOpen(false);

  const handleEditClick = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const addEsiaProfile = () => {
    EsiaLoginService.Login().then((accessToken) => {
      createEsiaAccount(accessToken)
        .unwrap()
        .then(() => {
          notification.success({ message: 'Новый профиль успешно добавлен через Госуслуги' });
          refetchProfiles();
          navigate('/settings');
        })
        .catch(() => notification.error({ message: 'Ошибка добавления профиля' }));
    });
  };

  const connectEsiaProfile = () => {
    EsiaLoginService.Login().then((accessToken) => {
      joinEsiaAccount({ accessToken, idProfile: profileId! })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Профиль успешно подключен к Госуслугам' });
          refetchProfileData();
        })
        .catch(() => notification.error({ message: 'Ошибка подключения профиля к Госуслугам' }));
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [profile]);

  const handleSubmitForm = () => {
    createProfile({ ...form.getFieldsValue() })
      .unwrap()
      .then(() => {
        withId(96307853)('reachGoal', 'uspeshnoe-dobavlenie-profilya');
        notification.success({ message: 'Новый профиль успешно добавлен' });
        navigate(`/settings`);
      })
      .catch(() => {
        notification.error({ message: 'Ошибка добавления профиля' });
      });
  };

  const handleNextProfileClick = (id: string) => {
    navigate(`/settings/userProfile/${id}`);
    setIsFormTouched(false);
  };

  const handleSaveChanges = async () => {
    await form.validateFields();
    updateProfile({ idProfile: profileId!, data: { ...form.getFieldsValue(), getAgreement: true } })
      .unwrap()
      .then(() => {
        notification.success({ message: 'Профиль успешно изменен' });
        refetchProfiles();
        refetchProfileData();
      })
      .catch(() => {
        notification.error({ message: 'Ошибка изменения данных профиля' });
      });
  };

  const handleLogout = () => {
    keycloak(keycloakObj).logOut();
  };

  const statTabs = useMemo(
    () => [
      {
        icon: MedCardlock,
        tabName: 'Медкарта',
        tabNumber: docsCount || 0,
        href: `/medcards/${profile?.idProfile}`,
      },
      {
        icon: Calendar,
        tabName: 'Записи',
        tabNumber: profile?.appointmentsCount,
        href: `/records?profileId=${profile?.idProfile}`,
      },
      {
        icon: ClinicPlacemark,
        tabName: 'Клиники',
        tabNumber: profile?.myClinicsCount,
        href: `/settings/userProfile/myClinics?profileId=${profile?.idProfile}`,
      },
      {
        icon: MedMask,
        tabName: 'Врачи',
        tabNumber: profile?.myDoctorsCount,
        href: `/settings/userProfile/myDoctors?profileId=${profile?.idProfile}`,
      },
      {
        icon: Lock,
        tabName: 'Согласия',
        tabNumber: consents?.filter((consent) => consent.isActive)?.length ?? 0,
        href: `/consent?profileId=${profile?.idProfile}`,
      },
      // {
      //   icon: Points,
      //   tabName: 'Баллы',
      //   tabNumber: '4000', // FIXME
      //   href: `/points?profileId=${profile?.idProfile}`,
      // },
    ],
    [docsCount, profile, consents],
  );

  const stats = useMemo(
    () =>
      statTabs.map((tab) => (
        <StatisticsTab
          icon={tab.icon}
          tabName={tab.tabName}
          //@ts-ignore
          tabNumber={tab.tabNumber!}
          className={styles.StatisticsTabWrapper}
          key={tab.tabName}
          href={tab.href}
        />
      )),
    [statTabs],
  );

  const initialValues = {
    ...profile,
    birthDay: profile?.birthDay ? dayjs(profile?.birthDay) : '',
    snils:
      profile?.snils && profile?.snils?.length > 10
        ? `${profile?.snils.substring(0, 3)}-${profile?.snils.substring(3, 6)}-${profile?.snils.substring(
            6,
            9,
          )}-${profile?.snils.substring(9)}`
        : profile?.snils,
    phone: formatPhone(profile?.phone),
  };

  const phoneValue = Form.useWatch('phone', form);

  return (
    <>
      <BackHeader title="Профили" />
      {isNewProfile ? (
        <>
          <GosUslugiBlock
            rowType={true}
            buttonText="Добавить через Госуслуги"
            smallText={
              <div>
                Чтобы автоматически получать данные из клиник для нового профиля войдите в <b>его</b> аккаунт на
                Госуслугах. Так мы будем уверены, что вы имеете право на доступ к медкарте этого пользователя. <br />
                <a
                  className={styles.Link}
                  target="_blank"
                  href="https://www.gosuslugi.ru/help/faq/login_child/102380"
                  rel="noreferrer"
                >
                  Инструкция: как создать учётную запись ребёнка на Госуслугах
                </a>
              </div>
            }
            className={styles.EsiaBlock}
            buttonHandler={addEsiaProfile}
          />
          <Row className={styles.GosuslugiExtendedTextBlock}>
            <Typography className={styles.BoldText}>или заполните данные вручную</Typography>
            <Typography className={styles.GreySmallText}>
              сможете записывать пользователя на прием, но не сможете получать медицинские документы из клиник
            </Typography>
          </Row>
        </>
      ) : (
        <>
          <ProfilesSlider
            onNext={handleNextProfileClick}
            onAvatarClick={handleOpenAvatarModal}
            onEditClick={handleEditClick}
          />

          <Row className={styles.DesktopSliderRow}>{stats}</Row>
          <Row className={styles.MobileSliderRow}>{stats}</Row>
        </>
      )}
      {
        <AvatarUploadForm
          isModalOpen={isAvatarModalOpen}
          profileId={profileId}
          photoId={profile?.idPhoto ?? null}
          refetchFunc={() => {
            refetchProfiles();
            refetchProfileData();
          }}
          handleCloseModal={handleCloseAvatarModal}
        />
      }
      {!profile?.isConfirmed && !isNewProfile && (
        <GosUslugiBlock
          buttonText="Подтвердить через Госуслуги"
          smallText={
            <>
              Чтобы автоматически получать данные из клиник для этого профиля войдите в его аккаунт на Госуслугах. Так
              мы будем уверены, что вы имеете право на доступ к медкарте этого пользователя
            </>
          }
          title="Подтвердите учетную запись"
          className={styles.EsiaBlock}
          buttonHandler={connectEsiaProfile}
        />
      )}
      {isLoadingProfile ? (
        <CustomSpinner />
      ) : (
        <Form
          form={form}
          onFinish={handleSubmitForm}
          onValuesChange={() => setIsFormTouched(true)}
          key={profileId}
          initialValues={initialValues}
        >
          <Row className={styles.InputsBlock} ref={formRef}>
            <Typography className={styles.FormTitle}>Заполните данные</Typography>

            <Row className={styles.InputFullNameColumn}>
              <Row className={styles.InputRowContainer}>
                <Form.Item
                  name={'surname'}
                  className={styles.FormItemWrapper}
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, введите фамилию',
                    },
                  ]}
                >
                  <CustomInput
                    floatLabel={'Фамилия пациента*'}
                    value={form.getFieldValue('surname')}
                    className={styles.InputField}
                    disabled={profile?.isConfirmed}
                  />
                </Form.Item>
                <Form.Item
                  name={'name'}
                  className={styles.FormItemWrapper}
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, введите имя',
                    },
                  ]}
                >
                  <CustomInput
                    floatLabel={'Имя пациента*'}
                    value={form.getFieldValue('name')}
                    className={styles.InputField}
                    disabled={profile?.isConfirmed}
                  />
                </Form.Item>
              </Row>

              <Row className={styles.InputRowContainer}>
                <Form.Item className={styles.FormItemWrapper} name={'middleName'}>
                  <CustomInput
                    floatLabel={'Отчество пациента'}
                    value={form.getFieldValue('middleName')}
                    className={styles.InputField}
                    disabled={profile?.isConfirmed}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.FormItemWrapper}
                  name={'birthDay'}
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, введите дату рождения',
                    },
                  ]}
                >
                  <CustomDatePicker
                    floatLabel="Дата рождения пациента*"
                    value={form.getFieldValue('birthDay')}
                    disabled={profile?.isConfirmed}
                  />
                </Form.Item>
              </Row>
            </Row>

            <Row className={styles.RadioGroupWrapper}>
              <Typography className={styles.RadioLabel}>Пол *</Typography>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, укажите пол',
                  },
                ]}
              >
                <Radio.Group value={profile?.gender}>
                  <Radio value={Gender.Female} disabled={profile?.isConfirmed}>
                    Женский
                  </Radio>
                  <Radio value={Gender.Male} disabled={profile?.isConfirmed}>
                    Мужской
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Row>
            <Row className={styles.InputColumnWrapper}>
              <Row className={styles.InputColumnContainer}>
                <FloatLabelInput label="Телефон пациента / законного представителя  *" value={phoneValue}>
                  <PhoneInput disabled={profile?.isEsiaPhone} className={styles.InputField} />
                </FloatLabelInput>
                <Form.Item
                  className={styles.FormItemWrapper}
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста, введите корректный email',
                      pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                    },
                  ]}
                >
                  <CustomInput
                    floatLabel={'E-mail*'}
                    value={form.getFieldValue('email')}
                    className={styles.InputField}
                    disabled={!isNewProfile && !!profile?.isEsiaEmail}
                  />
                </Form.Item>
                <Form.Item className={styles.FormItemWrapper} name={'address'}>
                  <CustomInput
                    floatLabel={'Адрес пациента'}
                    value={form.getFieldValue('address')}
                    className={styles.InputField}
                    isTextArea
                    textAreaRows={3}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputColumnContainer}>
                <Form.Item
                  className={styles.FormItemWrapper}
                  name={'snils'}
                  rules={[
                    {
                      message: 'Пожалуйста, введите корректный СНИЛС',
                      pattern: /\d{3}-\d{3}-\d{3}-\d{2}/,
                    },
                  ]}
                >
                  <CustomInput
                    mask="999-999-999-99"
                    floatLabel={'СНИЛС'}
                    value={form.getFieldValue('snils')}
                    className={styles.InputField}
                    disabled={profile?.isConfirmed}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.FormItemWrapper}
                  name={'oms'}
                  rules={[
                    {
                      message: 'Пожалуйста, введите корректный полис ОМС',
                      pattern: /\d{16}/,
                    },
                  ]}
                >
                  <CustomInput
                    mask={'9999999999999999'}
                    floatLabel={'Номер полиса ОМС (16 цифр)'}
                    value={form.getFieldValue('oms')}
                    className={styles.InputField}
                  />
                </Form.Item>
                <Form.Item className={styles.FormItemWrapper} name={'milaName'}>
                  <CustomInput
                    floatLabel={`Отображаемое имя ${!whiteLabel?.isServiceEnabled ? 'в MILA' : ''}`}
                    value={form.getFieldValue('milaName')}
                    className={styles.InputField}
                  />
                </Form.Item>
                {!isNewProfile && (
                  <Row className={styles.SaveProfileButtonWrapper}>
                    <CustomButton className={styles.AddProfileButton} isPrimal onClick={handleSaveChanges}>
                      <Typography>Сохранить</Typography>
                    </CustomButton>
                  </Row>
                )}
              </Row>
            </Row>

            {isNewProfile && (
              <Row className={styles.AddProfileButtonWrapper}>
                <Row className={styles.CheckRow}>
                  <Form.Item
                    name="getAgreement"
                    valuePropName="checked"
                    rules={[
                      {
                        required: true,
                        message: 'Пожалуйста, подтвердите, что согласны на обработку',
                      },
                    ]}
                  >
                    <Checkbox>Получено согласие третьих лиц на передачу и обработку их персональных данных</Checkbox>
                  </Form.Item>
                </Row>
                <CustomButton className={styles.AddProfileButton} onClick={form.submit}>
                  <Typography>Добавить профиль</Typography>
                </CustomButton>
              </Row>
            )}
          </Row>
        </Form>
      )}
      {!isNewProfile && (
        <>
          <Row className={styles.FormButtonsBlock}>
            {thisProfile?.itSelf && (
              <CustomButton className={styles.AddProfileButton} onClick={handleLogout}>
                <Typography>Выйти из профиля</Typography>
              </CustomButton>
            )}

            <Row className={styles.RemoveProfileLink}>
              <Typography.Link className={styles.RemoveProfileLinkText} onClick={handleOpenRemoveModal}>
                Удалить профиль
              </Typography.Link>
            </Row>
          </Row>
          <RemoveProfileForm
            isModalOpen={isRemoveModalOpen}
            handleCloseModal={handleCloseRemoveModal}
            userId={profileId}
            itSelf={thisProfile?.itSelf}
          />
        </>
      )}
    </>
  );
};

import { ClinicLabel } from 'src/common/components/ClinicLabel/ClinicLabel';
import { Col, Divider, Image, notification, Row, Tooltip, Typography } from 'antd';
import { Cross, GreenCheckIcon, DocumentSettings, Trash } from 'src/common/icons';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { DiagnosisLabel } from 'src/common/components/DiagnosisLabel/DiagnosisLabel';
import { DoctorLabel } from 'src/common/components/DoctorLabel/DoctorLabel';
import { DocumentUploadedByWhomType } from 'src/common/types/enums';
import { MedcardDocument } from 'src/common/types';
import { MedcardDocumentLoadForm } from '../MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { getIconByDocumentType } from '../MedCardDocuments/MedCardDocuments';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import { useRemoveMedcardDocumentMutation } from '../../../common/redux/api/apiPatientSlice';
import { DocumentViewerModal } from 'src/common/components/DocumentViewerModal/DocumentViewerModal';
import cx from 'classnames';

interface MedCardDocumentProps {
  document: MedcardDocument;
  idShared?: string;
  dataTestId?: string;
  removeDocument?: (document: MedcardDocument) => void;
}

export const MedCardDocument = ({ document, idShared, dataTestId, removeDocument }: MedCardDocumentProps) => {
  const [isViewerOpen, setViewerOpen] = useState<boolean>(false);
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);

  const handleOpenViewer = useCallback(() => setViewerOpen(true), []);
  const handleCloseViewer = useCallback(() => setViewerOpen(false), []);
  const handleOpenSettings = useCallback(() => setSettingsOpen(true), []);
  const handleCloseSettings = useCallback(() => setSettingsOpen(false), []);

  const [remove] = useRemoveMedcardDocumentMutation();
  const removeMedCardDocument = useCallback(() => {
    remove({ idDocument: document.idMilaUserDocument, idProfile: document.profileId })
      .unwrap()
      .then(() => {
        removeDocument && removeDocument(document);
      })
      .catch(() => {
        notification.error({ message: 'Не удалось удалить документ' });
      });
  }, [document, remove, removeDocument]);

  const accessLabel = useMemo(
    () =>
      document.isPublic ? (
        <Typography className={`${styles.RoundedLabel} ${styles.GreenBorder}`}>
          <GreenCheckIcon className={styles.Icon} />
          Доступ открыт
        </Typography>
      ) : (
        <Typography className={`${styles.RoundedLabel} ${styles.RedBorder}`}>
          <Cross className={styles.Icon} />
          Доступ закрыт
        </Typography>
      ),
    [document],
  );

  const showButton = useMemo(
    () => (
      <Tooltip
        title="Файл отсутствует"
        overlayClassName={styles.tooltip}
        visible={!document.files.length ? undefined : false}
      >
        <div>
          <CustomButton
            className={cx(styles.DocumentButton, {
              [styles.DocumentButtonDisabled]: !document.files.length,
            })}
            onClick={handleOpenViewer}
            disabled={!document.files.length}
          >
            <Typography>Показать документ</Typography>
          </CustomButton>
        </div>
      </Tooltip>
    ),
    [handleOpenViewer, document.files.length],
  );

  const settingsButton = useMemo(
    () => (
      <CustomButton className={styles.DocumentIconButton} onClick={handleOpenSettings}>
        <DocumentSettings />
      </CustomButton>
    ),
    [handleOpenSettings],
  );

  const removeButton = useMemo(
    () => (
      <CustomButton className={styles.DocumentIconButton} onClick={removeMedCardDocument}>
        <Trash />
      </CustomButton>
    ),
    [removeMedCardDocument],
  );

  const getDiagnosis = (document: any) => {
    let diagnosis;
    if (document.diagnosisMkb10Code && document.diagnosisMkb10Code !== 'undefined') {
      diagnosis = document.diagnosisMkb10Code;
    }

    if (document.diagnosisName?.length > 0 && document.diagnosisName !== 'undefined') {
      diagnosis = diagnosis?.length > 0 ? `${diagnosis} ${document.diagnosisName}` : document.diagnosisName;
    }

    return diagnosis;
  };

  return (
    <WhiteRow dataTestId={dataTestId} className={styles.DocumentWrapper}>
      <Row className={styles.TitleRow}>
        <Row className={styles.Subrow}>
          <Col className={styles.IconCol}>
            <Image src={getIconByDocumentType(document.documentType)} preview={false} />
          </Col>
          <Col>
            <Typography className={styles.SmallText}>
              {dayjs(document.documentDate).format('D MMMM YYYY, ddd')}
            </Typography>
            <Typography className={styles.Title}>{document.documentName}</Typography>
          </Col>
        </Row>

        <Row className={styles.AccessDesktop}>{accessLabel}</Row>
      </Row>
      <Divider className={styles.Divider} />
      <Row className={styles.TitleRow}>
        <Col data-test-id={'med-card-document-container'} className={styles.DocumentContainer}>
          {document.doctorFullName && document.doctorFullName !== 'undefined' && (
            <DoctorLabel
              name={document.doctorFullName}
              specialty={document.doctorSpecialtyName?.slice(document.doctorSpecialtyName.indexOf(')') + 1)}
            />
          )}
          {getDiagnosis(document)?.length > 0 && <DiagnosisLabel name={getDiagnosis(document)} />}
          {document.medOrganizationName && document.medOrganizationName !== 'undefined' && (
            <ClinicLabel name={document.medOrganizationName} />
          )}
          {document && (
            <>
              {document.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ? (
                <Typography data-test-id={'document-uploaded-whom-label'} className={styles.RoundedLabel}>
                  Документ загружен клиникой
                </Typography>
              ) : (
                <Typography data-test-id={'document-uploaded-whom-label'} className={styles.RoundedLabel}>
                  Документ загружен пользователем
                </Typography>
              )}
              <Row className={styles.AccessMobile}>{accessLabel}</Row>
              <Col className={styles.ShowButtonMobile}>
                <Divider className={styles.Divider} />
                <Row className={styles.MobileButtonsContainer}>
                  {settingsButton}
                  {document.uploadedByWhomType === DocumentUploadedByWhomType.Patient && removeDocument && removeButton}
                  {showButton}
                </Row>
              </Col>
            </>
          )}
        </Col>
        <Col className={styles.ShowButtonDesktop}>{showButton}</Col>
        <Col className={styles.ShowButtonDesktop}>{settingsButton}</Col>
        {document.uploadedByWhomType === DocumentUploadedByWhomType.Patient && removeDocument && (
          <Col className={styles.ShowButtonDesktop}>{removeButton}</Col>
        )}
      </Row>

      <CustomModal className="UploadDocWide" isOpen={isSettingsOpen} onCancelClick={handleCloseSettings}>
        <MedcardDocumentLoadForm document={document} onFinish={handleCloseSettings} idShared={idShared} />
      </CustomModal>

      <DocumentViewerModal
        medcardDocument={document}
        isOpen={isViewerOpen}
        onClose={handleCloseViewer}
        idShared={idShared}
      />
    </WhiteRow>
  );
};

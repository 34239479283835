import { Col, Row, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { CustomModal } from '../CustomModal/CustomModal';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { MilaMessage } from '../MilaMessage/MilaMessage';
import { setKeycloakObj } from '../../redux/keycloak/keycloakSlice';
import { useDispatch } from 'react-redux';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';
import KeyCloakService from '../../../keycloak';
import Keycloak from 'keycloak-js';
import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import styles from './styles.module.scss';

export const ProtectedRoute = ({ children }: PropsWithChildren): ReactElement => {
  const dispatch = useDispatch();
  const { refetch, isFetching, data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const [keycloak, setKeycloak] = useState<any>(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showTokenExpieredModal, setShowTokenExpieredModal] = useState(false);

  const authSuccessCallback = () => {
    setIsAuthorized(true);
    refetch();
  };

  const tokenExpieredCallback = () => {
    setShowTokenExpieredModal(true);
    setIsAuthorized(false);
  };

  useEffect(() => {
    if (!isFetching && !keycloak) {
      const keycloakObj = new Keycloak({
        url:
          whiteLabel?.authUrl && whiteLabel?.authUrl?.length > 0
            ? whiteLabel?.authUrl
            : window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL,
        realm: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_REALM as string,
        clientId:
          whiteLabel?.authClientName && whiteLabel?.authClientName?.length > 0
            ? whiteLabel?.authClientName
            : (window.__RUNTIME_CONFIG__.REACT_APP_AUTH_CLIENT_ID as string),
      });
      dispatch(setKeycloakObj(keycloakObj));
      setKeycloak(KeyCloakService(keycloakObj));
    }
  }, [isFetching]);

  useEffect(() => {
    if (keycloak) {
      keycloak.login(authSuccessCallback, tokenExpieredCallback);
    }
  }, [keycloak]);

  return (
    <>
      {!isAuthorized && isFetching && <CustomSpinner />}
      {isAuthorized && !isFetching && (children as ReactElement)}

      {showTokenExpieredModal && (
        <CustomModal isOpen={showTokenExpieredModal} className={styles.ExpieredTokenModal}>
          <Row className={styles.ExpieredTokenModalRow}>
            <Col className={styles.ExpieredTokenModalCol}>
              <MilaMessage text={'Вас долго не было, пожалуйста, авторизуйтесь заново'} title={undefined} />
            </Col>
            <Col className={styles.ExpieredTokenModalCol} style={{ width: '100%' }}>
              <CustomButton
                isPrimal={true}
                onClick={() => keycloak && keycloak.logOut()}
                className={styles.ExpieredTokenModalEnterButton}
              >
                <Typography>Войти</Typography>
              </CustomButton>
            </Col>
          </Row>
        </CustomModal>
      )}
    </>
  );
};

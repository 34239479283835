import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { Col, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { CustomSteps } from 'src/common/components/CustomSteps/CustomSteps';
import { Gender, ServiceRequestType } from 'src/common/types/enums';
import { PatientForm } from './PatientForm/PatientForm';
import { PatientList } from './PatientList/PatientList';
import { Profile } from 'src/common/types';
import { Warn } from '../../../common/icons';
import { YouChose } from './YouChose/YouChose';
import { setAppointmentId, setWaitingListAppointmentId } from 'src/common/redux/appointment/appointmentSlice';
import {
  useAddToWaitingListMutation,
  useCreateAppointmentMutation,
  useGetProfileInfoQuery,
  useGetProfilesQuery,
  useJoinEsiaAccountMutation,
} from 'src/common/redux/api/apiPatientSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EsiaLoginService from '../../../common/services/login/Esia/esia-login-service';
import Link from 'antd/es/typography/Link';
import PaymentsService from 'src/common/services/payments/payments-service';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export type FormRef = { createPatient: () => Profile };

export const PatientChoice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isFormActive, setFormActive] = useState(false);
  const [isPatientCreating, setPatientCreating] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [alreadyCreatedAppointmentId, setNewAppointmentId] = useState<string>();
  const [disableMakeAppoinmentBtn, setDisableMakeAppoinmentBtn] = useState<boolean>(false);
  const ref = useRef<FormRef>();

  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  const service = useAppSelector((state) => state.appointment.service);
  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const doctor = useAppSelector((state) => state.appointment.doctor);
  const slot = useAppSelector((state) => state.appointment.slot);
  const medOrganization = useAppSelector((state) => state.appointment.medOrganization);
  const specialtyId = useAppSelector((state) => state.appointment.specialtyId);
  const startDate = useMemo(() => dayjs(slot?.visitStart), [slot]);
  const endDate = useMemo(() => dayjs(slot?.visitEnd), [slot]);

  const { refetch: refetchProfilesData } = useGetProfilesQuery();
  const { data: selectedProfile, refetch: refetchSelectedProfileData } = useGetProfileInfoQuery(selectedPatientId!, {
    skip: !selectedPatientId,
  });
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const isPatientProfileConfirmed = !isFormActive ? selectedProfile?.isConfirmed : false;
  const isPatientProfileFilled = !isFormActive
    ? selectedProfile?.birthDay &&
      selectedProfile?.birthDay?.length > 0 &&
      selectedProfile?.surname &&
      selectedProfile?.surname?.length > 0 &&
      selectedProfile?.name &&
      selectedProfile?.name?.length > 0 &&
      (selectedProfile?.gender === Gender.Female || selectedProfile?.gender === Gender.Male) &&
      selectedProfile?.phone &&
      selectedProfile?.phone?.length > 0
    : true;
  const idProfile = selectedProfile?.idProfile;

  const [makeAppointment, result] = useCreateAppointmentMutation();

  const [addToWaitingList, { isLoading: isAddToWaitingListLoading }] = useAddToWaitingListMutation();

  const [joinEsiaAccount] = useJoinEsiaAccountMutation();

  const handleAddPatient = () => {
    setFormActive(!isFormActive);
  };

  const handleMakeAppointment = async () => {
    let patientId = selectedPatientId!;
    if (isFormActive && ref.current) {
      try {
        setPatientCreating(true);
        const patient = await ref.current.createPatient();
        patientId = patient.idProfile;
      } catch (err) {
        // Couldn't figure out the type for the error, should be refactored
        //@ts-ignore
        Object.values(err.data.errors).forEach((error: string[]) => {
          notification.error({ message: error[0] });
        });
        notification.error({ message: 'Пользователь не был создан' });
        return;
      } finally {
        setPatientCreating(false);
      }
    }

    if (slot) {
      let newAppointmentId: any =
        alreadyCreatedAppointmentId ||
        (await makeAppointment({
          cost: service?.serviceCostFrom!,
          idAppointment: slot?.idAppointment!,
          doctorSpecialization: doctor?.specialitiesTags?.find((tag) => tag.idFerSpeciality === specialtyId)
            ?.specialityName!,
          idFerSpeciality: specialtyId!,
          idMedOrganization: medOrganization?.idMedOrganization!,
          idSpecialityService: service?.idSpecialtyService!,
          visitStartDate: startDate.format()!,
          visitEndDate: endDate.format()!,
          idMilaDoctor: doctor?.idMilaDoctor!,
          patientId: patientId,
        })
          .unwrap()
          .catch(() => {
            notification.error({ message: `Ошибка. Запись не подтверждена. Пожалуйста, попробуйте позже` });
          }));
      if (!newAppointmentId) {
        return;
      }

      if (newAppointmentId && serviceRequestType === ServiceRequestType.Online && service?.serviceCostFrom !== 0) {
        setNewAppointmentId(newAppointmentId);
        try {
          setDisableMakeAppoinmentBtn(true);
          dispatch(setAppointmentId(newAppointmentId));
          await PaymentsService.payAppointment(newAppointmentId, keycloakObj);
        } catch (e) {
          setDisableMakeAppoinmentBtn(false);
          setSelectedPatientId(patientId);
          setFormActive(false);
          notification.error({ message: 'Оплата не прошла. Попробуйте еще раз.' });
        }
      } else {
        dispatch(setAppointmentId(newAppointmentId));
        navigate(`/records/appointmentResult/${newAppointmentId}?isSuccess=true`);
      }
    } else {
      addToWaitingList({
        patientId: patientId,
        data: {
          idFerSpeciality: specialtyId!,
          idMedOrganization: medOrganization?.idMedOrganization!,
          idMilaDoctor: doctor?.idMilaDoctor!,
          idSpecialityService: service?.idSpecialtyService!,
          cost: service?.serviceCostFrom!,
        },
      })
        .unwrap()
        .then((res) => {
          notification.success({ message: 'Ваша заявка добавлена в лист ожидания' });
          dispatch(setWaitingListAppointmentId(res));
          navigate(`/records/appointmentResult?isSuccess=true`);
        })
        .catch(() => {
          notification.error({ message: 'Ошибка отправки заявки в лист ожидания' });
          navigate(`/records/appointmentResult/?isSuccess=false`);
        });
    }
  };

  const onChange = (id: string) => {
    setSelectedPatientId(id);
  };

  const connectEsiaProfile = () => {
    idProfile &&
      EsiaLoginService.Login().then((accessToken) => {
        joinEsiaAccount({ accessToken, idProfile })
          .unwrap()
          .then(() => {
            notification.success({ message: 'Профиль успешно подключен к Госуслугам' });
            refetchProfilesData();
            refetchSelectedProfileData();
          })
          .catch(() => notification.error({ message: 'Ошибка подключения профиля к Госуслугам' }));
      });
  };

  const onEditProfile = () => {
    navigate(`/settings/userProfile/${idProfile}`);
  };

  const getEmptyFields = () => {
    const emptyFields = [];
    if (!selectedProfile?.surname || selectedProfile?.surname?.length === 0) {
      emptyFields.push('фамилия');
    }
    if (!selectedProfile?.name || selectedProfile?.name?.length === 0) {
      emptyFields.push('имя');
    }
    if (!selectedProfile?.birthDay || selectedProfile?.birthDay?.length === 0) {
      emptyFields.push('дата рождения');
    }
    if (selectedProfile?.gender !== Gender.Female && selectedProfile?.gender !== Gender.Male) {
      emptyFields.push('пол');
    }
    if (!selectedProfile?.phone || selectedProfile?.phone?.length === 0) {
      emptyFields.push(
        'контактный телефон (если вы записываете ребенка или родственника, законным представителем которого являетесь, можете указать свой телефон',
      );
    }
    return emptyFields.join(', ');
  };

  return (
    <>
      <BackHeader title="Завершите запись" />

      <CustomSteps current={3} />

      <Row className={styles.PatientChoiceRow}>
        <Col className={styles.PatientChoiceCol}>
          {result.isLoading ? (
            <CustomSpinner />
          ) : isFormActive ? (
            <PatientForm ref={ref} />
          ) : (
            <PatientList onAddPatient={handleAddPatient} patientId={selectedPatientId} onChange={onChange} />
          )}
        </Col>
        <Col className={styles.PatientChoiceCol}>
          <YouChose />

          {serviceRequestType !== ServiceRequestType.Online || service?.serviceCostFrom === 0 || !slot ? (
            <Row>
              <Typography className={styles.TextAgreement}>
                Нажимая «Записаться» вы даете
                <Link
                  className={styles.TextLink}
                  target="_blank"
                  href={
                    isWhiteLabelEnabled
                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idPersonalDataAgreementFile}?inline=false`
                      : 'https://mila.online/legal'
                  }
                >
                  {' '}
                  согласие{' '}
                </Link>
                на обработку персональных данных {!isWhiteLabelEnabled ? 'ООО «ЭлНетМед»' : ''}
              </Typography>
            </Row>
          ) : (
            <Row>
              <Typography className={styles.TextAgreement}>
                Нажимая «Оплатить» вы даете
                <Link
                  className={styles.TextLink}
                  target="_blank"
                  href={
                    isWhiteLabelEnabled
                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idPersonalDataAgreementFile}?inline=false`
                      : 'https://mila.online/legal'
                  }
                >
                  {' '}
                  согласие{' '}
                </Link>
                на обработку персональных данных{!isWhiteLabelEnabled ? ' ООО «ЭлНетМед»' : ''}, а также принимаете
                условия
                <Link
                  className={styles.TextLink}
                  target="_blank"
                  href={
                    isWhiteLabelEnabled
                      ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idOfferAgreementFile}?inline=false`
                      : 'https://mila.online/legal'
                  }
                >
                  {' '}
                  оферты{' '}
                </Link>
                {!isWhiteLabelEnabled ? ' клиники-партнера ООО «ЭлНетМед»' : ''}
              </Typography>
            </Row>
          )}

          <CustomButton
            isPrimal={true}
            className={styles.SubmitButton}
            onClick={handleMakeAppointment}
            dataTestId={'create-appointment'}
            disabled={
              result.isLoading ||
              isPatientCreating ||
              disableMakeAppoinmentBtn ||
              (!isPatientProfileConfirmed &&
                serviceRequestType === ServiceRequestType.Online &&
                service?.isEsiaRequired) ||
              !isPatientProfileFilled ||
              isAddToWaitingListLoading
            }
          >
            <Typography>
              {serviceRequestType !== ServiceRequestType.Online || service?.serviceCostFrom === 0 || !slot
                ? 'Записаться'
                : `Оплатить ${service?.serviceCostFrom} Р`}
            </Typography>
          </CustomButton>

          {!isPatientProfileFilled && (
            <>
              <Typography className={styles.WarnNote}>
                <Warn />
                Дополните данные профиля
              </Typography>

              <Typography className={styles.WarnText}>
                У выбранного пользователя отсутствуют необходимые для записи сведения: {getEmptyFields()}
              </Typography>

              <CustomButton className={styles.Button} onClick={onEditProfile}>
                <Typography>Дополнить данные профиля</Typography>
              </CustomButton>
            </>
          )}

          {!isPatientProfileConfirmed &&
            service?.isEsiaRequired &&
            serviceRequestType === ServiceRequestType.Online && (
              <>
                <Typography className={styles.WarnNote}>
                  <Warn />
                  Подтвердите учетную запись
                </Typography>

                <Typography className={styles.WarnText}>
                  У выбранного пользователя неподтвержденная учетная запись. Согласно Приказу Минздрава РФ от 30.11.2017
                  N 965Н участники телемедицинской консультации должны быть авторизованы через Госуслуги. Пожалуйста,
                  подключите профиль Госуслуг для выбранного пользователя.
                </Typography>

                {!isFormActive && (
                  <CustomButton className={styles.GosButton} onClick={connectEsiaProfile}>
                    <Typography className="text-xs">Подключить Госуслуги к профилю</Typography>
                  </CustomButton>
                )}
              </>
            )}
        </Col>
      </Row>
    </>
  );
};

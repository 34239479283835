import { Card, List } from 'antd';
import { FC } from 'react';
import { HistoryItem, HistoryItemProps } from '../HistoryItem';
import styles from './styles.module.scss';

type HistoryProps = {
  historyData: HistoryItemProps[];
};

export const History: FC<HistoryProps> = ({ historyData }) => (
  <Card className={styles.historySection}>
    <List
      dataSource={historyData}
      renderItem={(item: HistoryItemProps) => (
        <Card className={styles.historyItemCard} bodyStyle={{ padding: '0 12px' }}>
          <List.Item>
            <HistoryItem {...item} />
          </List.Item>
        </Card>
      )}
    />
  </Card>
);

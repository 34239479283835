import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Dayjs } from 'dayjs';
import { Divider, Row, Typography } from 'antd';
import { Doctor } from 'src/common/types';
import { DoctorSchedule } from '../DoctorSchedule/DoctorSchedule';
import { DoctorSummary } from '../DoctorSummary/DoctorSummary';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

interface DoctorProps {
  doctor: Doctor;
  withSchedule?: boolean;
  startTime?: Dayjs | null;
  endTime?: Dayjs | null;
  selectedDate?: Dayjs;
  preselectedSpecialtyId?: string;
  preselectedBranchId?: string;
}

export const DoctorCard = ({
  doctor,
  withSchedule = false,
  startTime,
  endTime,
  selectedDate,
  preselectedSpecialtyId,
  preselectedBranchId,
}: DoctorProps) => {
  const navigate = useNavigate();

  const handleDoctorClick = () => {
    navigate(`/records/aboutDoctor/${doctor.idMilaDoctor}`);
  };
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  return doctor.hasMilaProfile ? (
    <Row className={`${styles.DoctorContainer} ${!withSchedule && styles.MiniContainer}`}>
      <DoctorSummary doctor={doctor} preselectedBranchId={preselectedBranchId} />
      <Divider className={styles.Divider} />
      {withSchedule ? (
        <DoctorSchedule
          doctor={doctor}
          startTime={startTime}
          endTime={endTime}
          selectedDate={selectedDate}
          preselectedSpecialtyId={preselectedSpecialtyId}
          preselectedBranchId={preselectedBranchId}
        />
      ) : (
        <CustomButton isPrimal className={styles.SignUpButton} onClick={handleDoctorClick}>
          <Typography>Записаться</Typography>
        </CustomButton>
      )}
    </Row>
  ) : (
    <Row className={`${styles.DoctorContainer} ${!withSchedule && styles.MiniContainer}`}>
      <DoctorSummary doctor={doctor} preselectedBranchId={preselectedBranchId} />
      <Typography className={styles.Text}>
        У данного врача еще нет профиля.
        {!whiteLabel?.isServiceEnabled
          ? 'Вы можете пригласить его клинику в Mila, чтобы записываться на прием и консультироваться с врачом онлайн'
          : ''}
      </Typography>
    </Row>
  );
};

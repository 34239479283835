import { Note2 } from 'src/common/icons';
import { Row, Typography } from 'antd';
import styles from './styles.module.scss';

interface DiagnosisLabelProps {
  name?: string;
}

export const DiagnosisLabel = ({ name }: DiagnosisLabelProps) => {
  return (
    <Row data-test-id={'diagnosis-label'} className={styles.Row}>
      <Note2 className={styles.Icon} />
      <Typography>{name}</Typography>
    </Row>
  );
};

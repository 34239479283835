import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Col, Layout, Radio, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Plus } from 'src/common/icons';
import { getProfileNameLabel, sortProfilesByItself } from '../../../../common/utils';
import { useEffect, useMemo } from 'react';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import styles from './styles.module.scss';

interface PatientListProps {
  onAddPatient: () => void;
  patientId?: string;
  onChange?: (id: string) => void;
}

export const PatientList = ({ onAddPatient, patientId, onChange }: PatientListProps) => {
  const { data: profiles, isFetching } = useGetProfilesQuery();

  const sortedProfiles = useMemo(() => sortProfilesByItself(profiles!), [profiles]);

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      const myProfile = profiles.find((profile) => profile.itSelf);
      if (myProfile && onChange && !patientId) {
        onChange(myProfile.idProfile);
      }
    }
  }, [onChange, profiles, patientId]);

  const patientSelectHandler = (id: string) => {
    if (onChange) {
      onChange(id);
    }
  };

  return (
    <Layout className={styles.PatientListContainer}>
      <Typography className={styles.Title}>Заполните данные о пациенте</Typography>
      <Radio.Group
        className={styles.RadioGroup}
        onChange={(e) => patientSelectHandler(e.target.value)}
        value={patientId}
      >
        <Col className={styles.OptionCol}>
          {isFetching ? (
            <CustomSpinner />
          ) : (
            sortedProfiles?.map((profile, key) => (
              <Radio
                data-test-id={`radio-profile-${key}`}
                className={styles.Radio}
                value={profile.idProfile}
                key={profile.idProfile}
              >
                <Row className={styles.OptionRow}>
                  <Avatar
                    idPhoto={profile.idPhoto}
                    idProfile={profile.idProfile}
                    name={`${profile.name} ${profile.surname}`}
                    className={styles.Avatar}
                    classNameText={styles.AvatarText}
                  />
                  <Typography>{getProfileNameLabel(profile)}</Typography>
                </Row>
              </Radio>
            ))
          )}
        </Col>
      </Radio.Group>
      <CustomButton dataTestId={'add-profile'} className={styles.AddButton} onClick={onAddPatient}>
        <Row className={styles.ButtonContent}>
          <Plus />
          <Typography>Добавить пациента</Typography>
        </Row>
      </CustomButton>
    </Layout>
  );
};

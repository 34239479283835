import { Clinic } from 'src/common/types';
import { Placemark, useYMaps } from '@pbe/react-yandex-maps';
import React, { useCallback } from 'react';
import styles from './styles.module.scss';

interface BranchPlacemarkProps {
  latitude: string;
  longitude: string;
  handleClick?: (e: Event, branchId: string) => void;
  branch?: Clinic;
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instanceRef: any;
}

export const BranchPlacemark = ({
  latitude,
  longitude,
  branch,
  handleClick,
  active = false,
  instanceRef,
}: BranchPlacemarkProps) => {
  const ymaps = useYMaps();

  const iconLayout = useCallback(() => {
    if (ymaps) {
      const animatedLayout = ymaps.templateLayoutFactory.createClass<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        build: () => void;
      }>(
        `<svg width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg" class=${
          styles.Placemark
        }>
        <path d="M15.3464 0C7.07649 0 0.351562 6.79646 0.351562 15.1532C0.351562 17.4488 1.30331 19.9695 2.63794 22.5898C3.97257 25.2103 5.71968 27.9113 7.46406 30.3623C10.9528 35.2648 14.4331 39.1862 14.4331 39.1862V39.1865C14.6654 39.4495 14.9977 39.6 15.3468 39.6C15.6956 39.6 16.0279 39.4495 16.2603 39.1865C16.2603 39.1865 19.7405 35.2651 23.2293 30.3626C24.9736 27.9115 26.7303 25.2104 28.0652 22.5901C29.3998 19.9696 30.3516 17.449 30.3516 15.1535C30.3516 6.79624 23.6166 0.000276194 15.3469 0.000276194L15.3464 0Z" fill="${
          active ? '#7F49DE' : '#E79E44'
        }"/>
        <circle cx="15.8873" cy="14.464" r="4.82143" fill="white"/>
        </svg>`,
        {
          build() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (animatedLayout as any).superclass.build.call(this);
            const shape = {
              type: 'Rectangle',
              coordinates: [
                [-31, -40],
                [12, 3],
              ],
            };
            this.getData().options.set('shape', shape);
          },
        },
      );
      return animatedLayout;
    }
    return null;
  }, [active, ymaps]);

  const balloonLayout = useCallback(() => {
    if (ymaps && branch && handleClick) {
      const animatedLayout = ymaps.templateLayoutFactory.createClass<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
        build: () => void;
      }>(
        `<div class=${styles.BalloonContainer}>
          <div class=${styles.TopRow}>
            <a href="${window.__RUNTIME_CONFIG__.REACT_APP_HOST}/catalog/${branch.idMedOrganization}" class=${
          styles.BranchName
        }>${branch.shortName}</a>           
          </div>
          <span class=${styles.Text1}>${branch.address}${nearestSubwayText(branch.nearestSubwayDescription)}</span>
          <span class=${styles.PhoneText}>${branch.phones.join(', ')}</span>
       
     
          <div class=${styles.ContactRow}>          
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6663 8.00016C14.6663 11.6802 11.6797 14.6668 7.99967 14.6668C4.31967 14.6668 1.33301 11.6802 1.33301 8.00016C1.33301 4.32016 4.31967 1.3335 7.99967 1.3335C11.6797 1.3335 14.6663 4.32016 14.6663 8.00016Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.4729 10.1197L8.40626 8.88635C8.04626 8.67301 7.75293 8.15968 7.75293 7.73968V5.00635" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

          <span class=${styles.WorkingHours}>${branch.workingHoursDescription}</span>
        </div>

          ${!active ? `<button id="clinic-info" class=${styles.Button}>Подробнее</button>` : ''}
        </div>`,
        {
          build() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (animatedLayout as any).superclass.build.call(this);
            var temp = document.getElementById('clinic-info');
            temp?.addEventListener('click', (e: Event) => {
              handleClick(e, branch.idMedOrganization);
            });
          },
        },
      );
      return animatedLayout;
    }
    return null;
  }, [active, branch, handleClick, ymaps]);

  const nearestSubwayText = (nearestSubwayDescription: string) => {
    return nearestSubwayDescription
      ? nearestSubwayDescription.includes('()') === false
        ? `, ${nearestSubwayDescription}`
        : ''
      : '';
  };

  return (
    <Placemark
      geometry={[latitude, longitude]}
      options={{
        iconLayout: iconLayout()!,
        balloonContentLayout: balloonLayout()!,
        balloonPanelMaxMapArea: 0,
        hideIconOnBalloonOpen: true,
      }}
      instanceRef={instanceRef}
    />
  );
};

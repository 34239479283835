import { Typography } from 'antd';
import styles from './styles.module.scss';

const { Title, Paragraph, Text } = Typography;

export const MedicalDataAgreement = (
  <div className={styles.agreementWrapper}>
    <Title level={4} className={styles.agreementTitle}>
      СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ПРОСМОТР МЕДИЦИНСКИХ СВЕДЕНИЙ
    </Title>

    <Paragraph className={styles.paragraph}>
      В соответствии со статьями 23, 24 Конституции Российской Федерации, статьей 22 Федерального закона от 21. 11.2011
      N 323-ФЗ "Об основах охраны здоровья граждан в Российской Федерации", Федеральным законом от 27.07.2006 № 152-ФЗ
      &laquo;О персональных данных&raquo;, Постановлением Правительства РФ от 12 апреля 2018 г. N 447 об утверждении
      &laquo;Правил взаимодействия иных информационных систем, предназначенных для сбора, хранения, обработки и
      предоставления информации, касающейся деятельности медицинских организаций и предоставляемых ими услуг, с
      информационными системами в сфере здравоохранения и медицинскими организациями&raquo;, Политикой
      конфиденциальности, размещенной на сайте Mila (Мила) по адресу www.mila.online,
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      Я, субъект персональных данных, дееспособное физическое лицо, достигшее 18-летнего возраста, успешно прошедшее
      процедуру регистрации на сайте Mila (Мила) путем заполнения формы регистрации или авторизации посредством Госуслуг
      (через Единую Систему Идентификации и Аутентификации, ЕСИА), или авторизации посредством Сбер ID (сервис
      авторизации от ПАО &laquo;Сбербанк&raquo;) в целях использования сайта Mila (Мила) и его сервисов, в том числе
      виджета записи на прием в медицинскую организацию, отображаемого на сайте медицинской организации (далее - Сайт,
      Сервисы), именуемый далее <Text strong>Пользователь</Text>, действуя своей волей и в своем интересе,
      <Text strong> даю согласие на просмотр мной </Text>
      <Text strong>
        имеющейся в медицинских организациях информации о состоянии моего здоровья, а также здоровья лиц, законным
        представителям которых я являюсь
      </Text>
      , в том числе сведений о результатах медицинского обследования, наличии заболеваний, об установленных диагнозах и
      о прогнозе развития заболевания, методах оказания медицинской помощи, связанном с ними риске, возможных видах
      медицинского вмешательства, его последствиях и результатах оказания медицинской помощи в виде медицинских
      документов (их копий), а также выписок из них в форме электронных документов, файлов и статистических данных
      (далее &ndash; Медицинские документы).
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>1. Согласие выдано Обществу с ограниченной ответственностью &laquo;ЭлНетМед&raquo; </Text>
      (ООО&nbsp;&laquo;ЭлНетМед&raquo;, ИНН 7842529670, ОГРН 114 784 736 4323) (далее &ndash; Оператор). Я уведомлен,
      что ООО &laquo;ЭлНетМед&raquo; является официальным оператором программного комплекса &laquo;N3.Health&raquo; -
      &laquo;иной информационной системы&raquo;, уполномоченным Министерством здравоохранения Российской Федерации для
      размещения сведений об оказанной медицинской помощи в ЕГИСЗ Минздрава РФ в соответствии с действующим
      законодательством (идентификатор в Реестре Минздрава РФ &ndash; 3 , включения в Реестр - 11.10.2019). Я уведомлен,
      что ООО &laquo;ЭлНетМед&raquo; также является оператором персональных данных, включенным в Реестр операторов
      Роскомнадзора (регистрационный номер 78-21-008437). Я уведомлен, что программное обеспечение N3.Health ООО
      &laquo;ЭлНетМед&raquo; включено в Реестр российского ПО Министерства цифрового развития, связи и массовых
      коммуникаций Российской Федерации (запись в Реестре № 9016 от 05.02.2021).
      <Text strong> </Text>Я уведомлен, что Оператор ПДн имеет право во исполнение своих обязательств согласно
      регламенту электронного взаимодействия медицинских организаций на обмен (прием и передачу) персональными данными с
      медицинскими организациями, имеющими лицензии на осуществление медицинской деятельности в Российской Федерации
      (далее &ndash; Медицинские Организации), с иными прописанными в регламенте участниками электронного взаимодействия
      с использованием машинных носителей информации или по каналам связи, с соблюдением мер, обеспечивающих их защиту
      от несанкционированного доступа, при условии, что их прием и обработка будут осуществляться лицом, обязанным
      сохранять врачебную тайну.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        Мои персональные данные, а также данные моих детей, полученные сервисом Mila (Мила) из ЕСИА или Сбер ID с целью
        идентификации меня как пациента, мною проверены и являются верными.
      </Text>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      Я уведомлен, что в соответствии с требованиям п. 5. Правил взаимодействия иных информационных систем,
      предназначенных для сбора, хранения, обработки и предоставления информации, касающейся деятельности медицинских
      организаций и предоставляемых ими услуг, с информационными системами в сфере здравоохранения и медицинскими
      организациями (далее &ndash; Правила взаимодействия иных информационных систем), утвержденных Постановлением
      Правительства РФ от 12 апреля 2018 г. N 447,&nbsp;
      <Text strong>
        доступ физических лиц к данным Иной информационной системы &laquo;N3.Health&raquo; обеспечивается с
        использованием подтвержденной учетной записи физического лица в &laquo;Единой системе идентификации и
        аутентификации&raquo; (ЕСИА)&nbsp;
      </Text>
      - посредством нажатия на кнопку &laquo;Войти через Госуслуги&raquo; на Сайте и его Сервисах) и получением из нее
      следующих сведений о физическом лице:
      <ul>
        <li>а) фамилия, имя и отчество (при наличии);</li>
        <li>б) пол;</li>
        <li>в) дата рождения;</li>
        <li>
          г) страховой номер индивидуального лицевого счета застрахованного лица в системе персонифицированного учета
          Пенсионного фонда Российской Федерации;
        </li>
        <li>д) реквизиты документа, удостоверяющего личность;</li>
        <li>е) адрес электронной почты (при наличии);</li>
        <li>ж) номер мобильного телефона (при наличии).</li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>3. Настоящее согласие выдано в целях </Text>
      <Text strong>исполнения Оператором следующих функций</Text> в соответствии с п. 4. Правил взаимодействия иных
      информационных систем, в том числе:
      <ul>
        <li>
          а) взаимодействия с подсистемами ЕГИСЗ с целью организации предоставления и получения сведений, обрабатываемых
          в ЕГИСЗ, для медицинских организаций и граждан;
        </li>
        <li>
          б) предоставления своим пользователям информации о медицинских организациях, медицинских работниках и
          возможности получения медицинских услуг, предоставляемых медицинскими организациями;
        </li>
        <li>в) предоставления сервиса записи на прием к врачу и вызова врача на дом;</li>
        <li>
          г) оказания медицинской помощи с применением телемедицинских технологий, в том числе обеспечение хранения
          материалов, полученных по результатам дистанционного взаимодействия медицинских работников между собой,
          медицинских работников и пациентов (или их законных представителей), включая материалы, направленные для
          проведения консультации (консилиума врачей), медицинские заключения по результатам консультаций и протоколы
          консилиумов врачей, данные, внесенные в медицинскую документацию пациента, данные, формирующиеся в результате
          дистанционного наблюдения за состоянием здоровья пациента иной информационной системы в соответствии с
          порядком организации и оказания медицинской помощи с применением телемедицинских технологий;
        </li>
        <li>д) информирования граждан об оказанной им медицинской помощи;</li>
        <li>
          е) предоставления сервисов доступа медицинских работников к информации по вопросам осуществления медицинской
          деятельности, в том числе к нормативным правовым актам и справочной информации в сфере охраны здоровья;
        </li>

        <li>
          ж) получения, обработки и предоставления информации о взаимодействии пользователей с медицинскими
          организациями и медицинскими работниками, а также ведения рейтингов медицинских организаций и медицинских
          работников;
        </li>
        <li>з) предоставления информации о медицинских организациях и медицинских услугах;</li>
        <li>
          и) предоставления сервисов, позволяющих гражданам получать агрегированную информацию о состоянии здоровья, а
          также рекомендации по ведению здорового образа жизни;
        </li>
        <li>
          к) организации и осуществления информационного обмена в сфере здравоохранения, в том числе по вопросам
          заключения договора об оказании медицинских услуг, получения информированного добровольного согласия на
          медицинское вмешательство или отказа от медицинского вмешательства, оформления первичной учетной документации
          и осуществления расчетов за оказанные медицинские услуги;
        </li>
        <li>
          л) предоставления аналитической информации об оказываемой медицинскими организациями медицинской помощи,
          включая медицинские услуги;
        </li>
        <li>
          м) приема жалоб, заявлений и предложений от граждан и медицинских работников по вопросам оказания медицинской
          помощи;
        </li>
        <li>н) предоставления сервисов и услуг, сопутствующих оказанию медицинской помощи.</li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>4. Настоящим Пользователь подтверждает, что:</Text>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        4.1. Ознакомлен и согласен с тем, что передача Медицинских документов, а также вероятно содержащихся в них
        Персональных данных Пользователя, а также лиц, законным представителем которых является Пользователь, может
        осуществляться Оператором&nbsp;
      </Text>
      следующим третьим лицам:
      <ul>
        <li>
          - в объеме, самостоятельно определяемым Пользователем: медицинским организациям, при заполнении и отправке
          Формы &laquo;Информация для врача&raquo; в карточке записи на прием в такую медицинскую организацию на Сайте;
        </li>
        <li>
          - в объеме, самостоятельно определяемым Пользователем: при формировании и передаче Пользователем по своему
          усмотрению ссылок на просмотр медицинских карт Пользователя и лиц, законным представителем которых является
          Пользователь, отображаемых для просмотра в отдельном окне веб-браузера: широкому кругу лиц. При формировании и
          передаче такой ссылки Пользователь подтверждает, осведомлен и согласен, с тем, что Оператор ПДн не несет
          ответственности за раскрытие персональных данных неопределенному кругу лиц самим субъектом персональных данных
          или его законным представителем, доказательства законности последующего распространения или иной обработки
          таких персональных данных лежит на Пользователе и лицах, законным представителем которых является Пользователь
          либо лице, получившим доступ к персональным данным Пользователя.
        </li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>4.2. </Text>Уведомлен и согласен с тем, что&nbsp;
      <Text strong>Оператор не несет ответственности за содержание </Text>
      <Text strong>Медицинских документов </Text>(Пользователя и лиц, законных представителем которых является
      Пользователь), а также полноту выгрузки сведений, передаваемых медицинскими организациями в рамках информационного
      взаимодействия. Я информирован, что&nbsp;
      <Text strong>
        в случае неполного или некорректного отображения на Сайте и/или в его Сервисах Медицинских документов
      </Text>
      &nbsp;(Пользователя и лиц, законных представителем которых является Пользователь), я могу обратиться за
      разъяснениями в медицинскую организацию, где были мной получены соответствующие медицинские услуги. В случае, если
      медицинская организация имеет договорные отношения с Оператором и зарегистрирована на Сайте, я могу направить
      запрос в нее через сервис &laquo;Помощь&raquo;, либо через сервис &laquo;Чат с клиникой&raquo; на Сайте.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>4.3. </Text>
      <Text strong>
        Уведомлен, что срок хранения ПДн, обрабатываемых Оператором ПДн при оказании медицинских услуг,
      </Text>
      &nbsp;соответствует сроку хранения первичных медицинских документов и составляет 25 лет.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>5. </Text>Согласие дается мной путем включения соответствующей &laquo;галочки&raquo; в разделе
      &laquo;Согласия на доступ к данным&raquo; Сайта, либо нажатия кнопок &laquo;Подписать согласие&raquo; или
      &laquo;Подписать согласие и добавить медкарты для детей&raquo; в форме &laquo;Настройка профиля&raquo;, либо
      нажатия на кнопку &laquo;Подключить госуслуги&raquo;. Настоящим подтверждаю, что иных доказательств для
      подтверждения моего свободного волеизъявления не потребуется.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>6. </Text>Я согласен с тем, что текст данного мной согласия хранится в электронном виде в базе данных
      Сайта и подтверждает факт согласия на обработку персональных данных в соответствии с вышеизложенными положениями и
      беру на себя ответственность за достоверность предоставления персональных данных. Настоящим я гарантирую, что
      указанные мною на Сайте или его Сервисах персональные данные принадлежат мне или лицам, законным представителям
      которых я являюсь. Под персональными данными понимается любая информация, сообщённая о себе Пользователем Сайта,
      на основании которой возможна его идентификация как субъекта персональных данных, либо сообщённая Пользователем
      Сайта о лице, законным представителем которого Пользователь является. При использовании персональных данных
      Оператор руководствуется статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006г. №
      152-ФЗ &laquo;О персональных данных&raquo;, Федеральным законом от 13.03.2006г. № 38-ФЗ &laquo;О рекламе&raquo;,
      Политикой конфиденциальности и Правилами обработки персональных данных, размещенными на Сайте.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>7. </Text>Я подтверждаю, что владею информацией о том, что в любой момент в течение всего срока
      действия настоящего согласия
      <Text strong> я вправе отозвать согласие</Text> путем выключения соответствующей &laquo;галочки&raquo; в разделе
      &laquo;Согласия на доступ к данным&raquo; в настройках профиля на Сайте, отдельно для себя и для каждого лица,
      законным представителем которого я являюсь. Я информирован, что при возникновении вопросов относительно отказа от
      согласия, я могу обратиться за помощью, отправив запрос в службу технической поддержки Оператора через сервис
      &laquo;Помощь&raquo; на Сайте. Я подтверждаю и согласен с тем, что в случае отмены согласия на просмотр
      медицинских сведений по соответствующему профилю мне не будет доступен просмотр Медицинских документов (моих или
      лица, законным представителем которого я являюсь) в личном кабинете на Сайте и его Сервисах.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>8. </Text>Настоящее <Text strong>согласие предоставляется на неограниченный срок</Text> при
      отсутствии сведений о его отзыве.
    </Paragraph>
  </div>
);

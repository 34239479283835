import React from 'react';

import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { GreenCheckIcon, Location, Profile } from 'src/common/icons';
import { SignedDocumentStatus } from '../../../common/types';
import Doc from 'src/assets/Doc.png';
import styles from './styles.module.scss';

interface DocBlockProps {
  dateApproval: string;
  dateCreate: string;
  name: string;
  type: string;
  doctor?: {
    name: string;
    profession: string;
  };
  clinic: string;
  setDocNumber: () => void;
  status: SignedDocumentStatus | null;
}

export const DocBlock = ({
  dateApproval,
  setDocNumber,
  dateCreate,
  name,
  type,
  doctor,
  clinic,
  status,
}: DocBlockProps) => {
  const getStatusText = (status: any) => {
    if (status === SignedDocumentStatus.Signed) {
      return 'Подписано';
    }
    if (status === SignedDocumentStatus.WaitDoctorSign) {
      return 'Ожидается подпись врача';
    }
    if (status === SignedDocumentStatus.Refused) {
      return 'Подписан отказ';
    }
    return '-';
  };

  return (
    <Row className={styles.Block}>
      <Row className={styles.Top}>
        <Row className={styles.TopData}>
          <Image className={styles.TopPicture} src={Doc} preview={false} />
          <Col className={styles.TopInfo}>
            <Typography.Text className={styles.DateCreate}>
              Дата и время поступления документа: {dateCreate}
            </Typography.Text>
            <Typography.Text className={styles.Name}>{name}</Typography.Text>
          </Col>
        </Row>
        {status === null && dateApproval !== '-' && (
          <Row className={styles.DateApproval}>Рассмотреть до {dateApproval}</Row>
        )}
        {status !== null && (
          <Row
            className={`${styles.StatusBlock} ${
              status === SignedDocumentStatus.WaitDoctorSign || status === SignedDocumentStatus.Signed
                ? styles.StatusBlockActive
                : status === SignedDocumentStatus.Refused
                ? styles.StatusBlockUnActive
                : ''
            }`}
          >
            {status === SignedDocumentStatus.Signed && <GreenCheckIcon className={styles.StatusBlockIcon} />}
            {getStatusText(status)}
          </Row>
        )}
      </Row>
      <Row className={styles.Bottom}>
        <Col>
          {doctor && (
            <Row>
              <Profile />
              <Col className={styles.Doctor}>
                <Typography.Text className={styles.DoctorName}>{doctor.name}</Typography.Text>
                <Typography.Text className={styles.DoctorProfession}>{doctor.profession}</Typography.Text>
              </Col>
            </Row>
          )}
          <Row className={styles.ClinicBlock}>
            <Location />
            <Col>
              <Typography.Text className={styles.Clinic}>{clinic}</Typography.Text>
            </Col>
          </Row>
          {type?.length > 0 && (
            <Row>
              <Typography.Text className={styles.Type}>{type}</Typography.Text>
            </Row>
          )}
        </Col>
        <Col className={styles.ButtonBlock}>
          <CustomButton onClick={setDocNumber} className={styles.Button}>
            <Typography>Посмотреть</Typography>
          </CustomButton>
        </Col>
      </Row>
    </Row>
  );
};

import { FC } from 'react';
import { Spin, SpinProps } from 'antd';
import cx from 'classnames';
import styles from './styles.module.scss';

type CustomSpinnerProps = {
  className?: string;
  componenentProps?: SpinProps;
};

export const CustomSpinner: FC<CustomSpinnerProps> = ({ className, ...componenentProps }: CustomSpinnerProps) => (
  <Spin className={cx(styles.Spinner, className)} {...componenentProps} />
);

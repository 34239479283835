import { Card, Collapse, Typography } from 'antd';
import styles from './styles.module.scss';

const { Panel } = Collapse;
const { Text } = Typography;

export const BonusRules = () => (
  <Card bodyStyle={{ padding: 0 }}>
    <Collapse expandIconPosition="end" ghost>
      <Panel header={<div className={styles.header}>Как работает бонусная программа</div>} key="1">
        <div className={styles.content}>
          <Text className={styles.ruleItem}>Начисление 5% от суммы оплаты</Text>
          <Text className={styles.ruleItem}>Списание до 15% стоимости</Text>
          <Text className={styles.ruleItem}>1 бонус = 1 рубль</Text>
          <Text className={styles.ruleItem}>Бонусы за покупки сгорают через 12 мес.</Text>
        </div>
      </Panel>
    </Collapse>
  </Card>
);

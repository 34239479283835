import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomTabs } from 'src/common/components/CustomTabs/CustomTabs';
import { DoctorEducation } from './DoctorEducation/DoctorEducation';
import { DoctorReviews } from './DoctorReviews/DoctorReviews';
import { DoctorSchedule } from '../DoctorChoice/DoctorSchedule/DoctorSchedule';
import { DoctorSummary } from '../DoctorChoice/DoctorSummary/DoctorSummary';
import { ItemsTabs } from 'src/common/types';
import { PromoBlockDoctor } from './PromoBlockDoctor/PromoBlockDoctor';
import { useEffect, useMemo } from 'react';
import {
  useGetAboutDoctorInfoQuery,
  useGetDoctorQuery,
  useGetIsOnboardingRequiredQuery,
} from 'src/common/redux/api/apiPatientSlice';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DoctorStub from 'src/assets/DoctorStub.png';
import styles from './styles.module.scss';

const items: ItemsTabs[] = [
  {
    label: 'О враче',
    key: 'about',
  },
  {
    label: 'Отзывы',
    key: 'reviews',
  },
  {
    label: 'Акции',
    key: 'promo',
  },
  {
    label: 'Запись',
    key: 'schedule',
  },
];

export const AboutDoctor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { doctorId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab: string = useMemo(
    () => (searchParams.get('activeTab') ? searchParams.get('activeTab')! : 'about'),
    [searchParams],
  );

  const branchId = useMemo(() => (searchParams.get('branchId') ? searchParams.get('branchId')! : ''), [searchParams]);

  const { data: doctor } = useGetDoctorQuery(
    { doctorId: doctorId!, branchId: branchId! },
    {
      skip: !doctorId,
    },
  );

  const { data: aboutDoctor } = useGetAboutDoctorInfoQuery(doctorId!, {
    skip: !doctorId,
  });

  const { data: onboardingStatus } = useGetIsOnboardingRequiredQuery();

  const handleTabChange = (key: string) => {
    setSearchParams({ activeTab: key, branchId: branchId });
  };

  const handleSignUp = () => {
    setSearchParams({ activeTab: 'schedule', branchId: branchId });
  };

  const handleSignUpMobile = () => {
    handleSignUp();
    setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 500);
  };

  useEffect(() => {
    if (onboardingStatus?.isOnboardingRequired) {
      navigate('/onboarding', {
        state: {
          prevLocationPath: location.pathname,
          prevLocationParams: location.search,
        },
      });
    }
  }, [onboardingStatus, location]);

  return (
    <>
      <BackHeader title="О враче" />
      <Row className={styles.DoctorRow}>
        <Image
          src={doctor?.photoId ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${doctor.photoId}` : DoctorStub}
          className={styles.DoctorPhoto}
          rootClassName={styles.DoctorPhotoContainer}
          preview={false}
        />
        <Col className={styles.DoctorSummaryBlock}>
          {doctor && <DoctorSummary doctor={doctor} hideAvatar />}
          {activeTab !== 'schedule' && (
            <CustomButton className={styles.DesktopSignUpButton} isPrimal={true} onClick={handleSignUp}>
              <Typography>Записаться</Typography>
            </CustomButton>
          )}
        </Col>
      </Row>
      <Row className={styles.InfoRow}>
        <CustomTabs
          items={items}
          activeKey={activeTab}
          onChange={handleTabChange}
          containerClassName={styles.TabsContainer}
        />
        <Row className={styles.ScrollWrapper}>
          {activeTab === 'about' && aboutDoctor && <DoctorEducation {...aboutDoctor} />}
          {activeTab === 'schedule' && doctor && (
            <DoctorSchedule doctor={doctor} className={styles.Schedule} preselectedBranchId={branchId} />
          )}
          {activeTab === 'promo' && doctor && <PromoBlockDoctor doctorId={doctor.idMilaDoctor} />}
          {activeTab === 'reviews' && doctor && <DoctorReviews doctorId={doctor.idMilaDoctor} />}
        </Row>
      </Row>
      <Row className={styles.MobileSignUpButtonRow}>
        <CustomButton isPrimal={true} className={styles.MobileSignUpButton} onClick={handleSignUpMobile}>
          <Typography>Записаться</Typography>
        </CustomButton>
      </Row>
    </>
  );
};

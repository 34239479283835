import { Card, Col } from 'antd';
import { FC } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

type PointsCardProps = {
  title: string;
  value: string | number;
  icon: string;
  isDisabled?: boolean;
  subTitle?: string;
};

export const PointsCard: FC<PointsCardProps> = ({ title, value, icon, isDisabled = false, subTitle }) => (
  <Col xs={24} md={8}>
    <Card
      className={styles.card}
      bodyStyle={{
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        padding: '12px 16px',
        minHeight: '70px',
        justifyContent: 'space-between',
        filter: isDisabled ? 'grayscale(1)' : 'none',
      }}
    >
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.title}>{subTitle}</div>}
      </div>
      <div className={styles.valueContainer}>
        <img src={icon} alt="" className={styles.icon} />
        <div className={cx(styles.value, { [styles.disabled]: isDisabled })}>{value}</div>
      </div>
    </Card>
  </Col>
);

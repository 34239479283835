import {
  AddWaitingListArgs,
  AdditionalEmailArgs,
  AppointmentClinic,
  AppointmentClinicsArgs,
  AppointmentDocumentsArgs,
  AttachPhotoArgs,
  CalendarRecord,
  CalendarRecordsArgs,
  CancelAppointmentArgs,
  ChildrenConsent,
  Clinic,
  ClinicAboutInfo,
  ClinicArgs,
  ClinicCommonInfo,
  ClinicsSearchArgs,
  Consent,
  CreateAppointmentArgs,
  CreateProfileArgs,
  DataResponse,
  DetachPhotoArgs,
  Doctor,
  DoctorArgs,
  DoctorClinic,
  DoctorClinicsArgs,
  DoctorInfo,
  DoctorsArgs,
  EsiaRequestArgs,
  FolloWUpDocument,
  MedOrganizationItem,
  MedcardDocument,
  MedcardDocumentsArgs,
  MedcardLink,
  MedcardNotification,
  MedcardRecommedation,
  MedcardUploadDocumentsArgs,
  MedcardUploadSeveralDocumentsArgs,
  MessageToSupport,
  NotificationsType,
  PaginationArgs,
  PatientSessionModel,
  Payment,
  PaymentsArgs,
  Profile,
  ProfileRemoveArgs,
  ProfileRemoveReasons,
  PromoArgs,
  PromoInfo,
  RescheduleAppointmentArgs,
  Reviews,
  Schedule,
  ScheduleArgs,
  SearchArgs,
  SearchData,
  SetAppointment,
  ShareMedcardArgs,
  SpecialtiesArgs,
  Specialty,
  TmkChat,
  TmkUserInfo,
  UploadProfileAvatar,
  WaitingListInfo, SignDocuments, MedcardUpdateExternalDocumentsArgs,
} from '../../types';
import { UploadFile } from 'antd/es/upload';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareBodyWithFile, prepareSearchParams } from 'src/common/utils';
import { v4 as uuidv4 } from 'uuid';
import KeyCloakService from '../../../keycloak';

const getSlotsLocker = (): string => {
  var slotsLocker = window.sessionStorage.getItem('X-Mila-UserSession');

  if (!slotsLocker) {
    slotsLocker = uuidv4();
    window.sessionStorage.setItem('X-Mila-UserSession', slotsLocker);
  }
  return slotsLocker as string;
};

export const apiPatientSlice = createApi({
  reducerPath: 'milaPatientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API}/patientaccount`,
    prepareHeaders: async (headers, api) => {
      const keycloakObj = (api.getState() as any)?.keycloak?.keycloakObj;
      headers.set('X-Mila-UserSession', getSlotsLocker());
      if (keycloakObj) {
        headers.set('Authorization', 'Bearer ' + (KeyCloakService(keycloakObj).getAuthToken() || ''));
      }
      headers.set('X-Mila-Origin', window.location.origin);
      return headers;
    },
  }),
  tagTypes: [
    'Appointments',
    'Profiles',
    'Notifications',
    'SharedLinks',
    'MedcardDocuments',
    'Consents',
    'OnboardingStatus',
  ],
  endpoints: (builder) => ({
    //queries
    getCalendarRecords: builder.query<DataResponse<CalendarRecord>, CalendarRecordsArgs>({
      query: ({ type, ...params }) => ({ url: `calendar/${type}`, params: prepareSearchParams(params) }),
      providesTags: ['Appointments'],
    }),

    getOrderMedicinesToken: builder.query<string, void>({
      query: () => `/ordermedicines/token`,
    }),

    getAppointment: builder.query<CalendarRecord, { appointmentId?: string }>({
      query: ({ appointmentId }) => ({ url: `appointments/${appointmentId}` }),
      providesTags: ['Appointments'],
    }),
    getAppointmentSessionId: builder.mutation<PatientSessionModel, { appointmentId: string }>({
      query: ({ appointmentId }) => ({
        url: `appointments/${appointmentId}/sessionId`,
        method: 'GET',
        cache: 'no-cache',
      }),
    }),

    getSpecialties: builder.query<Specialty[], SpecialtiesArgs>({
      query: (arg) => ({
        url: `/specialties`,
        params: {
          ServiceRequestType: arg.serviceType,
          branchId: arg.practiceId,
          region: arg?.region,
          ServeAge: arg.serveAge,
        },
      }),
    }),

    getPharmDocuments: builder.query<{data: any, total: number}, {status: any, offset: any, count: any}>({
      query: (arg) => ({
        url: `/pharm/documents`,
        params: {
          status: arg.status,
          offset: arg.offset,
          count: arg.count
        }
      }),
    }),

    signDocuments: builder.mutation<string, SignDocuments>({
      query: (data) => {
        return {
          url: `/pharm/documents/sign`,
          body: data,
          method: 'POST',
        };
      },
    }),

    analysesByQuery: builder.query<
      { analysisUserDocumentId: string; analysisName: string }[],
      { appointmentId: string; analysisNameQuery: string }
    >({
      query: ({ appointmentId, analysisNameQuery }) => ({
        url: `appointments/${appointmentId}/analyses/search`,
        params: { analysisNameQuery },
      }),
    }),

    getAttachedAnalysisDocs: builder.query<
      MedcardDocument,
      { appointmentId: string; attachedAnalysisDocumentId: string }
    >({
      query: ({ appointmentId, attachedAnalysisDocumentId }) => ({
        url: `appointments/${appointmentId}/analyses/${attachedAnalysisDocumentId}`,
        cache: 'no-cache',
      }),
    }),

    getDoctor: builder.query<Doctor, DoctorArgs>({
      query: ({ doctorId, branchId }) => ({
        url: `doctors/${doctorId}`,
        params: { fromBranchId: branchId },
      }),
    }),

    getAboutDoctorInfo: builder.query<DoctorInfo, string>({
      query: (docId) => `doctors/${docId}/about`,
    }),

    getDoctorReviews: builder.query<Reviews, string>({
      query: (docId) => `/doctors/${docId}/reviews`,
    }),

    getDoctorClinicsServices: builder.query<DoctorClinic[], DoctorClinicsArgs>({
      query: ({ doctorId, region }) => ({
        url: `/doctors/${doctorId}/clinics`,
        params: { region },
      }),
    }),

    getDoctorSchedule: builder.query<Schedule[], ScheduleArgs>({
      query: ({ idDoctor, branchId, from, to, idFerSpecialization, region }) => ({
        url: `/doctors/${idDoctor}/schedule`,
        params: {
          branchId,
          from,
          to,
          idFerSpecialization,
          region,
        },
      }),
    }),

    getDoctors: builder.query<Doctor[], DoctorsArgs>({
      query: (params) => ({
        url: `/doctors`,
        params: prepareSearchParams(params),
      }),
    }),

    getClinicInfo: builder.query<ClinicCommonInfo, string>({
      query: (medOrganizationId) => ({
        url: `/clinics/${medOrganizationId}`,
      }),
    }),

    getAboutClinicInfo: builder.query<ClinicAboutInfo, string>({
      query: (medOrganizationId) => ({
        url: `/clinics/${medOrganizationId}/about`,
      }),
    }),

    getClinicsRecords: builder.query<DataResponse<CalendarRecord>, CalendarRecordsArgs>({
      query: ({ type, ...params }) => ({
        url: `clinics/${params.medOrganizationId}/appointments/${type}`,
        params: prepareSearchParams(params),
      }),
    }),

    getClinics: builder.query<ClinicCommonInfo[], ClinicArgs>({
      query: (params) => ({ url: `clinics/`, params: prepareSearchParams(params) }),
    }),

    getClinicReviews: builder.query<Reviews, { medOrganizationId: string; patientId: string }>({
      query: ({ medOrganizationId }) => ({
        url: `clinics/${medOrganizationId}/reviews`,
      }),
    }),

    getPromotionInfo: builder.query<PromoInfo, string>({
      query: (promotionId) => `/promotions/${promotionId}`,
    }),

    getPromotions: builder.query<PromoInfo[], PromoArgs>({
      query: (params) => ({
        url: `/promotions`,
        params: params,
      }),
    }),

    getWaitingListInfo: builder.query<WaitingListInfo[], string>({
      query: (patientId) => ({ url: `/appointments/waitinglist`, params: { patientId } }),
    }),

    getProfiles: builder.query<Profile[], void>({
      query: () => `/profiles`,
      providesTags: ['Profiles'],
    }),

    getProfileRemoveReasons: builder.query<ProfileRemoveReasons[], void>({
      query: () => `/profiles/delete-reasons`,
    }),

    getRegions: builder.query<string[], void>({
      query: () => `/regions`,
    }),

    getProfileInfo: builder.query<Profile, string>({
      query: (idProfile) => `/profiles/${idProfile}`,
    }),

    getProfileDocuments: builder.query<string, string>({
      query: (idProfile) => `/profiles/${idProfile}/documents`,
      providesTags: ['MedcardDocuments'],
    }),

    getProfileDocumentsCount: builder.query<string, string>({
      query: (idProfile) => `/profiles/${idProfile}/documents/count`,
    }),

    getMedcardDocument: builder.query<Buffer, MedcardDocumentsArgs>({
      query: ({ idProfile, idDocument, idFile }) => ({
        url: `/profiles/${idProfile}/documents/${idDocument}/attachment/${idFile}`,
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),
    
    getExternalMedcardDocument: builder.query<Buffer, MedcardDocumentsArgs>({
      query: ({ idProfile, idDocument, idFile }) => ({
        url: `/profiles/${idProfile}/documents/external/${idDocument}/attachment/${idFile}`,
        responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
        cache: 'no-cache',
      }),
    }),

    getProfileRecommedations: builder.query<MedcardRecommedation[], string>({
      query: (idProfile) => `/profiles/${idProfile}/medcard/recommedations`,
    }),

    getMedcardCalendar: builder.query<string, string>({
      query: (idProfile) => `/profiles/${idProfile}/medcard/appointments/calendar`,
    }),

    getMedcardCalendarCases: builder.query<string, string>({
      query: (idProfile) => `/profiles/${idProfile}/medcard/appointments/list`,
      providesTags: ['MedcardDocuments'],
    }),

    getProfileNotifications: builder.query<NotificationsType, PaginationArgs>({
      query: (params) => ({
        url: `/notifications`,
        params: params,
      }),
      providesTags: ['Notifications'],
    }),

    getSearch: builder.query<SearchData, SearchArgs>({
      query: (params) => ({ url: `/search`, params: params }),
    }),

    getClinicsSearch: builder.query<Clinic[], ClinicsSearchArgs>({
      query: (params) => ({ url: '/clinics', params }),
    }),

    getConsents: builder.query<Consent[], string>({
      query: (idProfile) => `/profiles/${idProfile}/consents`,
      providesTags: ['Consents'],
    }),

    getProfileStat: builder.query<string, void>({
      query: () => `/stats`,
    }),

    getOnboardingClinics: builder.query<MedOrganizationItem[], void>({
      query: () => `onboarding/clinics`,
    }),

    getIsOnboardingRequired: builder.query<{ isOnboardingRequired: boolean }, void>({
      query: () => `profiles/onboarding`,
      providesTags: ['OnboardingStatus'],
    }),

    getMedcardUpdateDate: builder.query<string, string>({
      query: (idProfile) => ({ url: '/medcard', params: { idProfile } }),
    }),

    getProfileAuthType: builder.query<string, void>({
      query: () => ({
        url: `/auth-type`,
        responseHandler: 'text',
      }),
    }),

    getSharedMedcards: builder.query<MedcardLink[], void>({
      query: () => `profiles/medcard/links`,
      providesTags: ['SharedLinks'],
    }),

    getPayments: builder.query<DataResponse<Payment>, PaymentsArgs>({
      query: (params) => ({ url: `/${params.type}`, params: params }),
    }),

    getMedcardNotifications: builder.query<MedcardNotification[], void>({
      query: () => '/medcard/notifications',
    }),

    getAppoinmentClinics: builder.query<AppointmentClinic[], AppointmentClinicsArgs>({
      query: ({ idFerSpecialty, region }) => ({
        url: '/doctors/map',
        params: { idFerSpecialty, region },
      }),
    }),

    getTmkChats: builder.query<TmkChat[], void>({
      query: () => '/chats',
    }),

    getTmkUserInfo: builder.query<TmkUserInfo, void>({
      query: () => '/chats/session',
    }),

    getCalendarEvent: builder.query<string, string>({
      query: (idAppointment) => ({
        url: `/appointments/${idAppointment}/ics`,
        method: 'GET',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseHandler: async (response: any) =>
          window.location.assign(window.URL.createObjectURL(await response.blob())),
        cache: 'no-cache',
      }),
    }),

    //mutations

    uploadAnalysesDoc: builder.mutation<string, AppointmentDocumentsArgs>({
      query: ({ idMilaAppointment, ...args }) => {
        const body = prepareBodyWithFile(args);
        return {
          url: `/appointments/${idMilaAppointment}/analyses/uploadandattach`,
          body: body,
          method: 'POST',
        };
      },
      invalidatesTags: ['Appointments'],
    }),

    setAppointment: builder.mutation<string, SetAppointment>({
      query: (data) => {
        const { appointmentId, ...sendData } = data;
        return {
          url: `appointments/${appointmentId}`,
          body: sendData,
          method: 'POST',
        };
      },
    }),

    attachAppointmentPhoto: builder.mutation<string, AttachPhotoArgs>({
      query: ({ file, appointmentId }) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `appointments/${appointmentId}/photos/attach`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    attachAppointmentAnalyses: builder.mutation<string, { analysisDocumentId: string; appointmentId: string }>({
      query: ({ appointmentId, analysisDocumentId }) => {
        return {
          url: `appointments/${appointmentId}/analyses/attach`,
          method: 'PUT',
          params: { analysisDocumentId },
        };
      },
    }),

    detachAppointmentPhoto: builder.mutation<string, DetachPhotoArgs>({
      query: ({ photoId, appointmentId }) => ({
        url: `appointments/${appointmentId}/photos/${photoId}/detach`,
        method: 'PUT',
      }),
    }),

    detachAppointmentAnalyses: builder.mutation<string, { analysisDocumentId: string; appointmentId: string }>({
      query: (data) => {
        const { appointmentId, analysisDocumentId } = data;
        return {
          url: `appointments/${appointmentId}/analyses/detach`,
          method: 'PUT',
          params: { analysisDocumentId },
        };
      },
      invalidatesTags: ['Appointments'],
    }),

    rateAppointment: builder.mutation<
      string,
      { score: number; comment: string; rateType?: 'rate' | 'rateconnectionquality'; appointmentId: string }
    >({
      query: ({ appointmentId, score, comment, rateType = 'rate' }) => ({
        url: `appointments/${appointmentId}/${rateType}`,
        method: 'POST',
        body: { score, comment },
      }),
      invalidatesTags: ['Appointments'],
    }),

    createProfile: builder.mutation<string, CreateProfileArgs>({
      query: (data) => ({
        url: `/profiles/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Profiles'],
    }),

    createAppointment: builder.mutation<string, CreateAppointmentArgs>({
      query: (data) => ({
        url: `/patients/${data.patientId}/appointments`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Appointments', 'Notifications'],
    }),

    rescheduleAppointment: builder.mutation<string, RescheduleAppointmentArgs>({
      query: ({ appointmentId, endDate, startDate, misTimeSlotId }) => ({
        url: `/appointments/${appointmentId}/reschedule`,
        method: 'POST',
        body: { endDate, startDate, misTimeSlotId },
      }),
      invalidatesTags: ['Appointments'],
    }),

    cancelAppointment: builder.mutation<string, CancelAppointmentArgs>({
      query: (data) => ({
        url: `appointments/${data.appointmentId}/cancel`,
        method: 'POST',
        params: {
          isReschedule: data.isReschedule,
        },
      }),
      invalidatesTags: ['Appointments'],
    }),

    deleteProfile: builder.mutation<string, ProfileRemoveArgs>({
      query: ({ data, userId }) => ({
        url: `/profiles/${userId}/delete`,
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: ['Profiles'],
    }),

    updateProfileInfo: builder.mutation<string, { idProfile: string; data: Profile }>({
      query: ({ idProfile, data }) => ({
        url: `profiles/${idProfile}`,
        method: 'PUT',
        body: data,
      }),
    }),

    updateNotificationStatus: builder.mutation<string, string>({
      query: (idNotification) => ({
        url: `notifications/${idNotification}/viewed`,
        method: 'PUT',
      }),
      invalidatesTags: ['Notifications'],
    }),

    uploadAvatar: builder.mutation<string, UploadProfileAvatar>({
      query: (data) => {
        const { file, profileId } = data;
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `/profiles/${profileId}/avatar`,
          body: formData,
          method: 'POST',
        };
      },
    }),

    deleteAvatar: builder.mutation<string, string>({
      query: (userId) => ({
        url: `/profiles/${userId}/avatar`,
        method: 'DELETE',
      }),
    }),

    updateConsent: builder.mutation<string, { idMilaConsent: string; idProfile: string; isActive: boolean }>({
      query: ({ idMilaConsent, idProfile, isActive }) => ({
        url: `/profiles/${idProfile}/consents/${idMilaConsent}`,
        method: 'PUT',
        body: { isActive },
      }),
      invalidatesTags: ['Consents', 'Profiles'],
    }),

    sendMessageToSupport: builder.mutation<string, MessageToSupport>({
      query: (body) => ({
        url: `/support`,
        body,
        method: 'POST',
      }),
    }),

    addChildrenToMedcards: builder.mutation<string, {body: ChildrenConsent, keycloakObj: any}>({
      query: ({body, keycloakObj}) => ({
        url: `/onboarding/children`,
        method: 'POST',
        body,
        params: { accessToken: KeyCloakService(keycloakObj).getAccessToken() },
      }),
    }),

    addMedOrganizationDataToMedcards: builder.mutation<string, { externalClinicIds: string[] }>({
      query: (body) => ({
        url: `/onboarding/clinics`,
        method: 'POST',
        body,
      }),
    }),

    joinEsiaAccount: builder.mutation<string, EsiaRequestArgs>({
      query: ({ idProfile, accessToken }) => ({
        url: `profiles/${idProfile}/join-esia-account`,
        method: 'POST',
        body: { brokerAccessToken: accessToken },
      }),
    }),

    createEsiaAccount: builder.mutation<string, string>({
      query: (accessToken) => ({
        url: `profiles/create-esia-account`,
        method: 'POST',
        body: { brokerAccessToken: accessToken },
      }),
    }),

    shareMedcard: builder.mutation<string, ShareMedcardArgs>({
      query: ({ idProfile, hideSmartAnalysisTab, idAppointment, type }) => ({
        url: `profiles/${idProfile}/medcard/share`,
        method: 'POST',
        body: {
          hideSmartAnalysisTab: hideSmartAnalysisTab ?? false,
          idAppointment,
          type,
        },
      }),
      invalidatesTags: ['SharedLinks'],
    }),
    //загрузка по сохранить
    uploadMedcardDocument: builder.mutation<void, MedcardUploadSeveralDocumentsArgs>({
      query: ({ idProfile, ...data }) => {
        console.log('МОЯ ДАТА', data);
        // console.log('ПАТЧ', operations);
        return {
          url: `/profiles/${idProfile}/documents/create`,
          body: data,
          method: 'POST',
        };
      },
      invalidatesTags: ['MedcardDocuments', 'Appointments'],
    }),

    updateMedcardDocument: builder.mutation<string, MedcardUploadDocumentsArgs>({
      query: ({ idProfile, idDocument, ...data }) => ({
        url: `/profiles/${idProfile}/documents/${idDocument}`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: ['MedcardDocuments', 'Appointments'],
    }),

    removeMedcardDocument: builder.mutation<any, { idProfile: string, idDocument: string }>({
      query: ({ idProfile, idDocument }) => ({
        url: `/profiles/${idProfile}/documents/${idDocument}`,
        method: 'DELETE',
      }),
      invalidatesTags: [],
    }),

    updateMedcardExternalDocument: builder.mutation<string, MedcardUpdateExternalDocumentsArgs>({
      query: ({ externalDocumentId, ...data }) => ({
        url: `/profiles/documents/external/${externalDocumentId}`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: ['MedcardDocuments', 'Appointments'],
    }),

    deleteSharedLink: builder.mutation<string, string>({
      query: (id) => ({
        url: `/profiles/medcard/links/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['SharedLinks'],
    }),

    addAttachmentFile: builder.mutation<string, MedcardDocumentsArgs>({
      query: ({ idProfile, idDocument, files }) => {
        const formData = new FormData();
        files?.forEach((file: UploadFile) => {
          formData.append('files', file.originFileObj as unknown as Blob);
        });
        return {
          url: `/profiles/${idProfile}/documents/${idDocument}/attachment`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['MedcardDocuments', 'Appointments'],
    }),

    deleteAttachmentFile: builder.mutation<string, MedcardDocumentsArgs>({
      query: ({ idProfile, idDocument, idFile }) => ({
        url: `/profiles/${idProfile}/documents/${idDocument}/attachment/${idFile}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MedcardDocuments', 'Appointments'],
    }),

    sendToAdditionalEmail: builder.mutation<string, AdditionalEmailArgs>({
      query: ({ appointmentId, email }) => ({
        url: `/appointments/${appointmentId}/additional-email`,
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),

    sendToAdditionalEmailWaitingList: builder.mutation<string, AdditionalEmailArgs>({
      query: ({ appointmentId, email }) => ({
        url: `/appointments/waitingList/${appointmentId}/additional-email`,
        method: 'POST',
        body: {
          email: email,
        },
      }),
    }),

    setTmkChat: builder.mutation<TmkChat, string>({
      query: (idMedOrganization) => ({
        url: `/chats/${idMedOrganization}`,
        method: 'POST',
      }),
    }),

    setOnboardingPassed: builder.mutation<string, void>({
      query: () => ({ url: `profiles/onboarding`, method: 'POST' }),
      invalidatesTags: ['OnboardingStatus'],
    }),

    addToWaitingList: builder.mutation<string, { patientId: string; data: AddWaitingListArgs }>({
      query: ({ data, patientId }) => ({
        url: `/appointments/waitinglist`,
        method: 'POST',
        body: data,
        params: { patientId },
      }),
    }),
  }),
});

export const {
  useGetAppointmentSessionIdMutation,
  useGetCalendarRecordsQuery,
  useGetAppointmentQuery,
  useGetSpecialtiesQuery,
  useSetAppointmentMutation,
  useSignDocumentsMutation,
  useCancelAppointmentMutation,
  useAttachAppointmentPhotoMutation,
  useDetachAppointmentPhotoMutation,
  useLazyGetAttachedAnalysisDocsQuery,
  useGetAttachedAnalysisDocsQuery,
  useUploadAnalysesDocMutation,
  useLazyAnalysesByQueryQuery,
  useGetDoctorQuery,
  useGetAboutDoctorInfoQuery,
  useGetDoctorReviewsQuery,
  useGetDoctorScheduleQuery,
  useGetDoctorClinicsServicesQuery,
  useGetClinicInfoQuery,
  useGetAboutClinicInfoQuery,
  useGetClinicsRecordsQuery,
  useGetDoctorsQuery,
  useGetClinicReviewsQuery,
  useGetPromotionInfoQuery,
  useGetPromotionsQuery,
  useGetWaitingListInfoQuery,
  useGetClinicsQuery,
  useGetProfilesQuery,
  useGetProfileInfoQuery,
  useGetProfileNotificationsQuery,
  useGetProfileRemoveReasonsQuery,
  useGetRegionsQuery,
  useGetSharedMedcardsQuery,
  useLazyGetClinicsSearchQuery,
  useGetConsentsQuery,
  useLazyGetSearchQuery,
  useGetProfileStatQuery,
  useGetProfileDocumentsQuery,
  useGetProfileDocumentsCountQuery,
  useGetProfileRecommedationsQuery,
  useGetMedcardCalendarQuery,
  useGetMedcardCalendarCasesQuery,
  useLazyGetMedcardDocumentQuery,
  useLazyGetExternalMedcardDocumentQuery,
  useGetOnboardingClinicsQuery,
  useGetOrderMedicinesTokenQuery,
  useGetIsOnboardingRequiredQuery,
  useGetMedcardUpdateDateQuery,
  useGetProfileAuthTypeQuery,
  useGetPaymentsQuery,
  useGetMedcardNotificationsQuery,
  useGetAppoinmentClinicsQuery,
  useGetPharmDocumentsQuery,
  useLazyGetCalendarEventQuery,
  useAttachAppointmentAnalysesMutation,
  useDetachAppointmentAnalysesMutation,
  useRateAppointmentMutation,
  useCreateAppointmentMutation,
  useCreateProfileMutation,
  useUpdateProfileInfoMutation,
  useUpdateConsentMutation,
  useRescheduleAppointmentMutation,
  useDeleteProfileMutation,
  useUpdateNotificationStatusMutation,
  useUploadAvatarMutation,
  useDeleteAvatarMutation,
  useSendMessageToSupportMutation,
  useAddChildrenToMedcardsMutation,
  useAddMedOrganizationDataToMedcardsMutation,
  useCreateEsiaAccountMutation,
  useJoinEsiaAccountMutation,
  useShareMedcardMutation,
  useUploadMedcardDocumentMutation,
  // usePreUploadMedcardSeveralDocumentsMutation,
  useUpdateMedcardDocumentMutation,
  useRemoveMedcardDocumentMutation,
  useUpdateMedcardExternalDocumentMutation,
  useDeleteSharedLinkMutation,
  useAddAttachmentFileMutation,
  useDeleteAttachmentFileMutation,
  useSendToAdditionalEmailMutation,
  useSendToAdditionalEmailWaitingListMutation,
  useGetTmkChatsQuery,
  useGetTmkUserInfoQuery,
  useSetTmkChatMutation,
  useSetOnboardingPassedMutation,
  useAddToWaitingListMutation,
} = apiPatientSlice;

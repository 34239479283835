import { Button, Row, Skeleton, Typography } from 'antd';
import { ClinicIcon } from 'src/features/Catalog/ClinicIcon/ClinicIcon';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { InvitationModal } from 'src/features/Catalog/InvitationModal/InvitationModal';
import { MilaService } from '../../../common/types';
import { MyClinicsDump, Plus, Star2 } from 'src/common/icons';
import { SliderRow } from 'src/common/components/SliderRow/SliderRow';
import { useGetClinicsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from 'src/common/redux/api/apiWhiteLabelSlice';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

interface MyClinicsPreviewProps {
  maxShow: number;
  showCatalogTitle?: boolean;
}

export const MyClinicsPreview = ({ maxShow, showCatalogTitle }: MyClinicsPreviewProps) => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: profiles, isLoading: profileLoading } = useGetProfilesQuery();

  const { data: clinics, isLoading: clinicsLoading } = useGetClinicsQuery(
    {
      profileIds: profiles?.map((profile) => profile.idProfile),
    },
    { skip: !(profiles?.length && profiles.length > 0) },
  );

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleExternalClinicClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const CatalogTitle = useMemo(
    () =>
      showCatalogTitle && (
        <Row className={styles.TitleRow}>
          <Star2 />
          <Typography className={styles.Title}>Мои клиники</Typography>
        </Row>
      ),
    [showCatalogTitle],
  );

  const AddClinic = useMemo(
    () => (
      <Row className={styles.Empty}>
        <Button className={styles.AddClinicButton} onClick={handleOpenModal}>
          <Plus />
        </Button>
        <Typography.Text>Пригласить клинику</Typography.Text>
      </Row>
    ),
    [],
  );

  if (clinicsLoading || profileLoading)
    return (
      <>
        {CatalogTitle}
        <Row>
          <Skeleton.Image active />
          <Skeleton active paragraph={{ rows: 2 }} title={false} />
        </Row>
      </>
    );

  return showCatalogTitle && clinics && clinics.length === 0 ? (
    <></>
  ) : (
    <>
      {CatalogTitle}
      <SliderRow
        className={styles.Row}
        arrowsClass={styles.Arrows}
        arrowsHide={!!clinics?.length && clinics.length < maxShow}
      >
        {clinics?.map((clinic) => (
          <ClinicIcon clinic={clinic} key={clinic.idMedOrganization} onClick={handleExternalClinicClick} />
        ))}
        {clinics?.length === 0 && isWhiteLabelEnabled && (
          <Row className={styles.MyClinicsDumpBlock}>
            <Row>
              <MyClinicsDump />
            </Row>
            <Row>
              <Typography className={styles.MyClinicsText}>Здесь будет список ваших клиник</Typography>
            </Row>
          </Row>
        )}
        {(!isWhiteLabelEnabled || whiteLabel?.services?.includes(MilaService.AddClinic)) && AddClinic}
      </SliderRow>
      <CustomModal isOpen={isModalOpen} className={styles.AddClinicModal} onCancelClick={handleCloseModal}>
        <InvitationModal />
      </CustomModal>
    </>
  );
};

import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { BranchPlacemark } from '../../MapModule/BranchPlacemark/BranchPlacemark';
import { CaretDown, Link, Shield } from 'src/common/icons';
import { Clinic, ClinicAboutInfo, ClinicCommonInfo, MilaService } from 'src/common/types';
import { Collapse, Layout, Row, Typography } from 'antd';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { MapWithPlacemarks } from '../../../common/components/MapWithPlacemarks/MapWithPlacemarks';
import { ServiceRequestType } from 'src/common/types/enums';
import { SpecialtyIcon } from 'src/common/components/SpecialtyIcon/SpecialtyIcon';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { adultAge, specialtyColors } from 'src/common/contants/constatns';
import { useAppSelector } from 'src/app/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetIsOnboardingRequiredQuery, useGetSpecialtiesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from 'src/common/redux/api/apiWhiteLabelSlice';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styles from './styles.module.scss';

interface AboutClinicProps {
  clinicAbout: ClinicAboutInfo;
  clinicInfo: ClinicCommonInfo;
}

export const AboutClinic = ({ clinicAbout, clinicInfo }: AboutClinicProps) => {
  const showChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const [activeTab, setActiveTab] = useState<ServiceRequestType>();
  const [placemark, setPlacemark] = useState();
  const [networkClinicsToMap, setNetworkClinicsToMap] = useState<Array<Clinic>>([]);

  const { data: specialties, isFetching } = useGetSpecialtiesQuery(
    {
      serviceType: activeTab!,
      practiceId: clinicInfo.idMedOrganization,
      serveAge: showChildren ? childrenAge : adultAge,
    },
    { skip: activeTab === undefined },
  );
  const { data: onboardingStatus } = useGetIsOnboardingRequiredQuery();
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const navigate = useNavigate();
  const location = useLocation();

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as unknown as ServiceRequestType);
  };

  const items = useMemo(
    () => [
      {
        label: `В клинике`,
        key: ServiceRequestType.OnSite as unknown as string,
        disabled:
          clinicAbout.inClinicSpecializations.length === 0 ||
          (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.OnSite)),
      },
      {
        label: `Онлайн`,
        key: ServiceRequestType.Online as unknown as string,
        disabled:
          clinicAbout.onlineSpecializations.length === 0 ||
          (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.Online)),
      },
      {
        label: `На дому`,
        key: ServiceRequestType.AtHome as unknown as string,
        disabled:
          clinicAbout.atHomeSpecializations.length === 0 ||
          (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.AtHome)),
      },
    ],
    [clinicAbout, whiteLabel],
  );

  useEffect(() => {
    const enabledItem = items.find((item) => !item.disabled);
    if (enabledItem) {
      setActiveTab(enabledItem.key as unknown as ServiceRequestType);
    }
  }, [items]);

  useEffect(() => {
    if (onboardingStatus?.isOnboardingRequired) {
      navigate('/onboarding', {
        state: {
          prevLocationPath: location.pathname,
          prevLocationParams: location.search,
        },
      });
    }
  }, [onboardingStatus, location]);

  const handleMapClick = useCallback(
    (e: Event, branchId: string) => {
      e.preventDefault();
      navigate(`/catalog/${branchId}`);
    },
    [navigate],
  );

  useEffect(() => {
    let clinics: Clinic[] = [];

    if (clinicAbout.otherClinicsInNetwork.length > 0) {
      clinics = [...clinicAbout.otherClinicsInNetwork];
    }

    const currentClinicInList = clinics.find((branch) => branch.idMedOrganization === clinicInfo.idMedOrganization);
    if (!currentClinicInList) {
      clinics.push(clinicInfo);
    }

    setNetworkClinicsToMap(clinics);
  }, [clinicAbout, clinicInfo]);

  return (
    <Layout className={styles.AboutClinicWrapper}>
      <WhiteRow className={styles.ClinicSpecialtiesRow}>
        <CustomCardTabs
          items={items}
          activeKey={activeTab}
          containerClassName={styles.TabsContainer}
          onChange={handleTabChange}
        />
        <Row className={styles.ClinicSpecialtiesSubRow}>
          <Typography className={styles.ClinicSpecialtiesTitle}>Специализации</Typography>
          <AgeToggle mini containerClassName={styles.AboutClinicAgeToggle} />
        </Row>
        {activeTab !== undefined && (
          <Row className={styles.ServicesRow}>
            {isFetching ? (
              <CustomSpinner />
            ) : (
              specialties?.map((specialty, index) => (
                <SpecialtyIcon
                  key={specialty.idFerSpeciality}
                  specialty={specialty}
                  color={specialtyColors[index]}
                  serviceType={activeTab}
                  inClinic
                />
              ))
            )}
          </Row>
        )}
      </WhiteRow>

      <Row className={styles.AboutRow}>
        <Collapse
          expandIconPosition={'end'}
          ghost
          className={styles.DocumentsCollapse}
          expandIcon={({ isActive }) =>
            isActive ? <CaretDown /> : <CaretDown style={{ transform: 'rotate(180deg)' }} />
          }
          defaultActiveKey={['1', '2', '3']}
        >
          <Collapse.Panel className={styles.SubTitle} header="О клинике" key="1">
            <Typography>{clinicAbout.clinicAbout.aboutDescription}</Typography>
            {clinicAbout.clinicAbout.documents?.map((document) => (
              <Row className={styles.LinkRow} key={document.name}>
                <Shield className={styles.ShieldIcon} />
                <Typography.Link
                  className={styles.LinkText}
                  href={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${document.staticFileId}`}
                  target="_blank"
                >
                  {document.name}
                </Typography.Link>
              </Row>
            ))}
            <Row className={styles.LinkRow}>
              <Link className={styles.LinkIcon} />

              <Typography.Link className={styles.LinkText} href={clinicAbout.clinicAbout.link} target="_blank">
                {clinicAbout.clinicAbout.link}
              </Typography.Link>
            </Row>
          </Collapse.Panel>
          <Collapse.Panel className={styles.SubTitle} header="Как добраться" key="2">
            <Typography>{clinicAbout.clinicAbout.howToGetThereDescription}</Typography>
          </Collapse.Panel>
          <Collapse.Panel className={styles.SubTitle} header="Парковка" key="3">
            <Typography>{clinicAbout.clinicAbout.parkingDescription}</Typography>
          </Collapse.Panel>
        </Collapse>
      </Row>
      <MapWithPlacemarks
        mapState={{ zoom: 15, center: [Number(clinicInfo.latitude), Number(clinicInfo.longitude)] }}
        placemark={placemark}
        className={styles.OthersClinicsMap}
      >
        <>
          <Typography className={styles.Title}>Все клиники сети</Typography>
          {networkClinicsToMap?.map((branch) => (
            <BranchPlacemark
              latitude={branch.latitude}
              longitude={branch.longitude}
              key={branch.idMedOrganization}
              branch={branch}
              handleClick={handleMapClick}
              active={branch.idMedOrganization === clinicInfo.idMedOrganization}
              instanceRef={setPlacemark}
            />
          ))}
        </>
      </MapWithPlacemarks>
    </Layout>
  );
};

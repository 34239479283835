import { Image, Row, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import {
  useGetClinicsQuery,
  useGetProfilesQuery,
  useSetTmkChatMutation,
} from '../../../common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from '../../../common/redux/api/apiWhiteLabelSlice';
import Face from 'src/assets/FaceGlasses.png';
import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';

export const InfoModal = () => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const { data: profiles } = useGetProfilesQuery();
  const { data: clinics } = useGetClinicsQuery({
    profileIds: profiles?.map((profile) => profile.idProfile),
  });
  const navigate = useNavigate();
  const [setChat, chatInfo] = useSetTmkChatMutation();

  const handleGetTmkChat = (e: React.MouseEvent) => {
    e.preventDefault();
    if (clinics) setChat(clinics[0].idMedOrganization);
  };

  useEffect(() => {
    const roomId = chatInfo?.data?.roomId;
    if (roomId) navigate(`/settings/userProfile/chats?room=${roomId}`);
  }, [chatInfo?.data, navigate]);

  const InfoModalText = `
  В медкарту попадают сведения, переданные вашими клиниками по результатам приема, а также документы, которые вы загрузили самостоятельно - напрямую в медкарту или через форму «Информация для врача» к предстоящей записи.

  В разделе «Календарь обращений» данные медкарты сгруппированы по месяцам и типам медицинского обслуживания. С его помощью можно наглядно оценить, насколько часто вы обращаетесь за врачебной помощью и услугами или быстро найти случаи госпитализации. В разделе «Документы» хранятся непосредственно файлы, сгруппированные по типам. Для каждого документа вы можете открыть или закрыть доступ. Закрытые к просмотру документы не будут доступны по ссылке, которую вы можете создать в сервисе «Поделиться медкартой».

  Обращаю ваше внимание, что клиники могут передавать медицинские сведения в разном объеме, в структурированном виде или с приложенными файлами. В зависимости от этого отображение данных может отличаться. По вопросам отображения или отсутствия сведений в Mila вы можете обратиться в медицинскую организацию, где вам оказывались медицинские услуги. А в случае обнаружения ошибок в работе сервиса, пожалуйста, свяжитесь с <a class=${styles.Link} href="${window.__RUNTIME_CONFIG__.REACT_APP_HOST}/help">Техподдержкой</a>.`;

  const InfoModalTextWhiteLabel = (
    <Typography className={styles.ModalMessageInfo}>
      <Typography.Text className={styles.ModalMessageText}>
        В медкарту попадают сведения, переданные из клиники по результатам приема, а также документы, которые вы
        загрузили самостоятельно. В разделе «Календарь обращений» данные медкарты сгруппированы по месяцам и типам
        медицинского обслуживания. С его помощью можно наглядно оценить, насколько часто вы обращаетесь за врачебной
        помощью и услугами или быстро найти случаи госпитализации. В разделе «Документы» хранятся непосредственно файлы,
        сгруппированные по типам.
      </Typography.Text>
      <br />
      <br />
      <Typography.Text className={styles.ModalMessageText}>
        Для каждого документа вы можете открыть или закрыть доступ. Закрытые к просмотру документы не будут доступны по
        ссылке, которую вы можете создать в сервисе «Поделиться медкартой».
      </Typography.Text>
      <br />
      <br />
      <Typography.Text className={styles.ModalMessageText}>
        Документы из клиники могут быть переданы в разном объеме, в структурированном виде или с приложенными файлами. В
        зависимости от этого отображение данных может отличаться. По вопросам отображения или отсутствия сведений в
        личном кабинете вы можете{' '}
        <Link onClick={handleGetTmkChat} className={styles.Link} to="">
          <Typography.Text className={styles.LinkText}>обратиться </Typography.Text>
        </Link>
        к администратору клиники. А в случае обнаружения ошибок в работе сервиса, пожалуйста, свяжитесь с{' '}
        <Link className={styles.Link} to={`${window.__RUNTIME_CONFIG__.REACT_APP_HOST}/help`}>
          <Typography.Text className={styles.LinkText}>Техподдержкой</Typography.Text>.
        </Link>
      </Typography.Text>
      <br />
    </Typography>
  );

  return (
    <Row className={styles.InfoRow}>
      <Typography className={styles.Title}>Информация о сервисе</Typography>
      <Image src={Face} preview={false} className={styles.Image} />
      <MilaMessage
        title={isWhiteLabelEnabled ? 'Какие данные есть в медкарте' : 'Из каких данных я собираю медкарту'}
        className={styles.Message}
        text={!isWhiteLabelEnabled ? InfoModalText : ''}
        textHtmlElement={isWhiteLabelEnabled ? InfoModalTextWhiteLabel : undefined}
      />
    </Row>
  );
};

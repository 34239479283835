import { Row, Typography, Upload, notification } from 'antd';
import React, { useState } from 'react';

import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { Plus } from 'src/common/icons';

import { UploadFile } from 'antd/es/upload';
import styles from './styles.module.scss';

import { MedcardDocument } from 'src/common/types';
import { useAddAttachmentFileMutation } from 'src/common/redux/api/apiPatientSlice';
interface UpdateUploadBtnProps {
  document: MedcardDocument;
}

const UpdateUploadBtn: React.FC<UpdateUploadBtnProps> = ({ document }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDocumentLoading, setDocumentLoading] = useState<boolean>(false);
  const [addFile] = useAddAttachmentFileMutation();

  const handleUpload = async (files: UploadFile[]) => {
    setDocumentLoading(true);
    try {
      if (document && files.length > 0) {
        await addFile({
          idDocument: document.idMilaUserDocument ? document.idMilaUserDocument : document.idExternalDocument,
          idProfile: document.profileId,
          files,
        }).unwrap();

        notification.success({ message: 'Файл(ы) успешно загружены' });
        setFileList([]);
      } else {
        notification.error({ message: 'Пожалуйста, выберите файлы для загрузки' });
      }
    } catch (err) {
      notification.error({ message: 'Ошибка загрузки документа' });
    } finally {
      setDocumentLoading(false);
    }
  };

  const handleFileChange = async ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
    if (fileList.length > 0) {
      await handleUpload(fileList);
    }
  };

  return (
    <Row className={styles.antRow}>
      <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false} multiple>
        <label htmlFor="fileUpload" className={styles.labelUpload}>
          <CustomButton className={`${styles.uploadBtn}`}>
            <div className={`${styles.upload_btn}`}>
              <Plus />
              <Typography>Загрузить файлы</Typography>
            </div>
          </CustomButton>
        </label>
      </Upload>
    </Row>
  );
};

export default UpdateUploadBtn;

import { Col, Row, Space, Typography } from 'antd';
import { FC } from 'react';
import { HistoryData } from '../constants';
import RedCoin from '../../../../src/assets/RedCoin.svg';
import YellowCoin from '../../../../src/assets/YellowCoin.svg';
import cx from 'classnames';
import styles from './styles.module.scss';

const { Text } = Typography;

export type HistoryItemProps = HistoryData;

export const HistoryItem: FC<HistoryItemProps> = ({ date, amount, details, description }) => (
  <Row gutter={[8, 4]} className={styles.container}>
    <Col xs={24} md={4}>
      <Text type="secondary" className={styles.date}>
        {date}
      </Text>
    </Col>
    <Col xs={24} md={8}>
      <Space direction="vertical">
        <Space>
          <img src={amount.startsWith('+') ? YellowCoin : RedCoin} alt="" className={styles.coinIcon} />
          <Text
            className={cx(styles.amount, {
              [styles.red]: amount.startsWith('-'),
            })}
          >
            {amount} бонусов
          </Text>
        </Space>
        {details && (
          <Text type="secondary" className={styles.details}>
            {details}
          </Text>
        )}
      </Space>
    </Col>
    <Col xs={24} md={12}>
      <Text className={styles.description}>{description}</Text>
    </Col>
  </Row>
);

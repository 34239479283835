import { useState, useEffect, useCallback } from 'react';
import { DocumentData, PDFViewer } from 'src/common/components/PDFViewer/PDFViewer';
import { MedcardDocument, MedcardDocumentAttachment } from 'src/common/types';
import {
  useLazyGetExternalMedcardDocumentQuery,
  useLazyGetMedcardDocumentQuery,
} from 'src/common/redux/api/apiPatientSlice';
import {
  useLazyGetExternalSharedMedcardDocumentQuery,
  useLazyGetSharedMedcardDocumentQuery,
} from 'src/common/redux/api/apiSharedSlice';
import styles from './styles.module.scss';

interface DocumentViewerModalProps {
  medcardDocument?: MedcardDocument;
  isOpen: boolean;
  onClose: () => void;
  idShared?: string;
  initialDocumentIndex?: number;
}

export const DocumentViewerModal = ({
  medcardDocument,
  isOpen,
  onClose,
  idShared,
  initialDocumentIndex = 0,
}: DocumentViewerModalProps) => {
  const [getDocument] = useLazyGetMedcardDocumentQuery();
  const [getExternalDocument] = useLazyGetExternalMedcardDocumentQuery();
  const [getSharedDocument] = useLazyGetSharedMedcardDocumentQuery();
  const [getExternalSharedDocument] = useLazyGetExternalSharedMedcardDocumentQuery();

  const [documentsUrls, setDocumentsUrls] = useState<DocumentData[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(initialDocumentIndex);

  useEffect(() => {
    setCurrentFileIndex(initialDocumentIndex);
  }, [initialDocumentIndex]);

  const downloadFile = async (file: MedcardDocumentAttachment): Promise<DocumentData> => {
    let blob;
    if (idShared) {
      if (medcardDocument?.idMilaUserDocument) {
        blob = await getSharedDocument({
          sharedId: idShared,
          idDocument: medcardDocument.idMilaUserDocument,
          idFile: file.idDocumentFile,
        }).unwrap();
      } else {
        blob = await getExternalSharedDocument({
          sharedId: idShared,
          idDocument: medcardDocument?.idExternalDocument!,
          idFile: file.idExternalDocumentFile,
        }).unwrap();
      }
    } else {
      if (medcardDocument?.idMilaUserDocument) {
        blob = await getDocument({
          idProfile: medcardDocument.profileId!,
          idDocument: medcardDocument.idMilaUserDocument,
          idFile: file.idDocumentFile,
        }).unwrap();
      } else {
        blob = await getExternalDocument({
          idProfile: medcardDocument?.profileId!,
          idDocument: medcardDocument?.idExternalDocument!,
          idFile: file.idExternalDocumentFile,
        }).unwrap();
      }
    }

    return { url: blob as any, mimeType: file.mimeType };
  };

  const loadAllFiles = useCallback(async () => {
    if (medcardDocument?.files) {
      const promises = medcardDocument.files.map(downloadFile);
      const results = await Promise.all(promises);
      setDocumentsUrls(results);
    }
  }, [medcardDocument]);

  useEffect(() => {
    if (isOpen) {
      loadAllFiles();
    }
  }, [isOpen, loadAllFiles]);

  useEffect(() => {
    if (isOpen && typeof window !== 'undefined' && typeof document !== 'undefined') {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        document.body.style.overflow = '';
      }
    };
  }, [isOpen]);

  const handleShare = async (fileUrl: string, mimeType: string) => {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const shareData = {
      files: [
        new File([blob], `${medcardDocument?.documentName || 'document'}.${getFileExtension(mimeType)}`, {
          type: mimeType,
        }),
      ],
      title: medcardDocument?.documentName,
    };

    if (navigator.share && navigator.canShare(shareData)) {
      await navigator.share(shareData);
    } else {
      const printWindow = window.open('', '_blank');
      if (printWindow) {
        printWindow.document.write(`
            <html>
              <body>
                ${
                  mimeType.startsWith('image/')
                    ? `<img src="${fileUrl}" style="max-width:100%;" />`
                    : `<embed src="${fileUrl}" type="${mimeType}" width="100%" height="100%" />`
                }
              </body>
            </html>
          `);
        printWindow.document.close();
        printWindow.print();
      }
    }
  };

  const getFileExtension = (mimeType: string) => {
    const extensions: Record<string, string> = {
      'application/pdf': 'pdf',
      'application/pdf-converted-xml': 'pdf',
      'image/png': 'png',
      'image/jpeg': 'jpg',
      'image/svg+xml': 'svg',
    };
    return extensions[mimeType] || 'file';
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={styles.overlay}>
      <div
        className={styles.backdrop}
        role="button"
        tabIndex={0}
        aria-label="Закрыть модалку"
        onClick={onClose}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClose();
          }
        }}
      />

      <div className={styles.modalContent}>
        {documentsUrls.length > 0 && (
          <PDFViewer
            documents={documentsUrls}
            initialDocumentIndex={currentFileIndex}
            onShare={handleShare}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

import { useState, useEffect } from 'react';
import { Viewer, Worker, RenderPageProps } from '@react-pdf-viewer/core';
import { Button, Space, Typography } from 'antd';
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { pdfWorkerUrl } from 'src/common/contants/constatns';
import styles from './styles.module.scss';
import { CustomButton } from '../CustomButton/CustomButton';
import { Download, Share, ZoomIn, ZoomOut } from 'src/common/icons';

export interface DocumentData {
  url: string;
  mimeType: string;
}

interface PDFViewerProps {
  documents: DocumentData[];
  initialDocumentIndex?: number;
  onShare?: (fileUrl: string, mimeType: string) => void;
  onClose: () => void;
}

export const PDFViewer = ({ documents, initialDocumentIndex = 0, onShare, onClose }: PDFViewerProps) => {
  const [currentIndex, setCurrentIndex] = useState(initialDocumentIndex);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    setCurrentIndex(initialDocumentIndex);
  }, [initialDocumentIndex]);

  if (!documents || documents.length === 0) {
    return <div>Нет доступных документов</div>;
  }

  const showNavigation = documents.length > 1;
  const isFirstDocument = currentIndex === 0;
  const isLastDocument = currentIndex === documents.length - 1;

  const handleShareClick = () => {
    const currentDoc = documents[currentIndex];
    if (onShare) {
      onShare(currentDoc.url, currentDoc.mimeType);
    }
  };

  const goToPrevious = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
    setScale(1);
  };

  const goToNext = () => {
    setCurrentIndex((prev) => (prev < documents.length - 1 ? prev + 1 : prev));
    setScale(1);
  };

  const increaseScale = () => setScale((prev) => prev + 0.1);
  const decreaseScale = () => setScale((prev) => (prev > 0.2 ? prev - 0.1 : prev));

  const currentDoc = documents[currentIndex];
  const isPdf = currentDoc.mimeType === 'application/pdf' || currentDoc.mimeType === 'application/pdf-converted-xml';

  const renderPage = (props: RenderPageProps) => (
    <>
      {props.canvasLayer.children}
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = currentDoc.url;
    link.download = `document.${currentDoc.mimeType.split('/')[1]}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const printFile = () => {
    const win = window.open('', '_blank');
    if (win) {
      if (isPdf) {
        win.document.write(`
          <html>
            <head>
              <title>Печать PDF</title>
              <style>
                body, html { margin: 0; padding: 0; height: 100%; }
              </style>
            </head>
            <body>
              <embed src="${currentDoc.url}" type="application/pdf" width="100%" height="100%" />
            </body>
          </html>
        `);
        win.document.close();
        win.focus();
        setTimeout(() => {
          win.print();
        }, 500);
      } else {
        win.document.write(`
          <html>
            <head>
              <title>Печать Изображения</title>
              <style>
                body, html { margin: 0; padding: 0; text-align: center; }
              </style>
            </head>
            <body>
              <img src="${currentDoc.url}" style="max-width:100%;" onload="window.print();" />
            </body>
          </html>
        `);
        win.document.close();
      }
    }
  };

  return (
    <Worker workerUrl={pdfWorkerUrl}>
      <div className={styles.viewerContainer}>
        <div className={styles.customToolbar}>
          <div className={styles.toolbarWrapper}>
            <div>
              <Button type="link" icon={<Share />} onClick={handleShareClick} className={styles.share}>
                <div className={styles.desktopOnly}>Поделиться</div>
              </Button>
            </div>

            {showNavigation && (
              <div className={styles.pageNavigation}>
                <Button
                  onClick={goToPrevious}
                  icon={<LeftOutlined />}
                  disabled={isFirstDocument}
                  className={styles.pageNavigationButton}
                />
                <div>
                  {currentIndex + 1} из {documents.length}
                </div>
                <Button
                  onClick={goToNext}
                  icon={<RightOutlined />}
                  disabled={isLastDocument}
                  className={styles.pageNavigationButton}
                />
              </div>
            )}

            <Space size={32}>
              <div className={styles.zoomSection}>
                <Button type="text" onClick={decreaseScale} icon={<ZoomOut />} />
                <div className={styles.desktopOnly}>{Math.round(scale * 100)}%</div>
                <Button type="text" onClick={increaseScale} icon={<ZoomIn />} />
              </div>
              <Space size={32} className={styles.desktopOnly}>
                <Button type="text" onClick={downloadFile} icon={<Download />} />
                <CustomButton isPrimal onClick={printFile} className={styles.printButton}>
                  <Typography.Text>Распечатать</Typography.Text>
                </CustomButton>
              </Space>
              <Button type="text" icon={<CloseOutlined />} onClick={onClose} />
            </Space>
          </div>
        </div>

        <div className={styles.contentContainer}>
          <div className={styles.viewerContent}>
            <div
              className={styles.scaleWrapper}
              style={{
                transform: `scale(${scale})`,
                transformOrigin: 'top center',
                margin: '0 auto',
              }}
            >
              {isPdf ? (
                <div className={styles.pdfContainer}>
                  <Viewer fileUrl={currentDoc.url} renderPage={renderPage} theme="light" defaultScale={1} />
                </div>
              ) : (
                <div className={styles.imageContainer}>
                  <img src={currentDoc.url} alt="Документ" className={styles.image} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Worker>
  );
};

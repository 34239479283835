import { AppointmentDocumentsModal } from '../AppointmentDocumentsModal/AppointmentDocumentsModal';
import {
  AppointmentPaymentStatus,
  AppointmentStatus,
  AppointmentStatusChangedName,
  ServiceRequestType,
} from 'src/common/types/enums';
import { CalendarRecord } from 'src/common/types';
import { CancelAppointmentModals } from 'src/common/components/CancelAppointmentModals/CancelAppointmentModals';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { EstimateCard } from 'src/common/components/EstimateCard/EstimateCard';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { MedcardDocumentLoadForm } from 'src/features/MedCard/MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { Row, Typography, notification } from 'antd';
import { defaultAppointmentsWithStatusChanged } from '../../../common/contants/constatns';
import { getAppointmentStatusByCreated, getMinutesToNow, removeCents } from 'src/common/utils';
import { useAppSelector } from '../../../app/hooks';
import {
  useDetachAppointmentAnalysesMutation,
  useGetAppointmentSessionIdMutation,
} from 'src/common/redux/api/apiPatientSlice';
import { useGetSignalrQuery } from '../../../common/redux/api/apiAppointmentSignalrSlice';
import PaymentsService from 'src/common/services/payments/payments-service';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';

interface AppointmentButtonsProps {
  appointment: CalendarRecord;
  isList?: boolean;
  refetchData?: () => void;
}

export const AppointmentButtons = ({ appointment, isList = false, refetchData }: AppointmentButtonsProps) => {
  const navigate = useNavigate();
  const appointmentId = useMemo(() => appointment.idAppointment ?? appointment.idExternalAppointment, [appointment]);
  const appointmentStatus = useMemo(() => getAppointmentStatusByCreated(appointment.created), [appointment]);
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  const { data } = useGetSignalrQuery(defaultAppointmentsWithStatusChanged);
  const [params, setSearchParams] = useSearchParams();

  const currentClosedAppointment = data?.find((appointment) => appointment?.appointmentId === appointmentId);

  const isAppointmentClosedByDoctor =
    !data || data?.length <= 1
      ? false
      : currentClosedAppointment?.name === AppointmentStatusChangedName.Cancelled ||
        currentClosedAppointment?.name === AppointmentStatusChangedName.Finished ||
        currentClosedAppointment?.name === AppointmentStatusChangedName.ReportUploaded;
  const price = appointment ? removeCents(appointment.paymentCost) : null;

  const [isCancelModalVisible, setCancelModalVisibility] = useState(false);
  const [isDocumentsModalVisible, setDocumentsModalVisibility] = useState(false);
  const [isDocumentsFormModalVisible, setDocumentsFormModalVisibility] = useState(false);
  const [isEstimateModalVisible, setEstimateModalVisible] = useState(false);

  const [detachAppointmentAnalyses, detachDocMutation] = useDetachAppointmentAnalysesMutation();
  const [getAppointmentSessionId] = useGetAppointmentSessionIdMutation();
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);

  useEffect(() => {
    if (params.get('call') === null) {
      setIframeUrl(null);
    }
  }, [params]);

  useEffect(() => {
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  const listener = (e: any) => {
    if (e.data === 'CloseVcs') {
      setIframeUrl(null);
      setSearchParams({});
    }
  };

  const handleDocumentsModalClick = () => {
    setDocumentsModalVisibility(!isDocumentsModalVisible);
  };

  const handleRemoveDocument = (docId: string) => {
    detachAppointmentAnalyses({ analysisDocumentId: docId, appointmentId: appointmentId });
    refetchData && refetchData();
  };

  const handleOpenDocumentsForm = () => {
    setDocumentsModalVisibility(false);
    setDocumentsFormModalVisibility(true);
  };

  const handleDocumentsFormModalCancel = () => {
    setDocumentsFormModalVisibility(false);
  };

  const handleDocumentUpload = () => {
    setDocumentsFormModalVisibility(false);
    refetchData && refetchData();
  };

  const handleCreateAppointmentClick = () => {
    navigate(`/records/aboutDoctor/${appointment.doctor.idMilaDoctor}?activeTab=schedule`);
  };

  const handleStartMeetingClick = async () => {
    const result = await getAppointmentSessionId({ appointmentId: appointmentId }).unwrap();
    const link = `${window.__RUNTIME_CONFIG__.REACT_APP_VCS_URL}/tmk/${appointment.tmkAppointmentId}?sessionId=${result.sessionId}`;
    setIframeUrl(link);
    setSearchParams({ call: 'true' });
    //window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleCancelClick = () => {
    setCancelModalVisibility(true);
  };

  const handleCancelRecordModalOk = () => {
    setCancelModalVisibility(false);
    refetchData && refetchData();
  };

  const handleCancelRecordModalClose = () => {
    setCancelModalVisibility(false);
  };

  const handlePayAppointment = async () => {
    try {
      await PaymentsService.payAppointment(appointmentId, keycloakObj);
    } catch (e) {
      notification.error({ message: 'Ошибка оплаты.' });
    }
  };

  const handleEstimateCloseClick = () => {
    setEstimateModalVisible(false);
  };

  const handleEstimateOpenClick = () => {
    setEstimateModalVisible(true);
  };

  const handleRowClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <Row className={styles.ButtonsRow} onClick={handleRowClick}>
        {appointment.isCancelled && (
          <CustomButton className={styles.Button} onClick={handleCreateAppointmentClick}>
            <Typography>Записаться заново</Typography>
          </CustomButton>
        )}

        {!appointment.isCancelled && appointmentStatus !== AppointmentStatus.Past && (
          <>
            {isList &&
              appointmentStatus !== AppointmentStatus.Ongoing &&
              (appointment.paymentStatus === AppointmentPaymentStatus.MoneyReserved ||
                appointment.paymentStatus === null) && (
                <Link className={styles.Button} to={`/records/appointment/${appointmentId}/?doctorBlock=true`}>
                  <CustomButton className={styles.Button} isPrimal={true}>
                    <Typography>Подготовиться</Typography>
                  </CustomButton>
                </Link>
              )}

            {appointment.serviceDeliveryType === ServiceRequestType.Online &&
              !isAppointmentClosedByDoctor &&
              (appointment.paymentStatus === AppointmentPaymentStatus.CreatedAndNotPaid ||
                appointment.paymentStatus === AppointmentPaymentStatus.PaymentSystemError) && (
                <CustomButton className={styles.Button} onClick={handlePayAppointment}>
                  <Typography>Оплатить консультацию</Typography>
                </CustomButton>
              )}
          </>
        )}

        <>
          {appointment.serviceDeliveryType === ServiceRequestType.Online &&
            (appointment.paymentStatus === AppointmentPaymentStatus.MoneyReserved ||
              ((price === '0' || (appointment.paymentStatus === null && appointment.isCreatedByAdmin)) &&
                !appointment.isCancelled &&
                !appointment.isFreeCompleted)) && (
              <>
                {getMinutesToNow(appointment.created!) <= 15 && !isAppointmentClosedByDoctor && (
                  <CustomButton
                    className={`${styles.Button} ${styles.OrangeButton}`}
                    isPrimal={true}
                    onClick={handleStartMeetingClick}
                  >
                    <Typography>Подключиться</Typography>
                  </CustomButton>
                )}
              </>
            )}
        </>

        {!appointment.isCancelled &&
          appointmentStatus !== AppointmentStatus.Past &&
          !appointment.isFreeCompleted &&
          !isAppointmentClosedByDoctor &&
          appointment.paymentStatus !== AppointmentPaymentStatus.WithdrawByClinicInOrderOfSuccess &&
          appointment.paymentStatus !== AppointmentPaymentStatus.WithdrawByClinic &&
          appointment.paymentStatus !== AppointmentPaymentStatus.WithdrawBySystem &&
          appointment.paymentStatus !== AppointmentPaymentStatus.WithdrawByClinicWithoutReport && (
            <>
              {
                <CustomButton className={styles.Button} onClick={handleCancelClick}>
                  <Typography>Отменить</Typography>
                </CustomButton>
              }
            </>
          )}

        {!appointment.isCancelled && appointmentStatus === AppointmentStatus.Past && (
          <>
            {appointment.documents && appointment.documents.length > 0 && (
              <CustomButton className={styles.Button} onClick={handleDocumentsModalClick}>
                <Typography>
                  {appointment.documents && appointment.documents.length > 0
                    ? 'Посмотреть документы'
                    : 'Загрузить документы'}
                </Typography>
              </CustomButton>
            )}
            <CustomButton
              disabled={!!appointment.review}
              className={styles.Button}
              isPrimal={!appointment.review}
              onClick={handleEstimateOpenClick}
            >
              <Typography>
                {appointment.review ? `Вы оценили на ${appointment.review?.score}` : 'Оценить прием'}
              </Typography>
            </CustomButton>
          </>
        )}

        <CancelAppointmentModals
          appointmentId={appointmentId}
          doctorId={appointment.doctor.idMilaDoctor}
          isCancelModalVisible={isCancelModalVisible}
          handleSetCancelModalVisibility={handleCancelRecordModalOk}
          handleCancelRecordModalClose={handleCancelRecordModalClose}
          isTmk={appointment.serviceDeliveryType === ServiceRequestType.Online}
          isLessThenTwoHours={getMinutesToNow(appointment.created) < 120}
          price={price}
          status={appointment?.paymentStatus}
        />

        <CustomModal
          isOpen={isDocumentsModalVisible}
          onOkClick={handleDocumentsModalClick}
          onCancelClick={handleDocumentsModalClick}
          className={styles.DedicatedDocumentsModalContainer}
        >
          <AppointmentDocumentsModal
            documents={appointment.documents}
            appointment={appointment}
            refetch={refetchData!}
            removeDocument={handleRemoveDocument}
            deleteDocLoading={detachDocMutation.isLoading}
            openFormCallback={handleOpenDocumentsForm}
            idMedOrganization={appointment.medOrganization.idMedOrganization}
          />
        </CustomModal>

        <CustomModal
          isOpen={isDocumentsFormModalVisible}
          onCancelClick={handleDocumentsFormModalCancel}
          className={styles.DocumentsModalContainer}
        >
          <MedcardDocumentLoadForm
            onFinish={handleDocumentUpload}
            isAppointmentForm
            appointment={appointment}
            isUpcomingAppointment={appointmentStatus !== AppointmentStatus.Past}
            idMedcardProfile={appointment.patient.idPatient}
          />
        </CustomModal>

        <CustomModal
          isOpen={isEstimateModalVisible}
          onCancelClick={handleEstimateCloseClick}
          className={styles.EstimateModalWrapper}
        >
          <EstimateCard
            title={<Typography.Text>Пожалуйста, расскажите, как все прошло? </Typography.Text>}
            text={'Мне и врачу очень важен ваш отзыв'}
            rates={
              appointment.serviceDeliveryType !== ServiceRequestType.Online
                ? [{ rateTitle: 'Оцените работу врача', rateType: 'rate', ratePlaceholder: 'Напишите Ваш отзыв' }]
                : [
                    { rateTitle: 'Оцените работу врача', rateType: 'rate', ratePlaceholder: 'Напишите Ваш отзыв' },
                    {
                      rateTitle: 'А как Вам качество связи?',
                      rateType: 'rateconnectionquality',
                      ratePlaceholder: 'Расскажите, все ли было удобно',
                    },
                  ]
            }
            appointmentId={appointmentId}
          />
        </CustomModal>
      </Row>
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          title="AppointmentVCS"
          frameBorder={0}
          allow="camera; microphone"
          allowFullScreen
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
          }}
        />
      )}
    </>
  );
};

import { AboutDoctor } from 'src/features/AboutDoctor/AboutDoctor';
import { Appointment } from 'src/features/Appointment/Appointment';
import { AppointmentResult } from 'src/features/NewAppointment/AppointmentResult/AppointmentResult';
import { CalendarPage } from 'src/features/CalendarPage/CalendarPage';
import { Catalog } from 'src/features/Catalog/Catalog';
import { Clinic } from 'src/features/Clinic/Clinic';
import { Col, Layout } from 'antd';
import { Consent } from 'src/features/Consent/Consent';
import { Content } from 'antd/es/layout/layout';
import { Docs } from 'src/features/Docs/Docs';
import { ErrorPage } from 'src/features/ErrorPage/ErrorPage';
import { FooterRow } from 'src/common/components/Footer/Footer';
import { HeaderRow } from 'src/common/components/Header/Header';
import { Help } from 'src/features/Help/Help';
import { Home } from 'src/features/Home/Home';
import { MedCard } from 'src/features/MedCard/MedCard';
import { MyClinics } from 'src/features/Profile/MyClinics/MyClinics';
import { MyDoctors } from 'src/features/Profile/MyDoctors/MyDoctors';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NoMedCardBlock } from 'src/features/MedCard/SharedMedcard/NoMedCardBlock/NoMedCardBlock';
import { Notifications } from 'src/features/Profile/Notifications/Notifications';
import { Onboarding } from 'src/features/Onboarding/Onboarding';
import { OrderMedicines } from 'src/features/OrderMedicines/OrderMedicines';
import { PatientChoice } from 'src/features/NewAppointment/PatientChoice/PatientChoice';
import { Payments } from 'src/features/Profile/Payments/Payments';
import { Points } from 'src/features/Points';
import { Profile } from 'src/features/Profile/Profile';
import { ProfileForm } from 'src/features/Profile/ProfileForm/ProfileForm';
import { ProfileSidebar } from 'src/common/components/ProfileSidebar/ProfileSidebar';
import { Promo } from 'src/features/Promo/Promo';
import { ProtectedRoute } from 'src/common/components/PrivateRoute/PrivateRoute';
import { SharedMedcard } from 'src/features/MedCard/SharedMedcard/SharedMedcard';
import DoctorChoice from 'src/features/DoctorChoice/DoctorChoice';
import SpecialtiesChoice from 'src/features/SpecialtiesChoice/SpecialtiesChoice';
import styles from './styles.module.scss';

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <HeaderRow />
          <Content className={styles.ContentArea}>
            <ProfileSidebar />
            <Layout className={styles.ContentWrapper}>
              <Outlet />
            </Layout>
          </Content>
          <FooterRow />
        </ProtectedRoute>
      ),
      errorElement: (
        <>
          <HeaderRow />
          <Content className={styles.ContentArea}>
            <ProfileSidebar />
            <Layout className={styles.ContentWrapper}>
              <ErrorPage />
            </Layout>
          </Content>
          <FooterRow />
        </>
      ),
      children: [
        {
          path: 'onboarding',
          element: <Onboarding />,
        },
        {
          path: 'home',
          element: <Home />,
        },
        {
          path: 'help',
          element: <Help />,
        },
        {
          path: 'consent',
          element: <Consent />,
        },
        {
          path: 'points',
          element: <Points />,
        },
        {
          path: 'medcards/:profileId?',
          element: <MedCard />,
        },
        {
          path: 'docs/:profileId?',
          element: <Docs />,
        },
        {
          path: 'orderMedicines/:profileId?',
          element: <OrderMedicines />,
        },
        {
          path: 'records',
          children: [
            {
              index: true,
              element: <CalendarPage />,
            },
            {
              path: 'appointment/:appointmentId',
              element: <Appointment />,
            },
            {
              path: 'specialtyChoice',
              element: <SpecialtiesChoice />,
            },
            {
              path: 'doctorChoice/:specialtyId',
              element: <DoctorChoice />,
            },
            {
              path: 'patientChoice',
              element: <PatientChoice />,
            },
            {
              path: 'appointmentResult/:appointmentId?',
              element: <AppointmentResult />,
            },
            {
              path: 'promo/:promoId',
              element: <Promo />,
            },
            {
              path: 'aboutDoctor/:doctorId',
              element: <AboutDoctor />,
            },
          ],
        },
        {
          path: 'catalog',
          children: [
            {
              index: true,
              element: <Catalog />,
            },
            {
              path: ':clinicId',
              element: <Clinic />,
            },
          ],
        },
        {
          path: 'settings',
          children: [
            {
              path: 'userProfile',
              element: <ProfileForm isNewProfile />,
            },
            {
              path: 'userProfile/:profileId',
              element: <ProfileForm />,
            },
            {
              path: 'userProfile/notifications',
              element: <Notifications />,
            },
            {
              path: 'userProfile/chats',
              element: <Notifications showChats />,
            },
            {
              path: 'userProfile/myClinics',
              element: <MyClinics />,
            },
            {
              path: 'userProfile/myDoctors',
              element: <MyDoctors />,
            },
            {
              path: 'userProfile/payments',
              element: <Payments />,
            },
            {
              path: 'userProfile/moneyBacks',
              element: <Payments isMoneyBack />,
            },
            {
              index: true,
              element: <Profile />,
            },
          ],
        },
      ],
    },

    {
      path: '/shared/medcard/:id?',
      element: (
        <>
          <HeaderRow isShared />
          <Content className={`${styles.ContentArea}`}>
            <Col className={styles.NoMedCardDesktopCol}>
              <NoMedCardBlock />
            </Col>
            <Layout className={`${styles.ContentWrapper} `}>
              <SharedMedcard />
              <Col className={styles.NoMedCardMobileCol}>
                <NoMedCardBlock />
              </Col>
            </Layout>
          </Content>
          <FooterRow isShared />
        </>
      ),
    },
    {
      path: '/',
      index: true,
      element: <Navigate to="/onboarding" replace />,
    },
  ],
  { basename: `${process.env.PUBLIC_URL}/` },
);

export const RoutingComponent = () => <RouterProvider router={router} />;

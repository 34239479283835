import { ArrowLeft, CaretLeft, Plus } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

interface BackHeaderProps {
  title?: string;
  addButtonText?: string;
  handleAddClick?: () => void;
  className?: string;
  backFunction?: () => void;
  dataButtonTestId?: string;
}

export const BackHeader = ({
  title,
  addButtonText,
  handleAddClick,
  className,
  backFunction,
  dataButtonTestId,
}: BackHeaderProps) => {
  let navigate = useNavigate();

  const handleGoBack = () => {
    backFunction ? backFunction() : navigate(-1);
  };

  return (
    <Row className={`${styles.TopRow} ${className}`}>
      <ArrowLeft className={styles.BackButtonDesktop} onClick={handleGoBack} />
      <Col className={styles.BackButtonMobileCol} onClick={handleGoBack}>
        <CaretLeft />
      </Col>
      <Typography className={styles.Title}>{title}</Typography>
      {addButtonText && (
        <CustomButton dataTestId={dataButtonTestId} isPrimal onClick={handleAddClick} className={styles.AddNewButton}>
          <Row className={styles.AddButtonRow}>
            <Plus className={styles.PlusIcon} />
            <Typography className={styles.AddNewText}>{addButtonText}</Typography>
          </Row>
        </CustomButton>
      )}
    </Row>
  );
};

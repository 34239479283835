import { Briefcase, CaretDown, DoctorIcon, Star } from 'src/common/icons';
import { Carousel, Col, Collapse, Divider, Image, Row, Typography } from 'antd';
import { ClinicCommonInfo } from 'src/common/types';
import { ClinicContacsBlock } from '../../../common/components/ClinicContactsBlock/ClinicContacsBlock';
import { CreateAppointmentModalContent } from 'src/features/NewAppointment/CreateAppointmentModal/CreateAppointmentModal';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { CustomTag } from 'src/common/components/CustomTag/CustomTag';
import { InvitationModal } from 'src/features/Catalog/InvitationModal/InvitationModal';
import { useNavigate } from 'react-router-dom';
import { useSetTmkChatMutation } from '../../../common/redux/api/apiPatientSlice';
import ClinicStub from 'src/assets/ClinicStub.png';
import EmptyImagesBlock from '../EmptyImagesBlock/EmptyImagesBlock';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
interface ClinicCardProps {
  clinicInfo: ClinicCommonInfo;
  single?: boolean;
  preview?: boolean;
}

export const ClinicCard = ({ clinicInfo, single = false, preview = false }: ClinicCardProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalVisible, setModalVisibility] = useState(false);

  const [setChat, chatInfo] = useSetTmkChatMutation();

  const handleGetTmkChat = useCallback(
    (idMedOrganization: string) => () => {
      setChat(idMedOrganization);
    },
    [setChat],
  );

  useEffect(() => {
    const roomId = chatInfo?.data?.roomId;
    if (roomId) navigate(`/settings/userProfile/chats?room=${roomId}`);
  }, [chatInfo?.data, navigate]);

  const handleOpenModal = () => {
    setModalVisibility(true);
  };

  const handleCloseModal = () => {
    setModalVisibility(false);
  };

  const handleClinicClick = () => {
    navigate(`/catalog/${clinicInfo.idMedOrganization}`);
  };
  const handleDoctorClick = (event: any) => {
    event?.stopPropagation();
    navigate(`/catalog/${clinicInfo.idMedOrganization}?activeTab=doctors`);
  };

  const handleOpenInvitationModal = () => setIsModalOpen(true);
  const handleCloseInvitationModal = () => setIsModalOpen(false);

  const handleRatingClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(`/catalog/${clinicInfo.idMedOrganization}?activeTab=reviews`);
    window.scrollTo({
      top: 600,
      behavior: 'smooth',
    });
  };

  const buttons = useMemo(
    () => (
      <>
        {/*<CustomButton*/}
        {/*  className={`${styles.MobileSignUpButton} ${styles.ChatButton}`}*/}
        {/*  onClick={handleGetTmkChat(clinicInfo.idMedOrganization)}*/}
        {/*>*/}
        {/*  <Typography>Чат с клиникой</Typography>*/}
        {/*</CustomButton>*/}
        <CustomButton isPrimal={true} className={styles.MobileSignUpButton} onClick={handleOpenModal}>
          <Typography>Записаться</Typography>
        </CustomButton>
      </>
    ),
    [clinicInfo.idMedOrganization, handleGetTmkChat],
  );

  const ratingValue = useMemo(() => Math.round(clinicInfo.averageScore * 10) / 10, [clinicInfo.averageScore]);

  const clinicTags = useMemo(
    () => (clinicInfo.servingAges ? [clinicInfo.form, ...clinicInfo.servingAges] : []),
    [clinicInfo.form, clinicInfo.servingAges],
  );

  return clinicInfo.isConnectedToMila ? (
    <Row className={styles.BlockRow}>
      {!preview && clinicInfo?.photoStaticFileIds.length > 0 && (
        <Col className={styles.CarouselCol}>
          <Col onClick={handleClinicClick}>
            <Carousel className={styles.Carousel} dots={{ className: styles.Dots }}>
              {clinicInfo?.photoStaticFileIds?.map((imageId) => (
                <Row key={imageId}>
                  <Image
                    preview={false}
                    className={styles.MobileImage}
                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${imageId}`}
                  />
                  <Image
                    preview={false}
                    className={styles.ClinicLogo}
                    src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${clinicInfo.logoStaticFileId}`}
                  />
                </Row>
              ))}
            </Carousel>
          </Col>
          {single && <Row className={styles.SignUpButtonRowOutside}>{buttons}</Row>}
          {!single && <Row className={styles.SignUpButtonRowInsideDesktop}>{buttons}</Row>}
        </Col>
      )}

      {clinicInfo?.photoStaticFileIds.length === 0 && (
        <EmptyImagesBlock
          logo={
            clinicInfo.logoStaticFileId
              ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${clinicInfo.logoStaticFileId}`
              : ''
          }
        />
      )}

      <Col className={styles.ClinicInfoCol} onClick={handleClinicClick}>
        <Typography className={styles.ClinicName}>{clinicInfo?.shortName}</Typography>
        {!preview ? (
          <>
            <Typography className={styles.ClinicFullName}>{clinicInfo?.fullName}</Typography>
            <Row className={styles.PromoRow} onClick={handleRatingClick}>
              {clinicInfo?.averageScore > 0 && (
                <>
                  <Star />
                  <Typography className={styles.LabelRating}>{ratingValue}</Typography>
                </>
              )}
              {clinicInfo.reviewsCount > 0 && (
                <Typography className={styles.LabelReviews}>{`${clinicInfo.reviewsCount} отзывов`}</Typography>
              )}
              {/*<Link to={`/catalog/${clinicInfo.idMedOrganization}?activeTab=promo`}>
                {clinicInfo.hasPromotions && (
                  <Typography className={styles.LabelPromo}>
                    <Lightning />
                    есть акции
                  </Typography>
                )}
              </Link>*/}
            </Row>

            <Row className={styles.TagRow}>
              {clinicTags?.map((tag) => (
                <CustomTag key={tag}>{tag}</CustomTag>
              ))}
            </Row>
            {single ? (
              <Collapse
                expandIconPosition={'end'}
                defaultActiveKey="1"
                ghost
                className={styles.DocumentsCollapse}
                expandIcon={({ isActive }) =>
                  isActive ? <CaretDown /> : <CaretDown style={{ transform: 'rotate(180deg)' }} />
                }
              >
                {clinicInfo?.address && (
                  <Collapse.Panel className={styles.SubTitle} header="Контакты" key="1">
                    <ClinicContacsBlock
                      clinic={clinicInfo}
                      isFullViewClinicCard
                      className={styles.ContacsBlockWrapper}
                    />
                  </Collapse.Panel>
                )}
              </Collapse>
            ) : (
              <>
                {clinicInfo?.address && <Divider className={styles.Divider} />}
                {clinicInfo?.address && (
                  <ClinicContacsBlock clinic={clinicInfo} isFullViewClinicCard className={styles.ContacsBlockWrapper} />
                )}
                <Divider className={styles.Divider} />
                <Row className={styles.PromoRow}>
                  <Briefcase />
                  <Typography className={styles.ColoredText}>
                    Специализаций: {clinicInfo?.specializationsCount ?? 0}
                  </Typography>
                </Row>
                <Divider className={styles.Divider} />
                <Row className={styles.PromoRow}>
                  <DoctorIcon className={styles.Icon} />
                  <Typography onClick={handleDoctorClick} className={styles.ColoredText}>
                    Врачей: {clinicInfo?.doctorsCount ?? 0}
                  </Typography>
                </Row>
                <Divider className={styles.Divider} />

                <Typography className={styles.GreyText}>
                  {clinicInfo?.onsiteMinCost ? `Прием в клинике от ${clinicInfo?.onsiteMinCost} Р, ` : ''}
                  {clinicInfo?.onlineMinCost ? `онлайн-консультация от ${clinicInfo?.onlineMinCost} Р, ` : ''}вызов на
                  дом по запросу
                </Typography>
              </>
            )}
          </>
        ) : (
          clinicInfo?.address && (
            <ClinicContacsBlock clinic={clinicInfo} isFullViewClinicCard className={styles.ContacsBlockWrapper} />
          )
        )}
      </Col>

      {!single && <Row className={styles.SignUpButtonRowInsideMobile}>{buttons}</Row>}
      <CreateAppointmentModalContent isOpen={isCancelModalVisible} onClose={handleCloseModal} clinic={clinicInfo} />
    </Row>
  ) : (
    <Row className={styles.BlockRow}>
      <Col>
        <Image preview={false} rootClassName={styles.MobileImageStub} src={ClinicStub} />
      </Col>
      <Col className={styles.InfoPlaceholderCol}>
        <Typography className={styles.ClinicName}>{clinicInfo?.shortName}</Typography>
        <Divider className={styles.Divider} />
        <Typography className={styles.GreyText}>
          Данная клиника еще не создала профиль в Mila. Поделитесь с ней приглашением, чтобы ваше взаимодействие стало
          еще удобнее — вы сможете записываться на прием в клинику, получать из нее все медицинские документы, общаться
          с врачами и участвовать в акциях
        </Typography>
        <CustomButton className={styles.MobileSignUpButton} onClick={handleOpenInvitationModal}>
          <Typography>Пригласить клинику</Typography>
        </CustomButton>
      </Col>
      <CustomModal isOpen={isModalOpen} onCancelClick={handleCloseInvitationModal}>
        <InvitationModal />
      </CustomModal>
    </Row>
  );
};

import { Col, Image, Row, Typography } from 'antd';
import { MilaIconMobile } from 'src/common/icons';
import { ReactNode, useMemo, useState } from 'react';
import { useGetWhiteLabelInfoQuery } from '../../redux/api/apiWhiteLabelSlice';
import DOMPurify from 'dompurify';
import styles from './styles.module.scss';

export interface MilaMessageProps {
  title: string | ReactNode;
  text?: string;
  hiddenText?: string | ReactNode;
  className?: string;
  textHtmlElement?: ReactNode;
}

export const MilaMessage = ({ title, text = '', hiddenText, className = '', textHtmlElement }: MilaMessageProps) => {
  const [isShowHiddenText, setShowHiddenText] = useState<boolean>(false);

  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  const handleClickTitle = () => {
    if (hiddenText) setShowHiddenText((prev) => !prev);
  };

  return (
    <Row className={`${className} ${styles.PrepRow}`}>
      <Col>
        {isWhiteLabelEnabled ? (
          <Image
            className={styles.MilaIcon}
            src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idIconFile}`}
            preview={false}
          />
        ) : (
          <MilaIconMobile />
        )}
      </Col>
      <Col className={styles.TextCol}>
        <Row
          className={`${styles.TitleRow} ${hiddenText ? styles.Clickable : ''} ${isShowHiddenText ? styles.Open : ''}`}
          onClick={handleClickTitle}
        >
          <Typography className={styles.Title}>{title}</Typography>
        </Row>

        {!isShowHiddenText
          ? text && (
              <Typography
                className={styles.Text}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(text),
                }}
              />
            )
          : hiddenText && <Typography className={styles.Text}>{hiddenText}</Typography>}
        {textHtmlElement}
      </Col>
    </Row>
  );
};

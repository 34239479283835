import { ReactComponent as Cross } from '../../../../assets/Cross.svg';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { ReactComponent as Docs } from '../../../../assets/Docs.svg';
import { Plus } from 'src/common/icons';
import { Progress, Row, Typography, message } from 'antd';
import KeyCloakService from '../../../../keycloak';
import React, { useMemo, useState } from 'react';
import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios';

import { setUploadedFiles } from 'src/common/redux/fileUploadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';

import { DocumentsState, UploadedFile } from 'src/common/types';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useAppSelector } from '../../../../app/hooks';
import styles from './styles.module.scss';

interface UploadDocumentBtnProps {
  setIsActiveSaveBtn: React.Dispatch<React.SetStateAction<boolean>>;
  normalizeFile?: (
    e: UploadChangeParam<UploadFile<any>>,
  ) => UploadFile<any>[] | (UploadChangeParam<UploadFile<any>> & any[]);
  isRequiredDocumentForm?: boolean;
  saveBtnClass?: string;
  setIsFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FileListItem {
  name: string;
  lastModified: number;

  webkitRelativePath: string;
  size: number;
}

interface FileList {
  length: number;
  item(index: number): FileListItem | null;
  [Symbol.iterator](): IterableIterator<FileListItem>;
}
interface ProgressEvent {
  lengthComputable: boolean;
  loaded: number;
  total: number;
}

const UploadDocumentBtn: React.FC<UploadDocumentBtnProps> = ({
  setIsActiveSaveBtn,
  normalizeFile,
  isRequiredDocumentForm,
  saveBtnClass,
  setIsFileUploaded,
}) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [preUploadedFiles, setPreUploadedFiles] = useState<UploadedFile[]>([]);
  const [showUploadButton, setShowUploadButton] = useState(true);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const { data: profiles } = useGetProfilesQuery();
  const idProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile || '', [profiles]);
  const baseUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_API}/patientaccount`;
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const [fileSelected, setFileSelected] = useState(false);

  const uploadedFiles = useSelector((state: DocumentsState) => state.fileUpload.uploadedFiles);
  const dispatch = useDispatch();

  const handleUploadFile = async (files: FileList) => {
    const formData = new FormData();
    const fileArray = Array.from(files) as File[];
    fileArray.forEach((file: File) => {
      formData.append('files', file);
    });
    const authToken = KeyCloakService(keycloakObj).getAuthToken();
    const config: AxiosRequestConfig<FormData> = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total !== undefined) {
          const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log('Upload progress:', percentComplete);
        } else {
          console.log('Unable to calculate upload progress');
        }
      },

      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      setUploadInProgress(true);
      const response = await axios.post(
        `${baseUrl}/profiles/${idProfile}/documents/attachment/pre-upload`,
        formData,
        config,
      );
      const uploadedFiles = response.data;
      setPreUploadedFiles(uploadedFiles);
      dispatch(setUploadedFiles(response.data));

      message.success('Файл успешно загружен');

      setUploadPercent(100);
    } catch (error) {
      if (error instanceof AxiosError) {
        message.error(`Ошибка загрузки файла: ${error.response?.status}`);
      } else {
        console.error('Unexpected error:', error);
      }
    } finally {
      setUploadInProgress(false);

      setIsActiveSaveBtn(true);
      setFileSelected(false);
      setIsLoadingComplete(true);
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      handleUploadFile(selectedFiles);
      setIsActiveSaveBtn(true);
      setFileSelected(true);
      setShowUploadButton(false);
      setShowProgressBar(true);
      setIsFileUploaded(true);
    } else {
      console.error('Файлы не выбраны');
    }
  };

  const handleCancelFile = (fileName: string) => {
    const updatedFiles = preUploadedFiles.filter((file) => file.fileName !== fileName);

    setPreUploadedFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      setShowUploadButton(true);
      setShowProgressBar(false);
      setIsFileUploaded(false);
    }
  };

  return (
    <Row className={styles.antRow}>
      {showUploadButton && (
        <label htmlFor="fileUpload" className={styles.labelUpload}>
          <CustomButton
            className={`${styles.uploadBtn}`}
            onClick={() => {
              const fileUploadElement = document.getElementById('fileUpload');
              if (fileUploadElement) {
                fileUploadElement.click();
              } else {
                console.error('Элемент с id="fileUpload" не найден');
              }
            }}
          >
            <div className={`${styles.upload_btn}`}>
              <Plus />
              <Typography>Загрузить файлы</Typography>
            </div>
          </CustomButton>

          <input type="file" multiple id="fileUpload" onChange={handleFileChange} style={{ display: 'none' }} />
        </label>
      )}
      {(uploadInProgress || isLoadingComplete) && showProgressBar && (
        <div style={{ width: '100%' }}>
          <div className="progress-container">
            <Progress
              className="custom_progress_bar"
              strokeColor="#A6C763"
              percent={uploadPercent}
              type="line"
              status={uploadPercent === 100 ? 'success' : 'active'}
              showInfo={true}
              size={[346, 46]}
            />
            {isLoadingComplete && (
              <div>
                {uploadedFiles.length > 0 && (
                  <div className={`${styles.filename}`}>
                    {preUploadedFiles.map((file, index) => (
                      <div key={index} className={`${styles.filename2}`}>
                        <div className={`${styles.docNameIcon}`}>
                          <Docs className="documents-icon" />
                          <div style={{ textTransform: 'uppercase', color: 'rgb(127, 73, 222)' }}>{file.fileName}</div>
                        </div>
                        <CustomButton className={`${styles.closeIcon}`} onClick={() => handleCancelFile(file.fileName)}>
                          <Cross />
                        </CustomButton>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Row>
  );
};

export default UploadDocumentBtn;

import { CaretRight, DoctorIcon } from 'src/common/icons';
import { Col, Row, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface DoctorLabelProps {
  name?: string;
  specialty?: string;
  id?: string;
}

export const DoctorLabel = ({ name, specialty, id }: DoctorLabelProps) => (
  <Link
    data-test-id={'doctor-label'}
    to={id ? `/records/aboutDoctor/${id}` : ''}
    className={!id ? styles.DisabledLink : ''}
  >
    <Row className={styles.NameRow}>
      <DoctorIcon className={styles.DocIcon} />
      <Col>
        <Typography>{name}</Typography>
        <Typography className={styles.GreySmallText}>
          {specialty === 'undefined' ? 'Не определено' : specialty}
        </Typography>
      </Col>
      {id && <CaretRight className={styles.DocIcon} />}
    </Row>
  </Link>
);

import React, { useState } from 'react';

import { AGREE_TAB, Agreement, DISAGREE_TAB } from '../Agreement/Agreement';
import { Clinic, GreenCheckIcon, Location, Profile, Research } from 'src/common/icons';
import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { InfoBlock } from '../InfoBlock/InfoBlock';
import { Instruction } from '../Instruction/Instruction';
import { PharmDocumentStatus, SignedDocumentStatus } from '../../../common/types';
import { SignedAgreement } from '../SignedAgreement/SignedAgreement';
import Doc from 'src/assets/Doc.png';
import styles from './styles.module.scss';

interface DocBlockProps {
  id: string;
  dateApproval: string;
  dateCreate: string;
  createdDate: Date;
  name: string;
  type: string;
  doctor?: {
    name: string;
    profession: string;
  };
  clinic: string;
  studyName: string;
  studyDescription: string;
  documents: {
    name: string;
    title: string;
    fileUrl: string;
    optional: boolean;
    waiver: boolean;
    patientSignedDate: Date;
    doctorSignedDate: Date;
  }[];
  backToList: () => void;
  status: SignedDocumentStatus | null;
  docStatus: string;
}

export const SingleDoc = ({
  dateApproval,
  dateCreate,
  name,
  type,
  doctor,
  clinic,
  studyName,
  studyDescription,
  documents,
  createdDate,
  id,
  backToList,
  status,
  docStatus,
}: DocBlockProps) => {
  const [isShowMessage, setIsShowMessage] = useState<boolean>(false);
  const [isShowMoreInfo, setIsShowMoreInfo] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(AGREE_TAB);
  const signAgreement = () => {
    setActiveTab(AGREE_TAB);
    setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 500);
  };

  const signRefused = () => {
    setActiveTab(DISAGREE_TAB);
    setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 500);
  };

  const showDocs = () => {
    setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 500);
  };

  const getStatusText = (status: any) => {
    if (status === SignedDocumentStatus.Signed) {
      return 'Подписано';
    }
    if (status === SignedDocumentStatus.WaitDoctorSign) {
      return 'Ожидается подпись врача';
    }
    if (status === SignedDocumentStatus.Refused) {
      return 'Подписан отказ';
    }
    return '-';
  };

  return (
    <>
      <Row className={styles.Block}>
        <Row className={styles.Top}>
          <Row className={styles.TopData}>
            <Image className={styles.TopPicture} src={Doc} preview={false} />
            <Col className={styles.TopInfo}>
              <Typography.Text className={styles.DateCreate}>
                Дата и время поступления документа: {dateCreate}
              </Typography.Text>
              <Typography.Text className={styles.Name}>{name}</Typography.Text>
            </Col>
          </Row>
          {status === null && dateApproval !== '-' && (
            <Row className={styles.DateApproval}>
              <Typography.Text>Рассмотреть до {dateApproval}</Typography.Text>
            </Row>
          )}
          {status !== null && (
            <Row
              className={`${styles.StatusBlock} ${
                status === SignedDocumentStatus.WaitDoctorSign || status === SignedDocumentStatus.Signed
                  ? styles.StatusBlockActive
                  : status === SignedDocumentStatus.Refused
                  ? styles.StatusBlockUnActive
                  : ''
              }`}
            >
              <Typography.Text>
                {status === SignedDocumentStatus.Signed && <GreenCheckIcon className={styles.StatusBlockIcon} />}
                {getStatusText(status)}
              </Typography.Text>
            </Row>
          )}
        </Row>
        <Row className={styles.Bottom}>
          <Col>
            {doctor && (
              <Row>
                <Profile />
                <Col className={styles.Doctor}>
                  <Typography.Text className={styles.DoctorName}>{doctor.name}</Typography.Text>
                  <Typography.Text className={styles.DoctorProfession}>{doctor.profession}</Typography.Text>
                </Col>
              </Row>
            )}
            <Row className={styles.ClinicBlock}>
              <Location />
              <Col>
                <Typography.Text className={styles.Clinic}>{clinic}</Typography.Text>
              </Col>
            </Row>
            {type?.length > 0 && (
              <Row>
                <Typography.Text className={styles.Type}>{type}</Typography.Text>
              </Row>
            )}
          </Col>
          <Col className={styles.ButtonBlock}>
            {docStatus === PharmDocumentStatus.Active ? (
              <>
                <CustomButton onClick={signAgreement} isPrimal className={styles.Button}>
                  <Typography>Подписать согласие</Typography>
                </CustomButton>
                <CustomButton onClick={signRefused} className={styles.Button}>
                  <Typography>Подписать отказ</Typography>
                </CustomButton>
              </>
            ) : (
              <CustomButton onClick={showDocs} className={styles.Button}>
                <Typography>Показать документы</Typography>
              </CustomButton>
            )}
          </Col>
        </Row>
        <Row className={styles.Message}>
          <Typography.Text
            onClick={() => setIsShowMessage((prev) => !prev)}
            className={`${styles.MessageHeader} ${isShowMessage ? styles.Open : ''}`}
          >
            Сообщение от отправителя
          </Typography.Text>
          <Row className={`${styles.MessageBody} ${isShowMessage ? styles.Open : ''}`}>
            <Typography.Text>Уважаемый пациент!</Typography.Text>
            <Typography.Text>
              Вам направлены данные документы поскольку Вами было дано предварительное устное согласие на участие в
              клиническом исследовании (КИ) вашему врачу. Для подтверждения участия в исследовании требуется Ваше
              добровольное и информированное письменное согласие, подписываемое в электронном виде.
            </Typography.Text>
          </Row>
        </Row>
      </Row>
      <Row className={styles.Block}>
        <div className={styles.Info}>
          <Typography className={styles.InfoMainTitle}>Информация об исследовании</Typography>
          {studyName?.length > 0 && (
            <div className={styles.InfoBlock}>
              <Research />
              <Col className={styles.InfoData}>
                <Typography.Text className={styles.InfoTitle}>{studyName}</Typography.Text>
                <Typography.Text className={styles.InfoText}>{studyDescription}</Typography.Text>
              </Col>
            </div>
          )}
          <div className={styles.InfoBlock}>
            <Clinic />
            <Col className={styles.InfoData}>
              <Typography.Text className={styles.InfoTitle}>
                Организация, уполномоченная к проведению исследования в России:{' '}
              </Typography.Text>
              <Typography.Text className={styles.InfoText}>{clinic}</Typography.Text>
            </Col>
          </div>
          {isShowMoreInfo && (
            <>
              <div className={styles.InfoBlock}>
                <Research />
                <Col className={styles.InfoData}>
                  <Typography.Text className={styles.InfoTitle}>Название исследования</Typography.Text>
                  <Typography.Text className={styles.InfoText}>
                    54-недельное, многоцентровое, рандомизированное, двойное слепое, плацебо-контролируемое исследование
                    II фазы для оценки эффективности и безопасности бразикумаба в параллельных группах участников с
                    активным язвенным колитом средней или тяжелой степени (Expedition Lead-in).
                  </Typography.Text>
                </Col>
              </div>
              <div className={styles.InfoBlock}>
                <Research />
                <Col className={styles.InfoData}>
                  <Typography.Text className={styles.InfoTitle}>Название исследования</Typography.Text>
                  <Typography.Text className={styles.InfoText}>
                    54-недельное, многоцентровое, рандомизированное, двойное слепое, плацебо-контролируемое исследование
                    II фазы для оценки эффективности и безопасности бразикумаба в параллельных группах участников с
                    активным язвенным колитом средней или тяжелой степени (Expedition Lead-in).
                  </Typography.Text>
                </Col>
              </div>
              <div className={styles.InfoBlock}>
                <Research />
                <Col className={styles.InfoData}>
                  <Typography.Text className={styles.InfoTitle}>Название исследования</Typography.Text>
                  <Typography.Text className={styles.InfoText}>
                    54-недельное, многоцентровое, рандомизированное, двойное слепое, плацебо-контролируемое исследование
                    II фазы для оценки эффективности и безопасности бразикумаба в параллельных группах участников с
                    активным язвенным колитом средней или тяжелой степени (Expedition Lead-in).
                  </Typography.Text>
                </Col>
              </div>
            </>
          )}
          {/*<Typography.Text*/}
          {/*  onClick={() => setIsShowMoreInfo((prev) => !prev)}*/}
          {/*  className={`${styles.MoreInfo} ${isShowMoreInfo ? styles.Open : ''}`}*/}
          {/*>*/}
          {/*  Подробнее*/}
          {/*</Typography.Text>*/}
        </div>
      </Row>
      <Instruction />
      <InfoBlock
        title={'Правила подписания документов'}
        text={
          'Процесс подписания документов соответствует пункту 36 статьи 1 Федерального закона от 30.01.2024 № 1-ФЗ в части получения информированного добровольного согласия пациента на участие в клиническом исследовании лекарственного препарата для медицинского применения посредством УКЭП или простой электронной подписи (ПЭП) с применением единой системы идентификации и аутентификации пациента или его законного представителя на информационном листке пациента, сформированном в форме электронного документа.'
        }
      />
      {docStatus === PharmDocumentStatus.Active ? (
        <Agreement
          changedActiveTab={activeTab}
          backToList={backToList}
          createdDate={createdDate}
          documents={documents}
          id={id}
        />
      ) : (
        <SignedAgreement backToList={backToList} createdDate={createdDate} documents={documents} id={id} />
      )}
    </>
  );
};

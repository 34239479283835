import { Analysis } from './Analysis/Analysis';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { Col, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { DocumentDownload, Export, Info, Refresh } from 'src/common/icons';
import { GosUslugiBlock } from '../../common/components/GosUslugiBlock/GosUslugiBlock';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MedCardDocuments } from './MedCardDocuments/MedCardDocuments';
import { ProfilesSlider } from 'src/common/components/ProfilesSlider/ProfilesSlider';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { handleAddEsiaAccount } from '../../common/utils';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetConsentsQuery,
  useGetMedcardCalendarCasesQuery,
  useGetMedcardCalendarQuery,
  useGetProfileDocumentsQuery,
  useGetProfileRecommedationsQuery,
  useGetProfilesQuery,
  useJoinEsiaAccountMutation, useRemoveMedcardDocumentMutation,
} from '../../common/redux/api/apiPatientSlice';

import {
  CalendarRecord,
  MedcardCalendarMonthRecord,
  MedcardCalendarYearRecord,
  MedcardDocument,
} from 'src/common/types';
import { CloseOutlined } from '@ant-design/icons';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomModal } from 'src/common/components/CustomModal/CustomModal';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { GosUslugiDesktopBlock } from './GosUslugiDesktopBlock/GosUslugiDesktopBlock';
import { InfoModal } from './InfoModal/InfoModal';
import { MedcardDocumentLoadForm } from './MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import { ShareModal } from './ShareModal/ShareModal';
import { idProcessPersonalDataAgreementConsent75 } from 'src/common/contants/constatns';
import {
  streamMedCardApi,
  streamMedcardCalendarCasesApi,
  streamProfileDocumentsApi,
} from 'src/common/redux/api/apiStreamSlice';
import { useAppSelector } from '../../app/hooks';
import { useGetWhiteLabelInfoQuery } from '../../common/redux/api/apiWhiteLabelSlice';
import EsiaLoginService from '../../common/services/login/Esia/esia-login-service';
import ZBMedCardIcon from 'src/assets/ZBMedCardIcon.svg';
import styles from './styles.module.scss';

const DOCUMENTS_TAB = '1';
const CALENDAR_TAB = '2';

const items = [
  { label: `Документы`, key: DOCUMENTS_TAB },
  { label: `Календарь обращений`, key: CALENDAR_TAB },
];

export const MedCard = () => {
  const { profileId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(DOCUMENTS_TAB);
  const [shareOpen, setShareOpen] = useState(false);
  const [isDocumentOpen, setDocumentOpen] = useState(false);
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [isBannerVisible, setBannerVisible] = useState<boolean>(true);

  const { data: profiles, refetch: refetchProfiles } = useGetProfilesQuery();
  const [joinEsiaAccount] = useJoinEsiaAccountMutation();
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);

  const [params] = useSearchParams();
  const medcardUrlTab = useMemo(() => params.get('activeTab'), [params]);

  const isEsiaConnectedProfile = useMemo(
    () => profiles?.find((profile) => profile.idProfile === profileId)?.isConfirmed,
    [profiles, profileId],
  );
  const isMainProfile = useMemo(
    () => profiles?.find((profile) => profile.itSelf)?.idProfile === profileId,
    [profiles, profileId],
  );

  const { data: crId, isFetching: calendarFetching } = useGetMedcardCalendarQuery(profileId!, {
    skip: !profileId,
    refetchOnMountOrArgChange: true,
  });
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const [newCalendarData, setNewCalendarData] = useState<Array<MedcardCalendarYearRecord>>();
  const [calendarData, setCalendarData] = useState<Array<MedcardCalendarYearRecord>>();

  const [processCalendarMessage] = streamMedCardApi((item) => {
    // join data for the same CrId!!
    setNewCalendarData(item);
  });

  const { data: consents } = useGetConsentsQuery(profileId!, {
    skip: !profileId,
  });

  const showZabaykalskyBanner = useMemo(() => {
    const consent = consents?.find((consent) => consent.idMilaConsent === idProcessPersonalDataAgreementConsent75);
    return consent?.isActive === false;
  }, [consents]);

  useEffect(() => {
    const newData = calendarData ? [...calendarData] : [];
    newCalendarData?.forEach((newItem) => {
      const idx = newData.findIndex((x) => x.year === newItem.year);
      if (idx !== -1) {
        const appointments = [...newData[idx].appointments, ...newItem.appointments];
        const newAppointments: MedcardCalendarMonthRecord[] = [];
        appointments.forEach((appointment) => {
          if (!newAppointments.find((x) => x.month === appointment.month)) {
            const merged = [...appointments.filter((a) => a.month === appointment.month)].reduce(
              (prev, curr) => {
                prev.onSiteCount += curr.onSiteCount;
                prev.tmkCount += curr.tmkCount;
                prev.atHomeCount += curr.atHomeCount;
                prev.hospitalCount += curr.hospitalCount;
                prev.dispensaryCount += curr.dispensaryCount;
                return prev;
              },
              {
                month: appointment.month,
                onSiteCount: 0,
                tmkCount: 0,
                atHomeCount: 0,
                hospitalCount: 0,
                dispensaryCount: 0,
              },
            );
            newAppointments.push(merged);
          }
        });
        newData[idx].appointments = newAppointments;
      } else {
        newData.push({ ...newItem });
      }
    });
    setCalendarData(newData);
  }, [newCalendarData]);

  useEffect(() => {
    setCalendarData(undefined);
    processCalendarMessage(crId);
  }, [crId]);

  const [calendarCases, setCalendarCases] = useState<CalendarRecord[]>();
  const [newCalendarCases, setNewCalendarCases] = useState<CalendarRecord[]>();
  const { data: casesCrId, isFetching: calendarCasesFetching } = useGetMedcardCalendarCasesQuery(profileId!, {
    skip: !profileId,
    refetchOnMountOrArgChange: true,
  });

  const [processCalendarCasesMessage] = streamMedcardCalendarCasesApi((item) => {
    // join data for the same CrId!!
    setNewCalendarCases(item);
  });

  useEffect(() => {
    if (newCalendarCases) {
      const newData = calendarCases ? [...calendarCases, ...newCalendarCases] : [...newCalendarCases];
      setCalendarCases(newData);
    }
  }, [newCalendarCases]);

  useEffect(() => {
    setCalendarCases(undefined);
    processCalendarCasesMessage(casesCrId);
  }, [casesCrId]);

  const { data: recommendations, isFetching: recommendationsFetching } = useGetProfileRecommedationsQuery(profileId!, {
    skip: !profileId,
  });

  const { data: documentsCrId, isFetching: documentsFetching } = useGetProfileDocumentsQuery(profileId!, {
    skip: !profileId,
    refetchOnMountOrArgChange: true,
  });

  const [documents, setDocuments] = useState<MedcardDocument[]>();
  const [newDocuments, setNewDocuments] = useState<MedcardDocument[]>();

  const removeDocument = (document: MedcardDocument) => {
    setDocuments(prev => prev ? [...prev.filter(x => x.idMilaUserDocument !== document.idMilaUserDocument)] : []);
  };

  const [processDocumentsMessage] = streamProfileDocumentsApi((item) => {
    setNewDocuments(item);
  });

  useEffect(() => {
    if (newDocuments && Array.isArray(newDocuments)) {
      const newData = documents ? [...documents, ...newDocuments] : [...newDocuments];
      setDocuments(newData);
    }
  }, [newDocuments]);

  useEffect(() => {
    setDocuments([]);
    processDocumentsMessage(documentsCrId);
  }, [documentsCrId]);

  useEffect(() => {
    if (!profileId && profiles) {
      const myProfile = profiles.find((profile) => profile.itSelf)?.idProfile;
      navigate(`/medcards/${myProfile}`);
    }
  }, [navigate, profileId, profiles]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleNextProfileClick = (id: string) => {
    medcardUrlTab ? navigate(`/medcards/${id}?activeTab=${medcardUrlTab}`) : navigate(`/medcards/${id}`);
  };

  useEffect(() => {
    if (medcardUrlTab === 'calendar') {
      document.getElementById('RecommendationsBlock')?.scrollIntoView({ behavior: 'smooth' });
    } else if (medcardUrlTab === 'documents') {
      setActiveTab(DOCUMENTS_TAB);
    } else {
      return;
    }
  }, [medcardUrlTab]);

  const connectEsiaProfile = () => {
    EsiaLoginService.Login().then((accessToken) => {
      joinEsiaAccount({ accessToken, idProfile: profileId! })
        .unwrap()
        .then(() => {
          notification.success({ message: 'Профиль успешно подключен к Госуслугам' });
          refetchProfiles();
        })
        .catch(() => notification.error({ message: 'Ошибка подключения профиля к Госуслугам' }));
    });
  };

  const handleDocumentOpen = () => setDocumentOpen(true);
  const handleDocumentClose = () => setDocumentOpen(false);

  const handleInfoOpen = () => setInfoOpen(true);
  const handleInfoClose = () => setInfoOpen(false);

  return (
    <>
      <BackHeader title="Медкарты" />
      <ProfilesSlider onNext={handleNextProfileClick} />
      <WhiteRow className={styles.ButtonsRow}>
        <Col className={styles.ButtonCol}>
          <CustomButton className={styles.Button} onClick={() => setShareOpen(true)}>
            <Export />
          </CustomButton>
          <Typography className={styles.GreySmallText} onClick={() => setShareOpen(true)}>
            Поделиться медкартой
          </Typography>
        </Col>
        <Col className={styles.ButtonCol} onClick={handleDocumentOpen}>
          <CustomButton className={styles.Button}>
            <DocumentDownload />
          </CustomButton>
          <Typography className={styles.GreySmallText} onClick={handleDocumentOpen}>
            Загрузить документы
          </Typography>
        </Col>
        <Col className={styles.ButtonCol}>
          <Link className={styles.LinkButton} to={`/consent/?profileId=${profileId}`}>
            <CustomButton className={styles.Button}>
              <Refresh />
            </CustomButton>
            <Typography className={styles.GreySmallText}>Доступ к данным</Typography>
          </Link>
        </Col>
        <Col className={styles.ButtonCol} onClick={handleInfoOpen}>
          <CustomButton className={styles.Button}>
            <Info />
          </CustomButton>
          <Typography className={styles.GreySmallText} onClick={handleInfoOpen}>
            Информация о сервисе
          </Typography>
        </Col>
      </WhiteRow>

      {isBannerVisible && showZabaykalskyBanner && (
        <div className={styles.zbBanner}>
          <CloseOutlined className={styles.closeIcon} onClick={() => setBannerVisible(false)} />
          <div className={styles.backgroundLayer}></div>

          <div className={styles.content}>
            <div className={styles.mobileHeader}>
              <img src={ZBMedCardIcon} alt="" className={styles.icon} />
              <h2 className={styles.mobileVersionTitle}>Данные из государственных медучреждений</h2>
            </div>

            <div className={styles.textBlock}>
              <h2 className={styles.desktopVersionTitle}>Данные из государственных медучреждений</h2>
              <p>Забайкальский край: подпишите согласие и получайте электронные медицинские документы</p>
              <div className={styles.disclaimer}>* Только для авторизованных через Госуслуги пользователей</div>
            </div>

            <CustomButton onClick={() => navigate(`/consent`)} className={styles.zbButton}>
              <span>Подписать согласие</span>
            </CustomButton>
          </div>
        </div>
      )}

      {!isEsiaConnectedProfile &&
        (isMainProfile ? (
          <GosUslugiBlock
            buttonText="Подключить Госуслуги"
            smallText={
              <>
                Привяжите свой профиль с Госуслуг, чтобы получать больше данных. Вы также сможете входить
                {!whiteLabel?.isServiceEnabled ? ' в Mila' : ''} через Госуслуги
              </>
            }
            title="Получайте больше данных"
            buttonHandler={() => handleAddEsiaAccount(keycloakObj)}
          />
        ) : (
          <>
            <GosUslugiBlock
              buttonText="Подтвердить через Госуслуги"
              smallText={
                <>
                  Чтобы автоматически получать данные из клиник для этого профиля войдите в его аккаунт на Госуслугах.
                  Так мы будем уверены, что вы имеете право на доступ к медкарте этого пользователя
                </>
              }
              title="Подтвердите учетную запись"
              buttonHandler={connectEsiaProfile}
            />
            <GosUslugiDesktopBlock buttonHandler={connectEsiaProfile} />
          </>
        ))}
      <CustomCardTabs items={items} onChange={handleTabChange} className={styles.TabsLabels} activeKey={activeTab} />
      {activeTab === CALENDAR_TAB && (
        <Analysis
          calendarCases={calendarCases}
          calendarCasesFetching={calendarCasesFetching}
          calendarData={calendarData}
          calendarFetching={calendarFetching}
          recommendations={recommendations}
          recommendationsFetching={recommendationsFetching}
        />
      )}
      {activeTab === DOCUMENTS_TAB &&
        profileId &&
        (documentsFetching ? (
          <Row className={styles.LoaderRow}>
            <CustomSpinner />
            <Typography className={styles.LoaderTitle}>Собираю данные</Typography>
            <Typography className={styles.LoaderText}>Это может занять какое-то время</Typography>
          </Row>
        ) : (
          <MedCardDocuments showFilters documents={documents} removeDocument={removeDocument}/>
        ))}
      {profileId && (
        <ShareModal idProfile={profileId!} isModalOpen={shareOpen} onCancelClick={() => setShareOpen(false)} />
      )}
      <CustomModal className="UploadDocWide" isOpen={isDocumentOpen} onCancelClick={handleDocumentClose}>
        <MedcardDocumentLoadForm onFinish={handleDocumentClose} />
      </CustomModal>

      <CustomModal isOpen={isInfoOpen} onCancelClick={handleInfoClose}>
        <InfoModal />
      </CustomModal>
    </>
  );
};

import { ABOUT_MILA_CLINIC_LINK, MY_MILA_ONLINE_LINK } from '../../../../common/contants/constatns';
import { Col, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { MedcardDoctorIcon, MedcardIcon, MedcardUnlock } from 'src/common/icons';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { handlePatientsClick } from '../../../../common/utils';
import { useGetWhiteLabelInfoQuery } from '../../../../common/redux/api/apiWhiteLabelSlice';
import { useState } from 'react';
import YandexShare from 'src/common/components/YandexShare/YandexShare';
// eslint-disable-next-line css-modules/no-unused-class
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const NoMedCardBlock = () => {
  const [isYandexShareOpen, setIsYandexShareOpen] = useState(false);
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const handleAboutMilaClinic = () => {
    window.open(ABOUT_MILA_CLINIC_LINK, '_blank', 'noopener,noreferrer');
  };

  const handleYandexShareClick = () => setIsYandexShareOpen(!isYandexShareOpen);

  return (
    <WhiteRow className={styles.NoMedcardRow}>
      <Col className={styles.NoMedcardCol}>
        <MedcardIcon />
        <Typography.Title className={styles.Title} level={3}>
          У вас еще нет медкарты{!whiteLabel?.isServiceEnabled ? ' в Mila' : ''}?
        </Typography.Title>
        <Typography className={styles.GreySmallText}>
          Более 1000 медицинских организаций уже подключены к платформе. Наверняка, среди них есть и ваши клиники. Ваша
          самая полная электронная медкарта{!whiteLabel?.isServiceEnabled ? ' - в Mila' : ''}.
        </Typography>
        <Typography className={styles.GreySmallText}>Зарегистрируйтесь, чтобы начать получать данные</Typography>
        <Link to={MY_MILA_ONLINE_LINK}>
          <CustomButton className={styles.Button}>
            <Typography>Зарегистрироваться</Typography>
          </CustomButton>
        </Link>
        {!whiteLabel?.isServiceEnabled && (
          <Typography.Link className={styles.Link} onClick={handlePatientsClick}>
            Подробнее о Mila
          </Typography.Link>
        )}
      </Col>

      {!whiteLabel?.isServiceEnabled && (
        <Col className={styles.NoMedcardCol}>
          <MedcardDoctorIcon />
          <Typography.Title className={styles.Title} level={3}>
            Вы работаете в клинике?
          </Typography.Title>
          <Typography className={styles.GreySmallText}>
            Подключите Mila - новый облачный сервис для заботы о пациентах - без вложений в разработку
          </Typography>
          <Typography className={styles.Promocode}>Промокод MILAPLUS </Typography>
          <Typography.Title className={styles.Title} level={3}>
            Скидка 50% на подключение по коду
          </Typography.Title>
          <CustomButton className={styles.Button} onClick={handleYandexShareClick}>
            <Typography>Поделиться кодом</Typography>
          </CustomButton>
          {isYandexShareOpen && (
            <YandexShare
              className={styles.Share}
              text="Здравствуйте! Прошу обеспечить доступ к моей медицинской карте в электронном виде в цифровом ассистенте пациента MILA. Для передачи данных рекомендую использовать платформу www.n3health.ru. По коду MILAPLUS сейчас действует скидка 50% на подключение."
            />
          )}
          <Typography.Link className={styles.Link} onClick={handleAboutMilaClinic}>
            Подробнее о Mila для клиник
          </Typography.Link>
        </Col>
      )}

      <Col className={styles.NoMedcardCol}>
        <MedcardUnlock />
        <Typography className={styles.GreySmallText}>
          Пользователь сервиса{!whiteLabel?.isServiceEnabled ? ' Mila' : ''} предоставил Вам доступ к просмотру своей
          медкарты. Содержащиеся в ней сведения строго конфиденциальны и охраняются согласно Федеральному закону от
          27.07.2006 № 152-ФЗ «О персональных данных». Запрещено любое копирование и передача персональных данных без
          согласия пользователя.
        </Typography>
      </Col>
    </WhiteRow>
  );
};

import { Card, Image } from 'antd';
import styles from './styles.module.scss';

type EmptyImagesBlockProps = {
  classes?: string;
  logo: string;
};

const EmptyImagesBlock = ({ classes, logo }: EmptyImagesBlockProps) => {
  return (
    <Card className={`${styles.EmptyImagesBlock} ${logo === '' ? styles.EmptyImagesBlockWithouthLogo : ''}`}>
      {logo && (
        <p className={styles.EmptyImagesBlockLogoWrapper}>
          <Image preview={false} className={`${styles.EmptyImagesBlockLogo} ${classes}`} src={logo} />
        </p>
      )}
    </Card>
  );
};

export default EmptyImagesBlock;

import { Avatar } from 'src/common/components/Avatar/Avatar';
import { CaretRight, Lightning, Star } from 'src/common/icons';
import { Col, Layout, Row, Typography } from 'antd';
import { CustomTag } from 'src/common/components/CustomTag/CustomTag';
import { Doctor } from 'src/common/types';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/app/hooks';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

interface DoctorSummaryProps {
  doctor: Doctor;
  className?: string;
  hideAvatar?: boolean;
  preselectedBranchId?: string;
}

export const DoctorSummary = ({ doctor, className, hideAvatar = false, preselectedBranchId }: DoctorSummaryProps) => {
  const specialtyId = useAppSelector((state) => state.appointment.specialtyId);

  const [academicDegreeName, setAcademicDegreeName] = useState<string>('');
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    let degreeName = doctor?.academicDegree && doctor.academicDegreeShortName;

    if (degreeName.length > 0) {
      degreeName = `${degreeName},`;
    }

    setAcademicDegreeName(degreeName);
  }, [doctor?.academicDegree, doctor.academicDegreeShortName]);

  useEffect(() => {
    let categoryName = doctor?.category;

    if (categoryName) {
      categoryName = `${categoryName}.`;
    }

    setCategoryName(categoryName);
  }, [doctor?.category]);

  return (
    <Layout className={`${styles.DocInfo} ${className}`}>
      <Row className={styles.DocRow}>
        {!hideAvatar && (
          <Col className={styles.AvatarCol}>
            <Avatar
              idProfile={doctor.idMilaDoctor}
              className={styles.DocAvatar}
              idPhoto={doctor.photoId}
              name={doctor.fio}
              classNameText={styles.DoctorInitials}
              isDoctor
            />
          </Col>
        )}
        <Col className={styles.NameCol}>
          <Link
            onClick={(e) => !doctor.hasMilaProfile && e.preventDefault()}
            style={!doctor.hasMilaProfile ? { cursor: 'default' } : undefined}
            className={styles.FullName}
            to={`/records/aboutDoctor/${doctor.idMilaDoctor}${
              preselectedBranchId ? `?branchId=${preselectedBranchId}` : ''
            }`}
          >
            {doctor.fio} {!hideAvatar ? <CaretRight /> : ''}
          </Link>
          {doctor.hasMilaProfile && (
            <>
              <Typography className={styles.ExperienceText}>
                {`${academicDegreeName} ${categoryName} ${doctor?.experience}`}
              </Typography>
              <Row className={styles.PromoRow}>
                <Link to={`/records/aboutDoctor/${doctor.idMilaDoctor}?activeTab=reviews`} className={styles.Link}>
                  {doctor.reviewsRating && (
                    <>
                      <Star />
                      <Typography className={styles.LabelRating}>
                        {doctor.reviewsRating ? doctor.reviewsRating.toFixed(2) : 0}
                      </Typography>
                    </>
                  )}
                  {doctor.reviewsCount !== 0 && (
                    <Typography className={styles.LabelReviews}>{doctor.reviewsCount} отзывов</Typography>
                  )}
                </Link>

                <Link to={`/records/aboutDoctor/${doctor.idMilaDoctor}?activeTab=promo`} className={styles.Link}>
                  {doctor.hasPromotions && (
                    <Typography className={styles.LabelPromo}>
                      <Lightning />
                      есть акции
                    </Typography>
                  )}
                </Link>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <Row className={styles.TagRowContainer}>
        <Row className={styles.TagRow}>
          {doctor.specialitiesTags?.length &&
            doctor.specialitiesTags.length > 0 &&
            doctor.specialitiesTags.map((specialization) => (
              <CustomTag
                key={specialization?.idFerSpeciality}
                selected={specialization?.idFerSpeciality === specialtyId}
              >
                {specialization.specialityName}
              </CustomTag>
            ))}
        </Row>
        <Row className={styles.TagRow}>
          {doctor.specialitiesTags?.length &&
            doctor.specialitiesTags.length > 0 &&
            doctor.specialitiesTags.map((specialization) => {
              if (specialization?.idFerSpeciality === specialtyId) {
                let ageTags = [];
                if (specialization.serveAdults) {
                  ageTags.push(<CustomTag key={specialization?.idFerSpeciality}>Взрослые</CustomTag>);
                }
                if (specialization.serveChildren) {
                  ageTags.push(
                    <CustomTag key={`${specialization?.idFerSpeciality} ${specialization.serveChildren}`}>
                      Дети
                    </CustomTag>,
                  );
                }
                return ageTags;
              }
              return null;
            })}
        </Row>
      </Row>
    </Layout>
  );
};

import { PointsCard } from '../PointsCard';
import { Row } from 'antd';
import ExpiredIcon from '../../../assets/ExpiredIcon.svg';
import PointsGroup from '../../../assets/PointsGroup.svg';

export const PointsContainer = () => (
  <Row gutter={[16, 16]}>
    <PointsCard title="Доступно для списания" value="4000" icon={PointsGroup} />

    <PointsCard title="В обработке" value="2000" icon={PointsGroup} isDisabled />

    <PointsCard title="Сгорит" value="1200" icon={ExpiredIcon} subTitle="16 фев. 2025 г." />
  </Row>
);

import { Button, Checkbox, Col, Form, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { DocumentsItem, RemoveDocumentIcon } from 'src/common/icons';
import { useForm } from 'antd/es/form/Form';
import { AppointmentType, DictionaryType, DocumentType, DocumentUploadedByWhomType } from 'src/common/types/enums';
import {
  CalendarRecord,
  DictionaryItem,
  DocumentsState,
  MedcardDocument,
  MedcardDocumentAttachment,
} from '../../../common/types';
import { CustomAutocompleteSearch } from 'src/common/components/CustomAutocompleteSearch/CustomAutocompleteSearch';
import { CustomDatePicker } from '../../../common/components/CustomDatePicker/CustomDatePicker';
import { CustomInput } from '../../../common/components/CustomInput/CustomInput';
import { CustomSelector } from '../../../common/components/CustomSelector/CustomSelector';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { getProfileNameLabel, normalizeFile, sortProfilesByItself } from '../../../common/utils';
import { useDebounce } from 'src/common/hooks/useDebounce';
import {
  useDeleteAttachmentFileMutation,
  useGetProfilesQuery,
  useUpdateMedcardDocumentMutation,
  useUpdateMedcardExternalDocumentMutation,
  useUploadMedcardDocumentMutation,
} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetFerSpecialitiesQuery,
  useLazySearchMKB10Query,
  useLazySearchQuery,
} from 'src/common/redux/api/apiDictionarySlice';

import DocumentUploadTabs from './DocumentUploadTabs/DocumentUploadTabs';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

import { useSelector } from 'react-redux';
import UpdateUploadBtn from './UploadDocumentBtn/UpdateUploadBtn';
import { DocumentViewerModal } from 'src/common/components/DocumentViewerModal/DocumentViewerModal';

interface DocumentLoadFormProps {
  document?: MedcardDocument;
  onFinish?: () => void;
  appointment?: Partial<CalendarRecord>;
  isAppointmentForm?: boolean;
  isUpcomingAppointment?: boolean;
  idMedcardProfile?: string;
  idShared?: string;
  isFromNotification?: boolean;
}
interface Document {
  idMilaAppointment: string;
  idExternalAppointment: number;
  documentName: string;
  documentType: DocumentType;
  appointmentType: AppointmentType;
  documentDate: string;
  medOrganizationName: string;
  doctorFullName: string;
  doctorSpecialtyName: string;
  diagnosisCode: string;
  serviceCode: string;
  isPublic: boolean;
  uploadedByWhomType?: DocumentUploadedByWhomType;
}

interface Payload {
  idProfile: string;
  idDocument: string;
  documentType: DocumentType;
  documentName: string;
  documentDate: string;
  appointmentType: AppointmentType;
  medOrganizationName: string;
  doctorFullName: string;
  doctorSpecialtyName: string;
  diagnosisCode: string;
  serviceCode: string;
  isPublic: boolean;
  upload: [];
}
interface FileData {
  idProfile: string;
  documentName: string;
  documentType: DocumentType;
  documentDate: string;
  appointmentType: AppointmentType;
}
interface FormData {
  idMilaAppointment: string;
  idExternalAppointment: number;
  idProfile: string;
  documentType: DocumentType;
  documentName: string;
  documentDate: string;
  appointmentType: AppointmentType;
  medOrganizationName: string;
  doctorFullName: string;
  doctorSpecialtyName: string;
  diagnosisCode: string;
  diagnosisCodeValue: string;
  serviceCode: string;
  isPublic: boolean;
}

const documentTypeOptions = [
  {
    value: DocumentType.MedicalReport as unknown as string,
    label: 'Заключение',
  },
  {
    value: DocumentType.Analysis as unknown as string,
    label: 'Анализ',
  },
  {
    value: DocumentType.Examination as unknown as string,
    label: 'Исследование',
  },
  {
    value: DocumentType.Recipe as unknown as string,
    label: 'Рецепт',
  },
  {
    value: DocumentType.Vaccination as unknown as string,
    label: 'Вакцинация',
  },
  {
    value: DocumentType.Genetic as unknown as string,
    label: 'Генетика',
  },
  {
    value: DocumentType.Other as unknown as string,
    label: 'Другое',
  },
];

const appointmentTypeOptions = [
  {
    value: AppointmentType.Ambulatory as unknown as string,
    label: 'Амбулаторный приём',
  },
  {
    value: AppointmentType.Online as unknown as string,
    label: 'Онлайн-консультация',
  },
  {
    value: AppointmentType.AtHome as unknown as string,
    label: 'Вызов на дом',
  },
  {
    value: AppointmentType.Stationary as unknown as string,
    label: 'Стационарное лечение',
  },
  {
    value: AppointmentType.Dispensary as unknown as string,
    label: 'Плановая диспансеризация',
  },
];

export const MedcardDocumentLoadForm = ({
  document,
  onFinish,
  appointment,
  isAppointmentForm = false,
  isUpcomingAppointment = false,
  idMedcardProfile = '',
  idShared,
  isFromNotification = false,
}: DocumentLoadFormProps) => {
  const [form] = useForm();
  const [mkb10SearchQuery, setMkb10SearchQuery] = useState<string>();
  const [serviceSearchQuery, setServiceSearchQuery] = useState<string>();
  const { data: profiles } = useGetProfilesQuery();
  const { data: specialities } = useGetFerSpecialitiesQuery();
  const [isDocumentLoading, setDocumentLoading] = useState<boolean>(false);
  const [isActiveSaveBtn, setIsActiveSaveBtn] = useState<boolean>(false);
  const [activeDocTab, setActiveDocTab] = useState<'oneDoc' | 'separateDocs'>('oneDoc');
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);

  const [searchMkb10, searchMkb10Results] = useLazySearchMKB10Query();
  const [searchService, searchServiceResults] = useLazySearchQuery();

  const [uploadDocuments] = useUploadMedcardDocumentMutation();
  const [update] = useUpdateMedcardDocumentMutation();
  const [updateExternalDocument] = useUpdateMedcardExternalDocumentMutation();

  const [deleteFile, deleteMutation] = useDeleteAttachmentFileMutation();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [diagnosisCode, setDiagnosisCode] = useState<string | null>(null);

  const debouncedMkm10Search = useDebounce(mkb10SearchQuery || '', 300);
  const debouncedServicesSearch = useDebounce(serviceSearchQuery || '', 300);
  const uploadedFiles = useSelector((state: DocumentsState) => state.fileUpload.uploadedFiles);

  useEffect(() => {
    if (debouncedMkm10Search && debouncedMkm10Search.length > 2) searchMkb10(debouncedMkm10Search);
  }, [debouncedMkm10Search, searchMkb10]);

  useEffect(() => {
    if (debouncedServicesSearch && debouncedServicesSearch.length > 2)
      searchService({ query: debouncedServicesSearch, type: DictionaryType.Services });
  }, [debouncedServicesSearch, searchService]);

  useEffect(() => {
    if (idMedcardProfile) {
      form.setFieldValue('idProfile', idMedcardProfile);
      setIsActiveSaveBtn(true);
    }
  }, [form, idMedcardProfile]);

  useEffect(() => {
    if (appointment) {
      form.setFieldsValue({
        idProfile: appointment?.patient?.idPatient,
        documentDate: dayjs(appointment?.created),
        medOrganizationName: appointment?.medOrganization?.shortName,
        appointmentType: appointment?.serviceDeliveryType,
        doctorSpecialtyName: appointment?.doctor?.specializationName,
        doctorFullName: appointment?.doctor?.fullName,
      });
      setIsActiveSaveBtn(false);
    }
    if (document) {
      form.setFieldsValue({
        ...document,
        documentDate: dayjs(document.documentDate),
        diagnosisCodeValue:
          document.diagnosisMkb10Code || document.diagnosisName
            ? `${document.diagnosisMkb10Code ? document.diagnosisMkb10Code : ''} ${
                document.diagnosisName ? document.diagnosisName : ''
              }`
            : null,
        idProfile: document.profileId,
      });
      setDiagnosisCode(document.diagnosisMkb10Code);
      setIsActiveSaveBtn(false);
    }
    return () => {
      form.resetFields();
    };
  }, [appointment, document, form]);

  const mkb10SearchOptions = useMemo(
    () =>
      searchMkb10Results?.currentData?.map(({ code, name }: DictionaryItem) => ({
        key: code,
        value: `(${code}) ${name}`,
        label: `(${code}) ${name}`,
      })),
    [searchMkb10Results],
  );

  const seriveSearchOptions = useMemo(
    () =>
      searchServiceResults?.currentData?.map(({ code, name }: DictionaryItem) => ({
        key: code,
        value: name,
        label: name,
      })),
    [searchServiceResults],
  );

  const specialitiesOptions = useMemo(
    () =>
      specialities?.map(({ code, name }: DictionaryItem) => ({
        key: code,
        value: `(${code}) ${name}`,
        label: `(${code}) ${name}`,
      })),
    [specialities],
  );

  const selectProfileOptions = useMemo(
    () =>
      profiles
        ? sortProfilesByItself(profiles).map((profile) => ({
            value: profile.idProfile,
            label: getProfileNameLabel(profile),
          }))
        : [],
    [profiles],
  );

  const handleSelectValueMkb10 = (id: string, option: { value: string; label: string; key: string }) => {
    form.setFieldValue('diagnosisCodeValue', option.value);
    setDiagnosisCode(option.key);
    setIsActiveSaveBtn(true);
  };

  const handleChangeValueMkb10 = (e: ChangeEvent<HTMLInputElement>) => {
    setMkb10SearchQuery(e.target.value);
  };

  const handleSelectValueService = (id: string, option: { value: string; label: string; key: string }) => {
    form.setFieldValue('serviceCode', option.value);
    setIsActiveSaveBtn(true);
  };

  const handleChangeValueService = (e: ChangeEvent<HTMLInputElement>) => {
    setServiceSearchQuery(e.target.value);
  };

  const handleSelectValueSpecialty = (id: string, option: { value: string; label: string; key: string }) => {
    form.setFieldValue('doctorSpecialtyName', option.value);
    setIsActiveSaveBtn(true);
  };

  const handleSubmit = async () => {
    setDocumentLoading(true);
    const formData = form.getFieldsValue();

    if (activeDocTab === 'separateDocs') {
      try {
        if (uploadedFiles && uploadedFiles.length > 0) {
          for (const uploadedFile of uploadedFiles) {
            const fileData: FileData = {
              ...formData,
              documentName: uploadedFile.fileName,
              documentType: '99',
              documentDate: dayjs().format('YYYY-MM-DD'),
              appointmentType: '0',
            };
            const document: Document = {
              idMilaAppointment: '',
              idExternalAppointment: 0,
              documentName: fileData.documentName,
              documentType: fileData.documentType,
              appointmentType: fileData.appointmentType,
              documentDate: fileData.documentDate,
              medOrganizationName: '',
              doctorFullName: '',
              doctorSpecialtyName: '',
              diagnosisCode: '',
              serviceCode: '',
              isPublic: false,
            };

            try {
              await uploadDocuments({
                idProfile: fileData.idProfile,
                document: document,

                preUploadedFiles: [uploadedFile.fileId],
              }).unwrap();

              notification.success({ message: 'Документ успешно загружен' });
            } catch (err) {
              notification.error({ message: 'Ошибка загрузки документа' });
            }
          }
        } else {
          console.log('Нет файла');
        }
      } catch (err) {
        notification.error({ message: 'Ошибка загрузки документа' });
      }
    } else {
      try {
        if (document) {
          await updateDocument({ ...formData, diagnosisCode }, document);
          notification.success({ message: 'Документ успешно обновлен' });
        } else {
          await createDocument({ ...formData, diagnosisCode });
          notification.success({ message: 'Документ успешно создан' });
        }
        form.resetFields();
        onFinish && onFinish();
      } catch (err) {
        notification.error({ message: 'Ошибка загрузки документа' });
      }
    }

    setDocumentLoading(false);
  };

  const updateDocument = async (formData: FormData, document: MedcardDocument) => {
    const updatedFields = { ...formData };

    if (document.idExternalDocument) {
      try {
        const response = await updateExternalDocument({
          externalDocumentId: document.idExternalDocument,
          isPublic: updatedFields.isPublic ?? false,
          externalDocumentSource: document.externalDocumentSource,
        }).unwrap();

        return response;
      } catch (err) {
        console.error('Error updating document:', err);
        throw err;
      }
    } else {
      const idDocument = document.idMilaUserDocument;
      const payload: Payload = {
        idProfile: formData.idProfile,
        idDocument: idDocument,
        documentType: updatedFields.documentType,
        documentName: updatedFields.documentName,
        //documentDate: dayjs(document.documentDate),
        documentDate: dayjs(updatedFields.documentDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        appointmentType: updatedFields.appointmentType,
        medOrganizationName: updatedFields.medOrganizationName,
        doctorFullName: updatedFields.doctorFullName,
        doctorSpecialtyName: updatedFields.doctorSpecialtyName,
        diagnosisCode: updatedFields.diagnosisCode,
        serviceCode: updatedFields.serviceCode,
        isPublic: updatedFields.isPublic ?? false,
        upload: [],
      };

      try {
        const response = await update(payload).unwrap();

        return response;
      } catch (err) {
        console.error('Error updating document:', err);
        throw err;
      }
    }
  };

  const createDocument = async (formData: FormData) => {
    await uploadDocuments({
      idProfile: formData.idProfile,
      document: {
        idMilaAppointment: formData.idMilaAppointment,
        idExternalAppointment: formData.idExternalAppointment,
        documentName: formData.documentName,
        documentType: formData.documentType,
        appointmentType: formData.appointmentType,
        documentDate: formData.documentDate,
        medOrganizationName: formData.medOrganizationName,
        doctorFullName: formData.doctorFullName,
        doctorSpecialtyName: formData.doctorSpecialtyName,
        diagnosisCode: formData.diagnosisCode,
        serviceCode: formData.serviceCode,
        isPublic: formData.isPublic,
      },
      preUploadedFiles: uploadedFiles.map((file) => file.fileId),
    }).unwrap();
  };

  const [isDocumentOpen, setDocumentOpen] = useState(false);

  const handleDocumentOpen = (file: MedcardDocumentAttachment, index: number) => {
    setSelectedFileIndex(index);
    setDocumentOpen(true);
  };
  const handleDocumentClose = () => setDocumentOpen(false);

  const deleteAttachment = async (id: string) => {
    if (document) {
      setIsActiveSaveBtn(true);
      await deleteFile({
        idDocument: document.idMilaUserDocument ? document.idMilaUserDocument : document.idExternalDocument,
        idFile: id,
        idProfile: document.profileId,
      }).unwrap();
      onFinish && onFinish();
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveDocTab(tab as 'oneDoc' | 'separateDocs');
  };

  const isRequiredDocumentForm = useMemo(() => {
    if (!document) {
      return true;
    } else if (document && document.uploadedByWhomType === DocumentUploadedByWhomType.Clinic) {
      return false;
    } else {
      return false;
    }
  }, [document]);

  return isDocumentLoading ? (
    <Row className={styles.LoadingRow}>
      <CustomSpinner />
      <Typography>Пожалуйста, не закрывайте окно, идет загрузка документа</Typography>
    </Row>
  ) : (
    <Form className={styles.Form} form={form} onFinish={handleSubmit}>
      <Row className={styles.DocumentsModalRecordWrapper}>
        <Row>
          <Typography className={styles.DocumentsModalRecordTitle}>
            {document
              ? document.uploadedByWhomType === DocumentUploadedByWhomType.Clinic
                ? 'Документ от медорганизации'
                : 'Документ от пользователя'
              : 'Загрузить файлы'}
          </Typography>
        </Row>

        {document?.files && document?.files.length > 0 && (
          <Row className={styles.FilesWrapper}>
            {deleteMutation.isLoading ? (
              <CustomSpinner />
            ) : (
              document?.files.map((file, index) => (
                <Row
                  className={styles.FileRow}
                  key={file.idDocumentFile ? file.idDocumentFile : file.idExternalDocumentFile}
                >
                  <Col className={styles.FileCol}>
                    <DocumentsItem />
                    <Typography className={styles.DocumentNameLabel} onClick={() => handleDocumentOpen(file, index)}>
                      {file.fileName ? file.fileName : 'Нажмите, чтобы посмотреть вложения'}
                    </Typography>
                  </Col>

                  {document.uploadedByWhomType === DocumentUploadedByWhomType.Patient && (
                    <Button
                      className={styles.RemoveButton}
                      icon={<RemoveDocumentIcon />}
                      onClick={() =>
                        deleteAttachment(file.idDocumentFile ? file.idDocumentFile : file.idExternalDocumentFile)
                      }
                    />
                  )}
                </Row>
              ))
            )}
          </Row>
        )}

        {document && !document.idExternalDocument && <UpdateUploadBtn document={document} />}
        <Row className={styles.FieldsBlock}>
          <Row className={styles.InputRow}>
            {!idShared && (
              <Form.Item
                name="idProfile"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста выберите пациента',
                  },
                ]}
              >
                <CustomSelector
                  floatLabel="Медкарта пользователя"
                  options={selectProfileOptions}
                  disabled={
                    document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ||
                    (!!idMedcardProfile && isFromNotification === false) ||
                    !!document?.profileId ||
                    !!appointment?.patient?.idPatient ||
                    idShared !== undefined
                  }
                  onChange={() => setIsActiveSaveBtn(true)}
                />
              </Form.Item>
            )}
          </Row>
          {!document && (
            <DocumentUploadTabs
              activeKey={activeDocTab}
              onTabChange={handleTabChange}
              isShowUploadFilesBtn={!idShared}
              normalizeFile={normalizeFile}
              setIsActiveSaveBtn={setIsActiveSaveBtn}
              isRequiredDocumentForm={isRequiredDocumentForm}
              saveBtnClass={styles.SaveButton}
              setIsFileUploaded={setIsFileUploaded}
            />
          )}

          {activeDocTab === 'oneDoc' && (
            <>
              <Row className={styles.InputRow}>
                <Form.Item
                  name="documentType"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста выберите тип документа',
                    },
                  ]}
                >
                  <CustomSelector
                    floatLabel={'Тип мед. документа *'}
                    options={documentTypeOptions}
                    disabled={
                      document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic || idShared !== undefined
                    }
                    onChange={() => setIsActiveSaveBtn(true)}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item
                  name="documentName"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста введите название документа',
                    },
                  ]}
                >
                  <CustomInput
                    floatLabel={'Название документа *'}
                    className={styles.InputOrganizationName}
                    onChange={() => setIsActiveSaveBtn(true)}
                    disabled={idShared !== undefined || !!document?.idExternalDocument}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item
                  name="documentDate"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста выберите дату документа',
                    },
                  ]}
                >
                  <CustomDatePicker
                    floatLabel="Дата медицинского документа *"
                    disabled={
                      document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ||
                      !!appointment?.created ||
                      idShared !== undefined
                    }
                    onChange={() => setIsActiveSaveBtn(true)}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item
                  name="appointmentType"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста выберите тип приёма',
                    },
                  ]}
                >
                  <CustomSelector
                    floatLabel={'Тип приема *'}
                    options={appointmentTypeOptions}
                    disabled={
                      (document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic &&
                        form.getFieldValue('appointmentType')) ||
                      appointment?.serviceDeliveryType !== undefined ||
                      idShared !== undefined ||
                      !!document?.idExternalDocument
                    }
                    onChange={() => setIsActiveSaveBtn(true)}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item name="medOrganizationName">
                  <CustomInput
                    floatLabel={'Медорганизация'}
                    className={styles.InputOrganizationName}
                    disabled={
                      document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic ||
                      !!appointment?.medOrganization?.shortName ||
                      idShared !== undefined
                    }
                    onChange={() => setIsActiveSaveBtn(true)}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item name="doctorSpecialtyName">
                  <CustomAutocompleteSearch
                    floatLabel="Укажите специализацию врача"
                    options={specialitiesOptions || []}
                    handleChangeValue={handleSelectValueSpecialty}
                    value={form.getFieldValue('doctorSpecialtyName') ?? mkb10SearchQuery}
                    disabled={
                      !!appointment?.doctor?.specializationName ||
                      idShared !== undefined ||
                      !!document?.idExternalDocument
                    }
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item name="doctorFullName">
                  <CustomInput
                    floatLabel={'ФИО врача'}
                    className={styles.InputOrganizationName}
                    onChange={() => setIsActiveSaveBtn(true)}
                    disabled={idShared !== undefined || !!document?.idExternalDocument}
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item name="diagnosisCodeValue">
                  <CustomAutocompleteSearch
                    floatLabel="Установленный диагноз"
                    options={mkb10SearchOptions || []}
                    onChange={handleChangeValueMkb10}
                    handleChangeValue={handleSelectValueMkb10}
                    value={form.getFieldValue('diagnosisCodeValue') ?? mkb10SearchQuery}
                    disabled={
                      document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic || idShared !== undefined
                    }
                  />
                </Form.Item>
              </Row>
              <Row className={styles.InputRow}>
                <Form.Item name="serviceCode">
                  <CustomAutocompleteSearch
                    floatLabel="Название анализа, исследования, услуги"
                    options={seriveSearchOptions || []}
                    onChange={handleChangeValueService}
                    handleChangeValue={handleSelectValueService}
                    value={form.getFieldValue('serviceCode') ?? serviceSearchQuery}
                    disabled={
                      document?.uploadedByWhomType === DocumentUploadedByWhomType.Clinic || idShared !== undefined
                    }
                  />
                </Form.Item>
              </Row>
            </>
          )}
        </Row>
        {!idShared && (
          <Row className={styles.DocsAccessEnable}>
            <Form.Item name={'isPublic'} valuePropName={'checked'}>
              <Checkbox
                onChange={() => {
                  setIsActiveSaveBtn(true);
                }}
              />
            </Form.Item>
            <Typography>Открывать доступ к документу</Typography>
          </Row>
        )}
        {!idShared && (
          <Row className={styles.SaveButtonWrapper}>
            <CustomButton
              isPrimal={true}
              className={styles.SaveButton}
              onClick={form.submit}
              disabled={!isActiveSaveBtn || (!isFileUploaded && isAppointmentForm)}
            >
              <Typography>Сохранить</Typography>
            </CustomButton>
          </Row>
        )}
      </Row>

      <DocumentViewerModal
        medcardDocument={document}
        isOpen={isDocumentOpen}
        onClose={handleDocumentClose}
        idShared={idShared}
        initialDocumentIndex={selectedFileIndex}
      />
    </Form>
  );
};

import { Typography } from 'antd';
import ContactIcon from '../../../assets/ContactIcon.svg';
import styles from './styles.module.scss';

const { Text } = Typography;

export const ContactInfo = () => (
  <div className={styles.container}>
    <img src={ContactIcon} alt="" />
    <Text type="secondary" className={styles.text}>
      Если у вас возникли вопросы по бонусам и другим условиям программы лояльности, напишите нам{' '}
      <a href="mailto:club@labquest.ru">club@labquest.ru</a> или позвоните по телефону{' '}
      <a href="tel:+74921111111">+7 (492) 111-11-11</a>
    </Text>
  </div>
);

import { Typography } from 'antd';
import styles from './styles.module.scss';

const { Title, Paragraph, Text } = Typography;

export const ProcessPersonalDataAgreement = (
  <div className={styles.agreementWrapper}>
    <Title level={4} className={styles.agreementTitle}>
      СОГЛАСИЕ ПОЛЬЗОВАТЕЛЯ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ
    </Title>

    <Paragraph className={styles.paragraph}>
      В соответствии со статьями 23, 24 Конституции Российской Федерации, Федеральным законом от 27.07.2006 № 152-ФЗ «О
      персональных данных», Политикой конфиденциальности, размещенной на сайте Mila (Мила) по адресу: www.mila.online.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      Я, субъект персональных данных (далее - ПДн), именуемый в дальнейшем Пользователь, при прохождении процедуры
      регистрации путем заполнения формы регистрации или авторизации посредством Федеральной государственной
      информационной системы «Единый портал государственных и муниципальных услуг (функций)» (далее – «Госуслуги»),
      через Единую Систему Идентификации и Аутентификации, в целях использования сайта Mila (Мила) и его сервисов, в том
      числе виджета записи на прием в медицинскую организацию, отображаемого на сайте медицинской организации (далее -
      Сайт, Сервисы), и в дальнейшем при использовании Сайта и/или его Сервисов, отправляя информацию через электронные
      формы Сайта и Сервисов (далее, каждая из них и все в совокупности - Форма, Формы) выражаю полное, безоговорочное и
      однозначное Согласие на обработку моих ПДн, а также ПДн лиц, законным представителем которых я являюсь, (далее -
      Согласие) на следующих условиях:
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>1. Согласие выдано Обществу с ограниченной ответственностью «ЭлНетМед»</Text> (ООО «ЭлНетМед», ИНН
      7842529670, ОГРН 114 784 736 4323) (далее – Оператор ПДн). Я уведомлен, что ООО «ЭлНетМед» является официальным
      оператором программного комплекса «N3.Health» - «иной информационной системы», уполномоченным Министерством
      здравоохранения Российской Федерации для размещения сведений об оказанной медицинской помощи в ЕГИСЗ Минздрава РФ
      в соответствии с действующим законодательством (идентификатор в Реестре Минздрава РФ – 3, включения в Реестр -
      11.10.2019). Я уведомлен, что ООО «ЭлНетМед» также является оператором ПДн, включенным в Реестр операторов
      Роскомнадзора (регистрационный номер 78 21-008437). Я уведомлен, что программное обеспечение N3.Health ООО
      «ЭлНетМед» включено в Реестр российского ПО Министерства цифрового развития, связи и массовых коммуникаций
      Российской Федерации (запись в Реестре № 9016 от 05.02.2021). Я уведомлен, что Оператор ПДн имеет право во
      исполнение своих обязательств согласно регламенту электронного взаимодействия медицинских организаций на обмен
      (прием и передачу) ПДн с медицинскими организациями, имеющими лицензии на осуществление медицинской деятельности в
      Российской Федерации (далее – Медицинские Организации), с иными прописанными в регламенте участниками электронного
      взаимодействия с использованием машинных носителей информации или по каналам связи, с соблюдением мер,
      обеспечивающих их защиту от несанкционированного доступа, при условии, что их прием и обработка будут
      осуществляться лицом, обязанным сохранять врачебную тайну.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        Мои ПДн, а также данные моих детей, полученные сервисом Mila (Мила) из ЕСИА с целью идентификации меня как
        пациента, мною проверены и являются верными.
      </Text>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        2. Согласие выдано на обработку персональных и иных данных Пользователя, а также ПДн лиц, законным
        представителем которых Пользователь является
      </Text>
      , указанных Пользователем в Формах путем заполнения соответствующих полей и/или прикрепленных к Формам файлов
      и/или полученных из систем авторизации Госуслуг и, а также информационных систем Медицинских Организаций и иных
      участников электронного взаимодействия, а именно следующих ПДн:
      <ul>
        <li>
          - фамилия, имя, отчество (при наличии), пол, дата рождения, адрес электронной почты (e mail), номер телефона,
          адрес регистрации, номер документа, удостоверяющего личность, сведения о дате выдачи указанного документа и
          выдавшем его органе, реквизиты полиса ОМС (ДМС), номер СНИЛС, либо сведения, сообщённые Пользователем Сайта о
          лице, законным представителем которого Пользователь является;
        </li>
        <li>
          - сведения о состоянии здоровья, об оказанных и о планируемых медицинских услугах, в том числе для оценки
          Пользователем качества оказания медицинских услуг (в том числе сведения о записях на прием в Медицинские
          Организации, результатах медицинского обследования, наличии заболевания, об установленном диагнозе и о
          прогнозе развития заболевания, методах оказания медицинской помощи, связанном с ними риске, возможных видах
          медицинского вмешательства, его последствиях и результатах оказания медицинской помощи, сведения об
          инвалидности, биометрические ПДн, сведения о льготах), сообщённые Пользователем Сайта о себе либо лице,
          законным представителем которого Пользователь является;
        </li>
        <li>
          - данных, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес,
          информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес
          предыдущей страницы);
        </li>
        <li>- статистики о моих IP-адресах.</li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>3. Согласие выдано на обработку ПДн в целях:</Text>
      <ul>
        <li>
          - исполнения соглашений по предоставлению доступа к Сайту, его Содержанию и/или Сервисам (в том числе
          персонализированным);
        </li>
        <li>- идентификации при регистрации на Сайте и/или при использовании Сервисов;</li>
        <li>
          - оказания услуг, включая формирование, отмену и перенос записей на прием в медицинские организации, в том
          числе для получения медицинских и информационных консультаций с использованием телемедицинских технологий и
          чатов;
        </li>
        <li>
          - возможности формирования медицинских карт из загружаемых Пользователем через Формы на Сайте и его Сервисах
          данных и приложенных к ним файлов;
        </li>
        <li>
          - возможности формирования и передачи Пользователем по усмотрению Пользователя ссылок на просмотр медицинских
          карт;
        </li>
        <li>
          - возможности совершения операций оплаты за услуги медицинских и информационных консультаций в медицинских
          организациях с использованием телемедицинских технологий;
        </li>
        <li>
          - информирования Пользователя, в том числе, направления уведомлений, запросов и информации, касающихся
          использования Сайта и Сервисов, оказания услуг медицинскими организациями;
        </li>
        <li>
          - возможности обработки запросов и заявок Пользователя службой технической поддержки Оператора ПДн и
          сотрудниками медицинских организаций;
        </li>
        <li>- установления обратной связи, включая направление уведомлений и запросов;</li>
        <li>- подтверждения полноты предоставленных ПДн;</li>
        <li>- возможности принятия оферт, публикуемых на Сайте;</li>
        <li>
          - проведения статистических и иных исследований, на основе обезличенных данных и в иных целях, определенных
          видами деятельности Оператора ПДн;
        </li>
        <li>
          - улучшения качества работы Сайта и/или его Сервиса, удобства их использования и разработки новых сервисов и
          услуг;
        </li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      Пользователь настоящим подтверждает, осведомлен и согласен, что для вышеуказанных целей, Оператор ПДн вправе
      собирать и использовать дополнительную информацию, связанную с Пользователем, получаемую в процессе доступа
      Пользователя к Сайту, его Содержанию и/или Сервисам, или от третьих лиц, и включающую в себя данные о технических
      средствах (в том числе, мобильных устройствах) и способах технологического взаимодействия с Сайтом и/или его
      Сервисами (в т. ч. IP-адрес хоста, вид операционной системы Пользователя, тип браузера, географическое положение,
      данные о провайдере и иное), об активности Пользователя при использовании Сайта и/или его Сервисов, cookies, об
      информации об ошибках, выдаваемых Пользователю, о скачанных файлах, видео, инструментах, а также иные данные,
      получаемые установленными Правилами обработки ПДн способами; распоряжаться статистической информацией, связанной с
      функционированием Сайта и/или его Сервисов, а также информацией Пользователя для целей организации
      функционирования и технической поддержки Сайта и/или его Сервисов и исполнения условий законодательства Российской
      Федерации, и разработанных в соответствии с ним Правилами обработки ПДн.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        4. Согласие выдано на обработку ПДн смешанным (автоматизированным и неавтоматизированным) способом.
      </Text>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>В процессе обработки ПДн Оператор ПДн вправе осуществлять: </Text>сбор, запись, систематизацию,
      накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление, уничтожение ПДн Пользователя.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>5. Настоящим, Пользователь подтверждает, что:</Text>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        5.1. Ознакомлен и согласен с тем, что передача ПДн Пользователя, а также лиц, законным представителем которых
        является Пользователь, может осуществляться Оператором ПДн
      </Text>
      &nbsp;следующим третьим лицам:
      <ul>
        <li>
          - в объеме, необходимом для идентификации Пользователя и возможности организации прямого контакта с ним:
          медицинским организациям, в которые Пользователь направил заявку на запись на прием, обращение, запрос или
          сообщение в чате посредством Сайта и/или его Сервисов, либо хранящих записи на прием Пользователя либо лиц,
          законным представителем которых является Пользователь, в собственных информационных системах; медицинским
          организациям, разместившим сведения об оказанной медицинской помощи в отношении Пользователя или лиц, законным
          представителем которых является Пользователь, в ЕГИСЗ Минздрава РФ в соответствии с действующим
          законодательством;
        </li>
        <li>
          - в объеме, самостоятельно определяемым Пользователем: медицинским организациям, при заполнении и отправке
          Формы «Информация для врача» в карточке записи на прием в такую медицинскую организацию на Сайте;
        </li>
        <li>
          - в объеме, самостоятельно определяемым Пользователем: при формировании и передаче Пользователем по своему
          усмотрению ссылок на просмотр медицинских карт Пользователя и лиц, законным представителем которых является
          Пользователь, отображаемых для просмотра в отдельном окне веб-браузера: широкому кругу лиц. При формировании и
          передаче такой ссылки Пользователь подтверждает, осведомлен и согласен, с тем, что Оператор ПДн не несет
          ответственности за раскрытие ПДн неопределенному кругу лиц самим субъектом ПДн или его законным
          представителем, доказательства законности последующего распространения или иной обработки таких ПДн лежит на
          Пользователе и лицах, законным представителем которых является Пользователь либо лице, получившим доступ к ПДн
          Пользователя.
        </li>
      </ul>
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        5.2. Уведомлен, что срок хранения ПДн, обрабатываемых Оператором ПДн при оказании медицинских услуг,&nbsp;
      </Text>
      соответствует сроку хранения первичных медицинских документов и составляет 25 лет.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>5.3. Проинформирован о возможности отзыва согласия</Text> в соответствии с пунктом 5 статьи 21
      Федерального закона от 27.07.2006 № 152 ФЗ «О персональных данных» посредством составления соответствующего
      письменного заявления, которое должно содержать: фамилию, имя, отчество (при наличии), дату рождения, номер
      документа, удостоверяющего личность, сведения о дате выдачи указанного документа и выдавшем его органе либо номер
      свидетельства о рождении несовершеннолетнего, сведения о дате выдачи указанного документа и выдавшем его органе, и
      может быть направлено Пользователем в адрес Оператора ПДн по почте заказным письмом с уведомлением о вручении либо
      вручено лично под расписку представителю Оператора ПДн.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        5.4. Проинформирован, что лица, указанные в пункте 1 настоящего Согласия, вправе продолжать обработку ПДн&nbsp;
      </Text>
      Пользователя после получения отзыва Согласия при наличии оснований, предусмотренных частью 2 статьи 9 Федерального
      закона от 27.07.2006 № 152 ФЗ «О персональных данных».
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        5.5. Дает согласие на получение уведомлений и информационных материалов на свою электронную почту&nbsp;
      </Text>
      и ознакомлен Оператором ПДн о возможности отзыва такого согласия в настройках профиля на Сайте.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>5.6. Дает согласие не передачу и дальнейшую обработку ПДн&nbsp;</Text>
      Пользователя для обработки заказов на лекарственные препараты и товары обществу с ограниченной ответственностью
      «Фармлинк» (143402, Московская область, г.о. Красногорск, г. Красногорск, ул. Жуковского, д. 17, помещ. III, ком.
      12-П ИНН 5024223277 ОГРН 1225000071955), являющимся самостоятельным оператором ПДн в целях исполнения обязательств
      Оператора ПДн перед Пользователем в соответствии с Пользовательским соглашением.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        6. Согласие вступает в силу с момента его акцепта Пользователем в сервисе Mila (Мила) и действует в течение
        неопределенного срока до момента его отзыва Пользователем.
      </Text>
      &nbsp;Согласие прекращает свое действие с даты, указанной в заявлении Пользователя об отзыве Согласия на обработку
      ПДн, но не ранее даты, следующей за датой фактического получения Оператором ПДн отзыва Согласия.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>7. Датой и временем формирования, подтверждения и акцепта Согласия</Text> прошу считать момент
      нажатия на кнопку «Войти через Госуслуги» и/или «Зарегистрироваться» на Сайте и/или его Сервисах. Настоящим
      подтверждаю, что иных доказательств для подтверждения моего свободного волеизъявления не потребуется.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>8. </Text> Я согласен с тем, что&nbsp;
      <Text strong>текст данного мной согласия хранится в электронном виде в базе данных Сайта</Text> и подтверждает
      факт согласия на обработку ПДн в соответствии с вышеизложенными положениями и беру на себя ответственность за
      достоверность предоставления ПДн. Настоящим я гарантирую, что указанные мною на Сайте или его Сервисах ПДн
      принадлежат мне или лицам, законным представителям которых я являюсь.
    </Paragraph>

    <Paragraph className={styles.paragraph}>
      <Text strong>
        9. Руководствуясь ч. 4 ст. 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»,
      </Text>
      &nbsp;при прохождении процедуры регистрации на Сайте направляю свои ПДн через личное мобильное устройство или
      персональный компьютер путем заполнения формы регистрации или путем авторизации посредством Госуслуг.
    </Paragraph>
  </div>
);

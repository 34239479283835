import { Row } from 'antd';
import React, { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

interface WhiteRowProps extends PropsWithChildren {
  className?: string;
  dataTestId?: string;
}

export const WhiteRow = ({ children, className = '', dataTestId }: WhiteRowProps) => (
  <Row data-test-id={dataTestId ? dataTestId : null} className={`${className} ${styles.WhiteRow} `}>
    {children}
  </Row>
);

import dayjs from 'dayjs';

export const SLOT_RANGE = 7;

export const specialtyColors = [
  '#FEF7F6',
  '#E5DBF8',
  '#CBDCAD',
  '#FFF0C2',
  '#D7DBEB',
  '#E6D1EA',
  '#FAD1CE',
  '#DEF9FD',
  '#FAECDA',
  '#FED6BE',
];

export const avatarColors = ['#E79E44', '#40C9D5', '#E5DBF8', '#8D91C0', '#FAD1CE', '#BEA4EE', '#FAECDA', '#D2E3B1'];

export const idProcessPersonalDataAgreementConsent = 'cc444b63-c86a-4d3e-9089-0ed8f186b876';

export const idMedicalDataAccessConsent = 'e9469135-2a68-48f4-81f0-9b6f0d7d8cb0';

export const idNotificationsAgreementConsent = 'eb0652a0-45e5-474a-ae58-88989e2290cb';

export const idProcessPersonalDataAgreementConsent75 = 'fcd800c9-36ff-4637-aabc-7c702fc380e2';

export const clientId = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_CLIENT_ID as string;

export const redirectUrl = window.__RUNTIME_CONFIG__.REACT_APP_HOST;

export const pdfWorkerUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_HOST}/pdf.worker.min.js`;

export const N3_HEALTH_PATIENTS_LINK = 'https://n3health.ru/servis-dlya-vrachej-i-pacientov-mila';

export const MILA_ONLINE_LINK = 'https://www.mila.online';

export const MY_MILA_ONLINE_LINK = 'https://www.my.mila.online';

export const ABOUT_MILA_CLINIC_LINK = 'https://n3health.ru/servis-dlya-vrachej-i-pacientov-mila';

export const itemsPerPage = 10;

export const defaultAppointmentsWithStatusChanged = [{ appointmentId: '', name: '', reason: '' }];

export const minimumDate = dayjs('0001-01-01T00:00:00+00:00');

export const adultAge = 18;

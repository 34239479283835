import { Spin } from 'antd';
import { useEffect } from 'react';
import { useGetOrderMedicinesTokenQuery } from '../../common/redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

export const OrderMedicines = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useGetOrderMedicinesTokenQuery();

  const lockBody = () => {
    document.documentElement.classList.add(styles.LockBody);
    document.body.classList.add(styles.LockBody);
  };

  useEffect(() => {
    lockBody();
  }, []);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onIframeMessage, false);
    }
    return () => window.removeEventListener('message', onIframeMessage, false);
  }, []);

  const onIframeMessage = (data: MessageEvent) => {
    if (data.data === 'close') {
      navigate('/home');
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className={styles.Content}>
      <iframe
        title="widget"
        id="widget"
        src={`${window.__RUNTIME_CONFIG__.REACT_APP_ORDER_MEDICINES_URL}/?token=${data}`}
        className={styles.Widget}
        allow="fullscreen"
        allowFullScreen
      />
    </div>
  );
};

import { AppointmentBlock } from './AppointmentBlock/AppointmentBlock';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { CustomSpinner } from '../../common/components/CustomSpinner/CustomSpinner';
import { DoctorInfoBlock } from './DoctorInfoBlock/DoctorInfoBlock';
import { InfoBlock } from './InfoBlock/InfoBlock';
import { ProfileBlock } from './ProfileBlock/ProfileBlock';
import { Row } from 'antd';
import { getAppointmentStatus } from 'src/common/utils';
import { useGetAppointmentQuery } from '../../common/redux/api/apiPatientSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

const dateDiffString = (date: string) => {
  const appointmentDate = dayjs(date);

  if (appointmentDate.isToday()) {
    return `Сегодня в ${appointmentDate.format('HH:mm')}`;
  }

  if (appointmentDate.isTomorrow()) {
    return `Завтра в ${appointmentDate.format('HH:mm')}`;
  }

  if (appointmentDate.isYesterday()) {
    return `Вчера в ${appointmentDate.format('HH:mm')}`;
  }

  return `${appointmentDate.format('DD MMM YYYY (dd)')} в ${appointmentDate.format('HH:mm')}`;
};

export const Appointment = () => {
  const { appointmentId } = useParams();
  const [params] = useSearchParams();
  const doctorBlock = useMemo(() => params.get('doctorBlock'), [params]);

  const [appointmentStatusUpcomingDoctorInfo, setAppointmentStatusUpcomingDoctorInfo] = useState<boolean>(true);

  useEffect(() => {
    if (doctorBlock) {
      document.getElementById('DoctorInfoBlock')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [doctorBlock]);

  const {
    data: appointmentData,
    isLoading,
    refetch: refetchAppointmentData,
  } = useGetAppointmentQuery({
    appointmentId,
  });

  const dateString = appointmentData ? dateDiffString(appointmentData.created) : '';

  useEffect(() => {
    if (appointmentData?.visitEndDate) {
      const lastMomentOfVisitEndDate = dayjs(appointmentData?.visitEndDate).hour(23).minute(59);
      const currentDate = dayjs();
      const difference = lastMomentOfVisitEndDate.diff(currentDate, 'minute');

      if (difference <= 0) {
        setAppointmentStatusUpcomingDoctorInfo(false);
      }
    }
  }, [appointmentData?.visitEndDate]);

  return (
    <>
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <>
          {appointmentData && <BackHeader title={`${dateString}, ${appointmentData?.serviceDeliveryTypeName}`} />}

          {appointmentData?.patient && (
            <Row className={styles.ProfileRow}>
              <ProfileBlock
                role={'Пациент'}
                avatar={appointmentData.patient.idPhoto}
                fio={appointmentData.patient.fullName}
                fioTextClassName={styles.DoctorFioText}
                roleTextClassName={styles.DoctorRoleText}
                id={appointmentData.patient.idPatient}
              />
            </Row>
          )}

          {appointmentData && <AppointmentBlock refetchData={refetchAppointmentData} appointment={appointmentData} />}

          {appointmentData && (
            <Row id="DoctorInfoBlock">
              <DoctorInfoBlock
                idProfile={appointmentData.patient.idPatient}
                appointmentId={appointmentData.idAppointment ?? appointmentData.idExternalAppointment}
                appointmentStatusUpcoming={appointmentStatusUpcomingDoctorInfo}
                refetchData={refetchAppointmentData}
                sharedLinkId={appointmentData?.sharedLinkId}
              />
            </Row>
          )}

          {appointmentData && (
            <InfoBlock
              tips={appointmentData?.tips}
              status={getAppointmentStatus(appointmentData.visitStartDate, appointmentData.visitEndDate)}
            />
          )}
        </>
      )}
    </>
  );
};
